import React, { useState, useEffect, useContext } from 'react';

import { Link, useParams }          from 'react-router-dom';
import axios                        from 'axios';
// import moment                       from 'moment';
import moment                       from 'moment-timezone';
import 'moment/locale/it';

import TabContext                   from '@mui/lab/TabContext';
import TabPanel                     from '@mui/lab/TabPanel';
import { AdapterMoment }            from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }               from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider }     from '@mui/x-date-pickers/LocalizationProvider';
import { Box
       , Button                     
       , ButtonGroup                
       , IconButton                 
       , Tooltip }                  from '@mui/material';

import './Anagrafiche.css';
import config                       from '../../config.js';
import { AuthContext }              from '../../contexts/authContext';
import { AppContext }               from '../../App.js';
import { SimpleTable }              from '../../components/SimpleTable/SimpleTable';
import Graph                        from '../../components/Graph/Graph';
import CircleProgress               from '../../components/CircleProgress/CircleProgress.tsx';
import AnagrafichePercentuale       from './Component/AnagrafichePercentuale.js';
import utils                        from '../../util/CommonUtilities';
import {
          CustomCssTab
        , CustomCssTabList
        , CustomNoWidthTooltip }        from '../../components/CustomCSS/CustomCSS.tsx';
import { 
          CachedIcon
        , DoubleArrowLeftIcon
        // , DoubleArrowRightIcon
        , FolderOffIcon
        , LeaderboardIcon
        , SsidChartIcon
        , TableChartIcon }          from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;

const asTypeInPerc = [ 'HOUSE','SPONSORSHIP', 'NETWORK' ];

const oMappingFieldStorico = {
    'DATA'                  : 'Data'
   ,'IMPRESSIONS_DELIV'     : 'Imps Delivered'
   ,'IMPRESSIONS_SCHEDULED' : 'Imps Scheduled'
   ,'BACINO_S'              : 'Bacino Storico'
   ,'BACINO_F'              : 'Bacino Forecast'
};

const oMappingFieldForecast = {
    'DATA'                  : 'Data'
   ,'BACINO'                : 'Bacino'
   ,'OCCUPATO'              : 'Occupato'
};

// forecastedTimeSeries = BACINO
// forecastedAssignedTimeSeries = OCCUPATO
function transformData( oAdunitForecast ) {
    /* ESEMPIO
    const myVar = {
        adUnitId: 21845982734,
        forecastedTimeSeries: {
            timeSeriesDateRange: {
                startDate: { year: 2023, month: 5, day: 9 },
                endDate: { year: 2023, month: 6, day: 7 },
            },
            values: ['0', '0', '0', '0', '0', '0', '0', '0'],
        },
        forecastedAssignedTimeSeries: {
            timeSeriesDateRange: {
                startDate: { year: 2023, month: 5, day: 9 },
                endDate: { year: 2023, month: 6, day: 7 },
            },
            values: ['0', '0', '0', '0', '0', '0', '0', '0'],
        },
    };
    
    oAdunitForecast = {
        timeSeriesDateRange: {
            startDate: { year: 2023, month: 5, day: 9 },
            endDate: { year: 2023, month: 6, day: 7 },
        },
        values: ['0', '0', '0', '0', '0', '0', '0', '0'],
    };
    
    */
    const
         s             = oAdunitForecast.timeSeriesDateRange.startDate
        ,e             = oAdunitForecast.timeSeriesDateRange.endDate
        ,dataInizio    = moment( s.year  + '-' + s.month + '-' + s.day )
        ,endDate       = moment( e.year  + '-' + e.month + '-' + e.day )
        ,values        = oAdunitForecast.values
        ,dateArray     = Array.from(
            { length: endDate.diff(dataInizio, 'days') + 1 },
            (_, index) => moment(dataInizio).add(index, 'days')
        )
    ;
    
    return dateArray.map((date, index) => ({
        date:  date.format('YYYYMMDD'),
        value: values[index],
    }));
    
}

const AnagraficheStoricoForecast = () => {
    const auth = useContext(AuthContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const { account, accountcode, dettaglio, id, isForecastNotify } = useParams(); // , giornaliero
    const { set_oNotifyOptions, set_dLastUpdate, oFilterParamsTotal, setsConcatNamePage } = useContext(AppContext);
    let   { [dettaglio]: oFilterParams } = oFilterParamsTotal;
    const
          isPlacement = ( dettaglio === 'PLACEMENTS' ) ? true : ''
        , isAdUnit    = ( dettaglio === 'AD_UNITS'   ) ? true : ''
        , isLineItem  = ( dettaglio === 'LINE_ITEMS' ) ? true : ''
    ;

    /* #region Format */
    const
         sGAMurl               = config.GAM_URL
        ,formatZero            = (val) => +val === 0 ? 'zero' : ''
        ,formatNum1decPerc     = (val) => { return ( val === 0 ) ? val : !(+val) ? '' : `${ utils.formatNumberWithOptions(val, { nOuputDecimals: 1 }) } %` }
        ,formatNum0dec         = (val) => { return ( val === 0 ) ? val : !(+val) ? '' : utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })}
        ,formatImp0decPerc     = (val, oRow ) => `${utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })} ${ asTypeInPerc.includes( oRow.LINE_ITEM_TYPE.trim() ) ? '%' : '' }`
        // ,formatDate            = (val) => utils.formatDate(val)
        ,formatDateWeek        = (val) => utils.formatDateWeek(val)
        ,formatDateWeekHour    = (val) => utils.formatDateTime(val, { output: 'D MMM YYYY HH:mm' })
        ,formatHour            = (val) => utils.formatTime(val)
        ,formatPercentage      = (val, oRow ) => <div> <AnagrafichePercentuale perc={oRow} reusingComponent={true} /></div>
        ,createHref            = (val, href) => <a href={ sGAMurl + accountcode + href + '' + val } target='_blank' className='myExternalLinkIDUnderline' rel='noreferrer'>{val}</a>
        ,formatLineItemLink    = (val) => createHref( val, `#delivery/line_item/detail/line_item_id=` )
        ,formatPlacementLink   = (val) => createHref( val, `#inventory/placement/detail/placement_id=` )
        ,formatAdUnitLink      = (val) => createHref( val, `#inventory/ad_unit/detail/ad_unit_id=` )
        // ,formatLinkGiornaliero  = (val, oRow) =>  oRow.ACTUAL_PROG_PERC > 0 ? <Tooltip title='Dettaglio Giornaliero' placement='left' arrow followCursor><Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/${ dettaglio }/${ oRow.LINE_ITEM_ID }/contending/dettaglio/giornaliero`} onClick={ () => { localStorage.setItem( 'sContendingName', oRow.LINE_ITEM_NAME )}} className="link myShadow" >
        //                                         <DoubleArrowRightIcon />
        //                                     </Link></Tooltip> : null
    ;
    /* #endregion */

    const
         [ loading               ,setLoading                ] = useState(true)
        ,[ onReload              ,set_onReload              ] = useState(null) // State per triggerare il lancio della chiamata
        ,[ nValueTab             ,set_nValueTab             ] = useState( isForecastNotify ? 'Forecast' : 'Storico' ) // isAdUnit ? '3' : '1'
         
        , nDays = 31
        // ,[ aoStoricoFromDB        ,set_aoStoricoFromDB        ] = useState([]) // State per contenere i record di storico ottenuti direttamente dal DB
        ,[ aoStoricoErogFromDB    ,set_aoStoricoErogFromDB    ] = useState([]) // State per contenere i record di storico ottenuti direttamente dal DB
        ,[ aoStoricoAnagFromDB    ,set_aoStoricoAnagFromDB    ] = useState([]) // State per contenere i record di storico ottenuti direttamente dal DB
        ,[ aoForecastFromDB       ,set_aoForecastFromDB       ] = useState([]) // State per contenere i record di forecast ottenuti direttamente dal DB
        ,[ aoAnagTableHeaders     ,set_aoAnagTableHeaders     ] = useState([])
        ,[ sDettaglioDescription  ,set_sDettaglioDescription  ] = useState('') // State per contenere il nome di AdUnit/LineItem da mostrare nel titolo
        ,[ sGraphEnabled          ,set_sGraphEnabled          ] = useState( nValueTab === 'Forecast' ? 'line' : '' ) // State per Identificare se si vuole visualizzare il Grafico a Linee ('line'), a Barre ('bar') oppure la tabella ('')
        ,[ dDataInizio            ,set_dDataInizio            ] = useState(moment().subtract(nDays, 'days'))
        ,[ dDataFine              ,set_dDataFine              ] = useState(moment())
        ,[ dDataPrevisioneList    ,set_dDataPrevisioneList    ] = useState(moment().subtract(7, 'days'))
        ,[ dDateStartFromDb       ,set_dDateStartFromDb       ] = useState(null)
        ,[ dDateEndFromDb         ,set_dDateEndFromDb         ] = useState(null)
        ,[ dLastUpdateStoricoErog ,set_dLastUpdateStoricoErog ] = useState(moment())
        ,[ dLastUpdateStoricoAnag ,set_dLastUpdateStoricoAnag ] = useState(moment())
        ,[ dLastUpdateForecast    ,set_dLastUpdateForecast    ] = useState(moment())
        // ,[ bDettaglioGiornaliero ,set_bDettaglioGiornaliero ] = useState(false)
    ;

    /* #region Handler */
        // Handle Graph per controllare se l'utente sta premendo su un bottone già attivo, nel qual caso non scateno il cambio di stato
        const handleSettingGraph = ( field ) => {
            if ( field !== sGraphEnabled ) {
                set_sGraphEnabled( field );
            }
        };
    /* #endregion Handler */

    // Function che controlla i parametri della pagina e decide quale chiamata a DB lanciare
    const throwCallDB = () => {
        set_oNotifyOptions(null);
        
        if ( dDataInizio.isValid() && dDataFine.isValid() && dDataPrevisioneList.isValid() ) {
            getAnagDetailsFromDB();
            if (( nValueTab === 'Storico' ) || ( nValueTab === 'Storico Anagrafico' )) {
                getInfo();
                getStoricoFromDB();
            } else if ( nValueTab === 'Forecast' ) {
                // Richiamo la CallToDB per Forecast
                if ( isAdUnit || isPlacement ) {
                    if (isForecastNotify) {
                        getS3File(); 
                    } else {
                        getForecastADUnitFromGAM();
                    }
                } else {
                    // Questo controllo é necessario per prevenire il caso in cui l'utente riesca ad atterrare nella pagina inserendo una url arbitraria non esatta
                    setLoading(false);
                }
            }
        } else {
            set_oNotifyOptions({ message: `Attenzione: le date inserite non sono valide`, severity:'warning', timeout: 5 })
        }
    };

    // Function per ottenere dati secondari oltre all'anagrafica
    const getInfo = async () => {
        bDebug && console.log('Dentro GetInfo');
        try {
            // console.log('oFilterParams: ', oFilterParams );
            let response = await axios.get(
                config.API_URL + '/get',
                {
                    headers,
                    params: { getAnagraficheParams: true, sSubcategoria: dettaglio, pAccountDesc: oFilterParams.pAccountDesc?.toLowerCase() || account }
                }
            );
            if ( response?.status === 200 && response?.data?.aoRecords ) {
                let aoRecords = response.data.aoRecords || [];
                nValueTab === 'Storico'            && set_dLastUpdateStoricoErog( aoRecords[0].MAX_LAST_EXECUTION || '' );
                nValueTab === 'Storico Anagrafico' && set_dLastUpdateStoricoAnag( aoRecords[0].MAX_LAST_EXECUTION || '' );
            }

        } catch(err) {
            console.error('ERR 190: Failed GetAnagrafiche: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
        }
    };
    
    // Function per ottenere le info relative a LineItem/Adunit/Placement
    const getAnagDetailsFromDB = async () => {
        bDebug && console.log('Dentro GetAnagDetailsFromDB');
        setLoading(true);
        
        // Chiamata per ottenere i valori da inserire nella tabella
        try {
            const params = {
                 ...( isPlacement && { getAnagraficaPlacementParams: true })
                ,...( isAdUnit    && { getAnagraficaAdUnitParams:    true })
                ,...( isLineItem  && { getAnagraficaLineItemParams:  true })
                ,oFilterParams: { ...oFilterParams
                    , pAccountDesc: account
                    , ...( isAdUnit    && { pAdUnitId:    id  } ) 
                    , ...( isLineItem  && { pLineItemId:  id  } ) 
                    , ...( isPlacement && { pPlacementId: id  } )
                    , pFirstRecord: 0
                    , pNumRecords:  10 }
            };
            const response = await axios.get( config.API_URL + '/get', { headers, params } );
            // console.table(response.data.aoRecords.slice(0,2))
            if ( response && ( response?.status === 200 ) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                if ( isAdUnit ) { 
                    set_sDettaglioDescription( response.data.aoRecords[0].AD_UNIT_NAME   ); 
                } else if ( isLineItem ) {
                    set_sDettaglioDescription( response.data.aoRecords[0].LINE_ITEM_NAME );
                    set_dDateStartFromDb( moment(response.data.aoRecords[0].BEGIN_DATE ));
                    set_dDateEndFromDb( moment(response.data.aoRecords[0].END_DATE )); 
                } else if ( isPlacement ){
                    set_sDettaglioDescription( response.data.aoRecords[0].PLACEMENT_NAME );
                }
                
                // console.log(response);
                
            }
        } catch(err) {
            console.error('ERR 191: Failed getAnagDetailsFromDB: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
    };

    // Function per Popolamento di aoAnagTableHeaders e l'ottenimento del DettaglioTabella
    // [ Valido sia per Storico che per Forecast ]
    const getDetailTable = () => {
        bDebug && console.log('Dentro GetDetailTable');
        setLoading(true);

        // !!! E' probabile che non possa utilizzare un oMapHeaders come in AllarmiDettaglio,
        // !!! perchè non ricevo da db la FieldList, potrebbe essere quindi non utile mappare per delle Field che dovrei dichiararmi in questa stessa pagina
        // !!! Decido quindi di condizionare il popolamento di aoAnagTableHeaders con un if
        let aoHeaders = [];

        if ( nValueTab === 'Storico' ) {
            // Popolo aoAnagTableHeaders per Storico
            if ( isLineItem ) {
                aoHeaders.push(...[
                    //  { name: 'LINE_ITEM_ID'             ,title: 'Line Item ID'             ,width: 100  ,format: formatLineItemLink     ,isNum:   true  , filterOriginalValue: true }
                    // ,{ name: 'LINE_ITEM_NAME'           ,title: 'Line Item'                ,width: 650                                  ,tooltip: true  }
                    // ,{ name: 'PRIORITY'                 ,title: 'Priorità'                 ,width: 50                                   ,isNum:   true  }
                     { name: 'DATA'                     ,title: 'Data'                     ,width: 150  ,format: formatDateWeek         ,isNum: true  ,isUniqueKeyForRow: true      ,showDiff: true }
                    ,{ name: 'ORA'                      ,title: 'Ora'                      ,width: 50   ,format: formatHour             ,isNum: true  ,isUniqueKeyForRow: true      ,showDiff: true                      ,notSortable: true }
                    ,{ name: 'STATUS'                   ,title: 'Status'                   ,width: 110                                                                              ,showDiff: true  ,notSortable: true }
                    ,{ name: 'IMPRESSIONS_SCHEDULED'    ,title: 'Imps Scheduled'           ,width: 135  ,format: formatImp0decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                    ,{ name: 'IMPRESSIONS_DELIV'        ,title: 'Imps Delivered'           ,width: 135  ,format: formatNum0dec          ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                    ,{ name: 'DELIVERY_ESTIMATED_PERC'  ,title: 'Check Inventory'          ,width: 80   ,format: formatNum1decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true ,headerTooltip: utils.mapTooltip.CheckInventory       }
                    ,{ name: 'ACTUAL_PROG_PERC'         ,title: 'Progressivo attuale'      ,width: 80   ,format: formatNum1decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true ,headerTooltip: utils.mapTooltip.Progressivoattuale   }
                    ,{ name: 'OBJ_PROG_PERC'            ,title: 'Progressivo obiettivo'    ,width: 80   ,format: formatNum1decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true ,headerTooltip: utils.mapTooltip.Progressivoobiettivo }
                    ,{ name: 'PACING'                   ,title: 'Pacing'                   ,width: 80   ,format: formatNum1decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true ,headerTooltip: utils.mapTooltip.Pacing               }
                    ,{ name: 'INDICATOR'                ,title: 'Indicatore'               ,width: 100  ,format: formatPercentage       ,isNum: true                                                 ,notSortable: true ,noExport: true }
//   ,( !giornaliero && { name: 'LINE_ITEM_ID'             ,title: ''                         ,width: 45   ,format: formatLinkGiornaliero                ,additionalClass: () => 'sticky1'              ,notSortable: true ,noExport: true })
                ]);
            } else if ( isAdUnit || isPlacement ) {
                aoHeaders.push(...[
                     { name: 'DATA'                     ,title: 'Data'                     ,width: 150  ,format: formatDateWeek         ,isNum: true  ,isUniqueKeyForRow: true }
                    ,{ name: 'BACINO_S'                 ,title: 'Bacino Storico'           ,width: 110  ,format: formatNum0dec          ,isNum: true                                ,showDiff: true  ,notSortable: true }
                    ,{ name: 'BACINO_F'                 ,title: 'Forecast'                 ,width: 110  ,format: formatNum0dec          ,isNum: true                                ,showDiff: true  ,notSortable: true }
                ])
            }
            
        } else if ( nValueTab === 'Storico Anagrafico' ) {
            // Popolo aoAnagTableHeaders per Storico
            if ( isLineItem ) {
                aoHeaders.push(...[
                     { name: 'DATA'                     ,title: 'Data'                     ,width: 150  ,format: formatDateWeek         ,isNum: true  ,isUniqueKeyForRow: true }
                    ,{ name: 'ORA'                      ,title: 'Ora'                      ,width: 50   ,format: formatHour             ,isNum: true  ,isUniqueKeyForRow: true      ,showDiff: true                      ,notSortable: true }
                    ,{ name: 'STATUS'                   ,title: 'Status'                   ,width: 110                                                                              ,showDiff: true  ,notSortable: true }
                    ,{ name: 'LINE_ITEM_TYPE'           ,title: 'Tipo'                     ,width: 110                                                                              ,showDiff: true  ,notSortable: true }
                    ,{ name: 'PRIORITY'                 ,title: 'Priorità'                 ,width: 50                                   ,isNum: true                                ,showDiff: true  ,notSortable: true }
                    ,{ name: 'IMPRESSIONS_SCHEDULED'    ,title: 'Imps Scheduled'           ,width: 135  ,format: formatImp0decPerc      ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                    ,{ name: 'BEGIN_DATE'               ,title: 'Data Inizio'              ,width: 115  ,format: formatDateWeek                       ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                    ,{ name: 'END_DATE'                 ,title: 'Data Fine'                ,width: 115  ,format: formatDateWeek                       ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                ]);
                // necessario aggiungere: conteggio e lista adunits, conteggio e lista placements, data inizio, data fine
            }
            
        } else if ( nValueTab === 'Forecast' ) {
                // Popolo aoAnagTableHeaders per Forecast
                aoHeaders.push(...[
                     { name: 'DATA'                     ,title: 'Data'                     ,width: 150  ,format: formatDateWeek         ,isNum: true  ,isUniqueKeyForRow: true      }
                    ,{ name: 'BACINO'                   ,title: 'Bacino'                   ,width: 100  ,format: formatNum0dec          ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                    ,{ name: 'OCCUPATO'                 ,title: 'Occupato'                 ,width: 100  ,format: formatNum0dec          ,isNum: true  ,additionalClass: formatZero  ,showDiff: true  ,notSortable: true }
                ]);
        }

        if ( nValueTab === 'Forecast' ) {
            set_dLastUpdate(dLastUpdateForecast);
        } else if ( nValueTab === 'Storico' ){
            set_dLastUpdate(dLastUpdateStoricoErog);
            // isLineItem && set_aoStoricoFromDB(aoStoricoErogFromDB)
        } else if ( nValueTab === 'Storico Anagrafico' ){
            set_dLastUpdate(dLastUpdateStoricoAnag);
            // isLineItem && set_aoStoricoFromDB(aoStoricoAnagFromDB)
        }

        set_aoAnagTableHeaders([...aoHeaders] || []);

        // Il setLoading lo setto a false nella useEffect associata a aoAnagTableHeaders
        // setLoading(false);
    };

    /* #region Storico */
        const getStoricoFromDB = async () => {
            bDebug && console.log('Dentro GetStoricoFromDB');
            setLoading(true);
            set_oNotifyOptions({ message: '' });
            
            // Chiamata per ottenere i valori da inserire nella tabella
            try {
                bDebug && console.log('pNumElem getStoricoFromDB: ', moment(dDataFine).diff( dDataInizio, 'days' ))
                const params = {
                     ...( isLineItem  && { pLineItemId:            id
                                         , ...( nValueTab === 'Storico'
                                                ? { getStoricoLineitemList:           true }
                                                : { getStoricoAnagraficoLineitemList: true } )
                                         , pDataInizio:            dDataInizio.format('YYYYMMDD')
                                         , pDataFine:              dDataFine.format('YYYYMMDD')  }
                                        //  , ...( !giornaliero && { pDataInizio:            dDataInizio.format('YYYYMMDD')})
                                        //  , ...( !giornaliero && { pDataFine:              dDataFine.format('YYYYMMDD')  })
                                        //  , ...(  giornaliero && { giornaliero } ) }
                        )
                    ,...( isAdUnit    && { pAdUnitId:                    id
                                         , pPlacementId:                 ''
                                         , getStoricoAdUnitList:         true 
                                         , pDataInizio:                  dDataInizio.format('YYYYMMDD')
                                         , pDataFine:                    dDataFine.format('YYYYMMDD')
                                         , pDeviceList:                  ''
                                         , pDataPrevisioneList:          dDataPrevisioneList.format('YYYYMMDD') }
                        )
                    ,...( isPlacement && { pAdUnitId:                    ''
                                         , pPlacementId:                 id
                                         , getStoricoAdUnitList:         true 
                                         , pDataInizio:                  dDataInizio.format('YYYYMMDD')
                                         , pDataFine:                    dDataFine.format('YYYYMMDD')
                                         , pDeviceList:                  ''
                                         , pDataPrevisioneList:          dDataPrevisioneList.format('YYYYMMDD')}
                        )
                    
                    // ,GAMaccount      : pAccountDesc
                };
                const response = await axios.get( config.API_URL + '/get', { headers, params } );
                
                if ( response && ( response?.status === 200 ) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                    // bDebug && console.table(response.data.aoRecords);
                    nValueTab === 'Storico' && set_aoStoricoErogFromDB(response.data.aoRecords);
                    nValueTab === 'Storico Anagrafico' && set_aoStoricoAnagFromDB(response.data.aoRecords);
                } else {
                    setLoading(false);
                }
                
            } catch(err) {
                console.error('ERR 192: Failed GetStoricoFromDB: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
                setLoading(false);
            }
        };
        
    /* #endregion Storico */

    /* #region Forecast */
        const getForecastADUnitFromGAM = async () => {
            bDebug && console.log('Dentro GetForecastADUnitFromGAM');
            setLoading(true);
            set_oNotifyOptions({ message: '' });

            // Chiamata per ottenere i valori da inserire nella tabella
            try {
            
                const params = {
                     ...( isAdUnit    && { pAdUnitId         : id   } )
                    ,...( isPlacement && { pPlacementId      : id   } )
                    ,...( isAdUnit    && { isAdunitTraffic   : true } )
                    ,...( isPlacement && { isPlaceTraffic    : true } )
                    ,isForecast      : true
                    ,GAMaccount      : account
                    ,messageForQueue : `Forecast§${ 
                                        account + '§' 
                                      + accountcode + '§' 
                                      + ( isAdUnit ? 'AD_UNITS' : 'PLACEMENTS' ) + '§' 
                                      + id }`
                };
                
                const response = await axios.get( config.API_URL + '/get-adunit-forecast', { headers, params } );

                if ( response && ( response?.status === 200 ) && response?.data ) {
                    if ( !response?.data?.errMessage ) {
                        let forecastedAssignedTimeSeries = transformData( response.data.forecastedAssignedTimeSeries )
                        let forecastedTimeSeries = transformData( response.data.forecastedTimeSeries )
                        bDebug && console.log('forecastedAssignedTimeSeries: ', forecastedAssignedTimeSeries );
                        bDebug && console.log('forecastedTimeSeries: ', forecastedTimeSeries );

                        let aoRecord = forecastedAssignedTimeSeries.map( (oElement, id) => {
                            return { DATA: oElement.date, OCCUPATO: +oElement.value, BACINO: +forecastedTimeSeries[id].value }
                        })
                        
                        set_aoForecastFromDB([...aoRecord.slice(0,59)]);
                        
                        set_dLastUpdateForecast( moment().tz('Utc').format('YYYYMMDDHHmmss') );
                        // console.log('Now: ', moment().tz('Utc').format('D MMM YYYY HH:mm'))
                        // console.log('Now: ', moment().tz('Utc').format('YYYYMMDDHHmmss'))
                    } else {
                        // !!! ToDo aggiungere notifica di risposta troppo lenta, necessario attendere la risposta
                        console.error('ERR 195: Failed GetForecastADUnitFromGAM - ', response?.data?.errMessage || 'Errore estrazione Forecast da GAM' );
                        set_oNotifyOptions({ message: `Impossibile recuperare il Forecast ${ isAdUnit ? 'Ad Unit' : 'Placement' } da GAM`, severity:'error' });
                        setLoading(false);
                    }
                } else {
                    console.error('ERR 194: Failed GetForecastADUnitFromGAM - ', response?.data?.errMessage || 'Errore estrazione Forecast da GAM' );
                    set_oNotifyOptions({ message: `Impossibile recuperare il Forecast ${ isAdUnit ? 'Ad Unit' : 'Placement' } da GAM`, severity:'error' });
                    setLoading(false);
                }
                
            } catch (err) {
                console.error('ERR 193: Failed GetForecastADUnitFromGAM - ', err);
                set_oNotifyOptions({ message: `Impossibile recuperare il Forecast ${ isAdUnit ? 'Ad Unit' : 'Placement' } da GAM`, severity:'error' });
                setLoading(false);
            }
        };
        
        const getS3File = async() => {

        };
    /* #endregion Forecast */

    /* #region UseEffect */
        // !!! Todo Use effect che controlla se stiamo atterrando nel componente tramite il sistema di notifiche, nel caso chiamo s3 per ottenere il file di forecast
        // useEffect(() => {
        //     if (isForecastNotify) {
        //         if ( aoForecastFromDB.length ) {
        //             getDetailTable();
        //         } else {
        //             getS3File(); 
        //         }
        //     }
        // }, []);

        // Use Effect OnChangeTab, controllo se ho già i dati da mostrare, nel caso chiamo solamente il popolamento della tabella, altrimenti lancio la chiamata a db
        // Questa use effect viene anche 'sfruttata' come useEffect iniziale per il primo atterraggio nella pagina ( no controllo onReload != null )
        useEffect(() => {
            bDebug && console.log('UseEffect 0 Iniziale - nValueTab')
                set_dLastUpdate('');
                setsConcatNamePage( isLineItem && ( nValueTab === 'Storico' ) ? 'Storico Anagrafico' : nValueTab );
                if (( nValueTab === 'Forecast'            && aoForecastFromDB.length    ) || 
                    ( nValueTab === 'Storico'             && aoStoricoErogFromDB.length ) ||
                    ( nValueTab === 'Storico Anagrafico'  && aoStoricoAnagFromDB.length )) {
                    getDetailTable();
                } else {
                    throwCallDB();
                }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ nValueTab ]);

        // UseEffect per condizionare la funzione da scatenare all'onReload
        useEffect(() => {
            bDebug && console.log('UseEffect 1 OnReload');
            if ( onReload != null ) {
                cleanAll();
                throwCallDB();
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ onReload ]);

        // UseEffect per condizionare il popolamento di aoAnagTableHeaders e l'ottenimento del DettaglioTabella
        useEffect(() => {
            bDebug && console.log('UseEffect 2 AoStoricoFromDB');
            // Controllo che non sia in fase di cleanAll o di ChiamataDB Fallita
            if (( nValueTab === 'Storico' && aoStoricoErogFromDB.length ) || ( nValueTab === 'Storico Anagrafico' && aoStoricoAnagFromDB.length )) {
                getDetailTable();
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ aoStoricoErogFromDB, aoStoricoAnagFromDB ]);

        // UseEffect per condizionare il popolamento di aoAnagTableHeaders e l'ottenimento del DettaglioTabella
        useEffect(() => {
            bDebug && console.log('UseEffect 2 AoForecastFromDB');
            // Controllo che non sia in fase di cleanAll o di ChiamataDB Fallita
            if ( aoForecastFromDB.length ) {
                getDetailTable();
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ aoForecastFromDB ]);

        // In tutte le UseEffect settiamo loading a false solamente se non viene eseguito il codice interno
        // Questa UseEffect è da considerarsi finale in caso di tabella per attendere che tutti gli state vengono settati correttamente e infine mostrato l'output
        useEffect(() => {
            bDebug && console.log('UseEffect 3 AoAnagTableHeaders');
            // Controllo che non sia in fase di cleanAll o di ChiamataDB Fallita
            if ( aoAnagTableHeaders.length ) {
                setLoading(false);
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ aoAnagTableHeaders ]);

        // UseEffect per controllare il cambio Data Inizio
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect dDataInizio ', dDataInizio.format('DD/MM/YYYY') );
            if ( dDataInizio && dDataInizio.isValid() ) {
                bDebug && console.log( `${dDataInizio} ${dDataInizio.isValid()}` );
                let bIsMin = (dDataInizio || moment()).format('YYYYMMDD') <= ( dDataFine || moment() ).format('YYYYMMDD');
                let bIsMaxDifDate = moment(dDataFine).diff( dDataInizio, 'days' );
                bDebug && console.log('bIsMaxDifDate: ', bIsMaxDifDate)
                if ( !bIsMin ) {
                    console.log('pNumElem dDataInizio isNotMin: ', moment(dDataFine).diff( dDataInizio, 'days' ))
                    set_oNotifyOptions({ message: `Range di date non conforme, controllare prima di procedere oltre`, severity:'warning', timeout: 5 })
                    set_dDataFine(moment(dDataInizio));// .add( nDays, 'days' )
                } else if ( bIsMaxDifDate > nDays ) {
                    set_oNotifyOptions({ message: `Range di datetroppo ampio, massimi giorni estraibili: ${ nDays }`, severity:'warning', timeout: 5 })
                    set_dDataFine(moment(dDataInizio).add( nDays, 'days' ));
                }
            } else {
                set_oNotifyOptions({ message: `Attenzione: la data inserita non è valida, controllare prima di procedere oltre`, severity:'warning', timeout: 5 })
            }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ dDataInizio ]);
    
        // UseEffect per controllare il cambio Data Fine
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect dDataFine ', dDataFine.format('DD/MM/YYYY') );
            if ( dDataFine && dDataFine.isValid() ) {
                bDebug && console.log( `${dDataFine} ${dDataFine.isValid()}` );
                let bIsMag = (dDataFine || moment()).format('YYYYMMDD') >= (dDataInizio || moment()).format('YYYYMMDD');
                let bIsMaxDifDate = moment(dDataFine).diff( dDataInizio, 'days' );
                bDebug && console.log('bIsMaxDifDate: ', bIsMaxDifDate)
                if ( !bIsMag ) {
                    console.log('pNumElem dDataInizio isNotMag: ', moment(dDataFine).diff( dDataInizio, 'days' ))
                    set_oNotifyOptions({ message: `Range di date non conforme, controllare prima di procedere oltre`, severity:'warning', timeout: 5 })
                    set_dDataInizio(moment(dDataFine)); //.subtract( nDays, 'days' )

                } else if ( bIsMaxDifDate > nDays ) {
                    set_oNotifyOptions({ message: `Range di date troppo ampio, massimi giorni estraibili: ${ nDays }`, severity:'warning', timeout: 5 })
                    set_dDataInizio(moment(dDataFine).subtract( nDays, 'days' ));
                }
            } else {
                set_oNotifyOptions({ message: `Attenzione: la data inserita non è valida, controllare prima di procedere oltre`, severity:'warning', timeout: 5 })
            }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ dDataFine ]);
    /* #endregion UseEffect */

    /* #Region UtilsFunction */
        // Function per CleanAllValue
        const cleanAll = () => {
            bDebug && console.log('Dentro CleanAll');
            set_dLastUpdate('');
            if ( nValueTab === 'Storico' ) {
                set_aoStoricoErogFromDB([]);
            } else if ( nValueTab === 'Storico Anagrafico' ) {
                set_aoStoricoAnagFromDB([]);
            } else if ( nValueTab === 'Forecast' ) {
                set_aoForecastFromDB([]);
            }
            // set_aoAnagTableHeaders([]);
        };

        const setTitle = () => {
            return  isLineItem    ? <span className='flexyTitle'><span>Line Item { formatLineItemLink(  id ) } { dDateStartFromDb ? ` | data inizio: ${ dDateStartFromDb.format('DD MMM YYYY') }` : '' } { dDateEndFromDb ? ` - data fine: ${ dDateEndFromDb.format('DD MMM YYYY') }` : '' } </span>
                                      <CustomNoWidthTooltip title={ sDettaglioDescription } placement='bottom' arrow><span className='dettaglioDescription'>{ sDettaglioDescription }</span></CustomNoWidthTooltip></span>
                  : isAdUnit      ? <span className='flexyTitle'><span>AD Unit   {   formatAdUnitLink(  id ) } </span>
                                      <CustomNoWidthTooltip title={ sDettaglioDescription } placement='bottom' arrow><span className='dettaglioDescription'>{ sDettaglioDescription }</span></CustomNoWidthTooltip></span>
                  : isPlacement   ? <span className='flexyTitle'><span>Placement { formatPlacementLink( id ) } </span>
                                      <CustomNoWidthTooltip title={ sDettaglioDescription } placement='bottom' arrow><span className='dettaglioDescription'>{ sDettaglioDescription }</span></CustomNoWidthTooltip></span>
                  : ''
        };

        const buttonGroupTableGraph = () => {
            return <div className="component-options-wrapper-anagraphDetail-title">
                <div  className="component-options-wrapper-title">
                    <div className="component-options-wrapper-subtitle">
                        <Link to={`/anagrafica/${ account }/${ accountcode }/${ dettaglio }`} className='link' >
                            <Button className="backButton" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                <span>{ utils.formatNamePage(dettaglio) }</span>
                            </Button>
                        </Link>
                    </div>
                    <div className={ `titleAnagraficaDettaglio ${dettaglio}` }>{ setTitle() }</div>
                </div>
                <div  className="component-options-wrapper-title">

                  { (( nValueTab === "Storico" )  || ( nValueTab === 'Storico Anagrafico' )) &&
                    <div className="storicoDPicker">
                      <LocalizationProvider dateAdapter={AdapterMoment} key="date" >

                      {(isAdUnit || isPlacement) &&
                        <span className="singularPicker d-flex jumpDateIcon" >
                            { utils.jumpSegmentDate({ sPosition: 'before', dDateVal: dDataPrevisioneList, set_dDateVal: set_dDataPrevisioneList, dDateCompare: dDataInizio }) }
                            <Tooltip title={`Data minima: ${dDataInizio.format('DD/MM/YYYY')} | Data massima: OGGI`} placement='top'>
                                    <span className='pm-0'>
                                    <DatePicker
                                        id        = "dataPrevisione"
                                        label     = "Data previsione"
                                        key       = "dataPrevisione"
                                        format    = "DD/MM/YYYY"
                                        views     = {['year', 'month', 'day']}
                                        value     = {dDataPrevisioneList}
                                        minDate   = {dDataInizio}
                                        maxDate   = {moment()} 
                                        onChange  = {(value) => { set_dDataPrevisioneList(value)}}
                                        className = "storicoDPickerComponent mybordercolor"
                                    />
                                </span>
                            </Tooltip>
                            { utils.jumpSegmentDate({ sPosition: 'after', dDateVal: dDataPrevisioneList, set_dDateVal: set_dDataPrevisioneList, dDateCompare: dDataFine }) }
                        </span>
                      }

                        <span className='singularPicker d-flex jumpDateIcon'>
                            { utils.jumpSegmentDate({ sPosition: 'before', dDateVal: dDataInizio, set_dDateVal: set_dDataInizio, dDateCompare: moment().subtract(1, 'years'), dSegmentDateVal: dDataFine, set_dSegmentDateVal: set_dDataFine }) }
                            {/* <Tooltip title={`Data minima: ${moment().subtract(30, 'days').format('DD/MM/YYYY')} | Data massima: ${moment().format('DD/MM/YYYY')}`} placement='top'> */}
                            <Tooltip title={`Numero massimo di giorni estraibili: ${ nDays }`} placement='top'>
                                <span className='pm-0'>
                                    <DatePicker
                                        id        = "dataInizio"
                                        label     = "Visualizza dal giorno"
                                        key       = "dataInizio"
                                        format    = "DD/MM/YYYY"
                                        views     = {['year', 'month', 'day']}
                                        value     = {dDataInizio}
                                    //    minDate   = {moment().subtract(nDays, 'days')}
                                        maxDate   = {moment()}
                                        onChange  = {(value) => set_dDataInizio(value)}
                                        className = "storicoDPickerComponent mybordercolor"
                                    />
                                </span>
                            </Tooltip>
                        </span>

                        <span className='d-flex jumpDateIcon'>
                            {/* <Tooltip title={`Data minima: ${dDataInizio.format('DD/MM/YYYY')} | Data massima: ${moment().format('DD/MM/YYYY')}`} placement='top'> */}
                            <Tooltip title={`Numero massimo di giorni estraibili: ${ nDays }`} placement='top'>
                                <span className='pm-0'>
                                    <DatePicker
                                        id        = "dataFine"
                                        label     = "al giorno"
                                        key       = "dataFine"
                                        format    = "DD/MM/YYYY"
                                        views     = {['year', 'month', 'day']}
                                        value     = {dDataFine}
                                        // minDate   = {dDataInizio}
                                        maxDate   = {moment()}
                                        onChange  = {(value) => set_dDataFine(value)}
                                        className = "storicoDPickerComponent mybordercolor"
                                    />
                                </span>
                            </Tooltip>
                            { utils.jumpSegmentDate({ sPosition: 'after', dDateVal: dDataFine, set_dDateVal: set_dDataFine, dDateCompare: moment(), dSegmentDateVal: dDataInizio, set_dSegmentDateVal: set_dDataInizio }) }
                        </span>
                      </LocalizationProvider>
                    </div>
                  }

                  {/* { ( nValueTab !== 'Forecast' ) ? */}
                    <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className={`buttonGroup ${(nValueTab !== 'Storico Anagrafico') && ( aoStoricoErogFromDB.length || aoForecastFromDB.length ) ? '' : 'hiddenButton' }`}>
                        <Button
                            className={`graphButton link myShadow ${sGraphEnabled === 'line' ? 'activedButton' : ''}`}
                            title="Mostra Grafico Lineare" variant="outlined" onClick={() => handleSettingGraph('line')} disableElevation
                        >
                            <SsidChartIcon />
                        </Button>
                        <Button
                            className={`graphButton link myShadow ${sGraphEnabled === 'bar' ? 'activedButton' : ''}`}
                            title="Mostra Grafico a Barre" variant="outlined" onClick={() => handleSettingGraph('bar')} disableElevation
                        >
                            <LeaderboardIcon />
                        </Button>
                        <Button
                            className={`graphButton link myShadow ${sGraphEnabled === '' ? 'activedButton' : ''}`}
                            title="Mostra Tabella" variant="outlined" onClick={() => handleSettingGraph('')} disableElevation
                        >
                            <TableChartIcon />
                        </Button>
                    </ButtonGroup>    
                {/* : <></>
                  } */}
                  <Tooltip title="Aggiorna Elenco" placement="top" arrow>
                    <IconButton className="reloadButton link myShadow" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => set_onReload(!onReload) }>
                        <CachedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
            </div>
        };

        const myKeyPress = (e) => {
            bDebug && console.log('Im here')
            if (e.key === 'Enter') {
                e.preventDefault();
                set_onReload(!onReload);
            }
        };
    /* #endregion */

    return (
        <div className="component-container anagraphDetail" tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }>
            <div className="component-card anagDetail2">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={nValueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomCssTabList onChange={ (event, newValue) => set_nValueTab(newValue) } aria-label="lab API tabs example">
                                <CustomCssTab label={ (isAdUnit || isPlacement) ? 'Storico' : "Storico Erogato" } value="Storico" />
{(!isAdUnit && !isPlacement) && <CustomCssTab label="Storico Anagrafico"                                          value="Storico Anagrafico" /> }
  {(isAdUnit || isPlacement) && <CustomCssTab label="Forecast"                                                    value="Forecast" /> }
                            </CustomCssTabList>
                        </Box>
                        
                        <TabPanel value="Storico" className="tabPanel anag-storico">
                            {
                                buttonGroupTableGraph()
                            }
                            {
                                loading ? <CircleProgress /> : <>{
                                    ( aoStoricoErogFromDB?.length && aoAnagTableHeaders?.length && Array.isArray(aoStoricoErogFromDB) )
                                    ? sGraphEnabled
                                        ? <Graph
                                            oGraphContent = {{
                                                 sChartType     : sGraphEnabled
                                                ,aoRows         : aoStoricoErogFromDB
                                                ,asElementX     : (isAdUnit || isPlacement) ? [ 'DATA' ]                 : [ 'DATA_ORA' ]
                                                ,asElementY     : (isAdUnit || isPlacement) ? [ 'BACINO_S', 'BACINO_F' ] : [ 'IMPRESSIONS_DELIV' ,'IMPRESSIONS_SCHEDULED' ]
                                                ,oMappingField  : oMappingFieldStorico
                                                ,...( isLineItem && { formatDate: formatDateWeekHour })
                                            }}
                                            className = 'grafico'
                                            />
                                        : <SimpleTable
                                            chiave            = { 'ANAGTABLE' }
                                            sTableDataType    = { 'ANAGTABLE' }
                                            aoRows            = { aoStoricoErogFromDB }
                                            aoCols            = { aoAnagTableHeaders }
                                            oSortOptions      = { { isSortable: true } }
                                            oExportOption     = { { sFileName: `Anagrafica ${ nValueTab } ${ dettaglio } ${ id }` } }
                                            oHeadersOptions   = { { tooltip: true, breakline: true } }
                                            />
                                    : <div className="component-options-wrapper-alarmNoRecord">
                                        <FolderOffIcon />&nbsp; Nessun risultato trovato
                                      </div>
                                }</>
                            }
                        </TabPanel>
{(!isAdUnit && !isPlacement) && <TabPanel value="Storico Anagrafico" className="tabPanel anag-storico">
                            {
                                buttonGroupTableGraph()
                            }
                            {
                                loading ? <CircleProgress /> : <>{
                                  ( aoStoricoAnagFromDB?.length && aoAnagTableHeaders?.length && Array.isArray(aoStoricoAnagFromDB) )
                                    ? <SimpleTable
                                        chiave            = { 'ANAGTABLE' }
                                        sTableDataType    = { 'ANAGTABLE' }
                                        aoRows            = { aoStoricoAnagFromDB }
                                        aoCols            = { aoAnagTableHeaders }
                                        oSortOptions      = { { isSortable: true } }
                                        oExportOption     = { { sFileName: `Anagrafica ${ nValueTab } ${ dettaglio } ${ id }` } }
                                        oHeadersOptions   = { { breakline: true } }
                                      />
                                    : <div className="component-options-wrapper-alarmNoRecord">
                                        <FolderOffIcon />&nbsp; Nessun risultato trovato
                                      </div>
                                }</>
                            }
                        </TabPanel> }
      {(!isLineItem) && <TabPanel value="Forecast" className="tabPanel anag-forecast">
                            {
                                buttonGroupTableGraph()
                            }
                            {
                                loading ? <CircleProgress /> : <>{
                                    ( aoForecastFromDB?.length && aoAnagTableHeaders?.length && Array.isArray(aoForecastFromDB) )
                                    ? sGraphEnabled
                                        ? <Graph
                                            oGraphContent = {{
                                                 sChartType     : sGraphEnabled
                                                ,aoRows         : ( aoForecastFromDB || [] )
                                                ,asElementX     : [ 'DATA' ]
                                                ,asElementY     : [ 'BACINO', 'OCCUPATO' ]
                                                ,oMappingField  : oMappingFieldForecast
                                            }}
                                            className = 'grafico'
                                            />
                                        : <SimpleTable
                                            chiave            = { 'ANAGTABLE' }
                                            sTableDataType    = { 'ANAGTABLE' }
                                            aoRows            = { aoForecastFromDB }
                                            aoCols            = { aoAnagTableHeaders }
                                            oSortOptions      = { { isSortable: true } }
                                            oExportOption     = { { sFileName: `Anagrafica ${ nValueTab } ${ dettaglio } ${ id }` } }
                                            oHeadersOptions   = { { breakline: true } }
                                            />
                                    : <div className="component-options-wrapper-alarmNoRecord">
                                        <FolderOffIcon />&nbsp; Nessun risultato trovato
                                        </div>
                                }</>
                            }
                        </TabPanel> }
                    </TabContext>
                </Box>
            </div>
        </div>
    )
};

export default AnagraficheStoricoForecast;
