import moment    from 'moment';
// import FileSaver from "file-saver";
import {  ArrowLeft
        , ArrowRight
        , ForecastIcon } from '../assets/icons/icons.js';
let timeoutId = null;

const commonUtils = {
     regexEmailValidator : /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/
    ,regexPasswordValidator : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/
    
    ,formatNumberWithOptions: function ( num, oOptions = {} ) {
        
        let { sOuputThousandsSeparator, sOuputDecimalSeparator, nOuputDecimals, sInputDecimalSeparator, sInputThousandsSeparator, sZero } = oOptions;
        
        // verifico validità numero e lo converto in stringa
        const nNum = Number(num);
        let   sNum = '';
        if ( Number.isNaN(nNum) ) {
            console.error( 'numero non valido: ', num );
            return '';
        } else {
            if ( sZero && nNum === 0 ) { return sZero; }
            sNum = nNum.toString();
        }
        
        // default dei parametri
        sInputDecimalSeparator   = typeof sInputDecimalSeparator   === 'string' ? sInputDecimalSeparator   : '.';
        sInputThousandsSeparator = typeof sInputThousandsSeparator === 'string' ? sInputThousandsSeparator : '';
        sOuputDecimalSeparator   = typeof sOuputDecimalSeparator   === 'string' ? sOuputDecimalSeparator   : ',';
        sOuputThousandsSeparator = typeof sOuputThousandsSeparator === 'string' ? sOuputThousandsSeparator : '.';
        nOuputDecimals           = ( ( typeof nOuputDecimals === 'number')      // numero
                                    && !Number.isNaN(nOuputDecimals)           // non NaN
                                    && ( nOuputDecimals >= 0 )                 // maggiore o uguale a zero
                                   ) ? nOuputDecimals : '';
        
        // divido il numero in parte intera e decimale in base al carattere di separatore decimali di input indicato (o il punto di default)
        let [ sIntPart = '', sDecPart = '' ] = sNum.split(sInputDecimalSeparator);
        
        if ( sInputThousandsSeparator ) {
            sIntPart = sIntPart.split(sInputThousandsSeparator).join('');
        }
        
        // aggiunto il separatore di migliaia richiesto
        if ( sIntPart.length > 3 ) { sIntPart = sIntPart.replace( /\B(?=(\d{3})+(?!\d))/g, sOuputThousandsSeparator ); }
        
        // aggiungo zeri in coda se necessario, altrimenti tronco fino al massimo numero di decimali richiesto
        if ( nOuputDecimals !== '' ) {
            sDecPart = sDecPart.padEnd( nOuputDecimals, '0' ).substring( 0, nOuputDecimals );
        }
        
        // ricompongo il numero aggiungendo il separatore di decimali richiesto se necessario
        return sIntPart + ( sDecPart ? ( sOuputDecimalSeparator + sDecPart ) : '' );
        
    }

    ,fixDecimals: function ( value, decimals = 2, sZero = 0 ) {
    
        // METODO 1
        return +( Math.round(value + ( 'e+' + decimals ) )  + ( 'e-' + decimals ) ); // Example: preciseRound(1.005,2) === 1.01
        
        // // METODO 2
        // return value.toFixed( decimals )
        
        // // METODO 3
        // return formatNumberWithOptions( 
        //     value.toFixed( decimals ),
        //     { nOuputDecimals: decimals, sZero }
        // );
        
    }

    ,getMinMaxArray: function ( array ) {
        let nMin;
        let nMax;
        for ( let i = 0; i < array.length; i++ ) {
            if ( i === 0 ) {
                nMin = array[i];
                nMax = array[i];
            } else {
                nMin = (nMin > array[i]) ? array[i] : nMin;
                nMax = (nMax < array[i]) ? array[i] : nMax;
            }
        }
        return [ nMin, nMax ];
    }

    ,getMinObj: function ( attrib, array ) {
        const checker = (o, i) => (typeof(o) === 'object') && o[i];

        return ( array.length && array.reduce(function(prev, curr){
            const prevOk = checker(prev, attrib);
            const currOk = checker(curr, attrib);

            // console.log(`curr[attrib]:  ${curr[attrib]}  prev: ${ prev[attrib] } ${ ( +prev[attrib] || 0 ) < ( +curr[attrib] || 0 ) }` )
            if (!currOk) return {};
            if (!prevOk) return curr;
            if (!currOk) return prev;
            return (( +prev[attrib] || 0 ) < ( +curr[attrib] || 0 )) ? prev : curr; 
        })) || null;
    }    

    ,getMaxObj: function ( attrib, array ) {
        const checker = (o, i) => typeof(o) === 'object' && o[i];

        return ( array.length && array.reduce(function(prev, curr){
            const prevOk = checker(prev, attrib);
            const currOk = checker(curr, attrib);

            // console.log('curr[attrib]: ', curr[attrib] )
            // console.log(`curr[attrib]:  ${curr[attrib]}  prev: ${ prev[attrib] } ${ ( +prev[attrib] || 0 ) < ( +curr[attrib] || 0 ) }` )
            if (!prevOk && !currOk) return {};
            if (!prevOk) return curr;
            if (!currOk) return prev;
            return (( +prev[attrib] || 0 ) > ( +curr[attrib] || 0 )) ? prev : curr; 
        })) || null;
    }
    
    ,formatNamePage: function ( string = 'home' ) {
        // console.log('Dentro formatNamePage: ', string);
        return (
            (string||'')
                .replace(/([^A-Za-z0-9])+/g,' ')
                .trim()
                .split(' ')
                .map( string => ( string && ( string[0].toUpperCase() + string.toLowerCase().slice(1) )) )
                .join(' ')
        );
        
    }

    ,formatTitleHeader: function ( str = 'home' ) {
        let index = -1;
        let title = str.replace(/([^A-Za-z0-9])+/g,' ').trim().split(' ');
    
        // console.log('Step 1 Title: ', title.join(' ') );

        // Controlli Per Account
        index = title.indexOf('dvn');
        if ( index > -1 ) {
            // In futuro dovremo differenziare il comportamento per pagine Allarmi | Anagrafiche
            title[ index ] = '| DVN |';
            title.splice( index + 1 , 1 );   
        }

        index = title.indexOf('youtube');
        if ( index > -1 ) {
            title[ index ] = '| Youtube |';
            title.splice( index + 1 , 1 );   
        }

        index = title.indexOf('eurosport');
        if ( index > -1 ) {
            title[ index ] = '| Eurosport |';
            title.splice( index + 1 , 1 );   
        }
        
        index = title.indexOf('ilsole24ore');
        if ( index > -1 ) {
            title[ index ] = '|';   
            title.splice( index + 1 , 1 );     
        }
        
        // console.log('Step 2   Title: ', title );
        // console.log('Step 2.1 Title: ', title.join(' ') );
        // console.log('Step 2.2 Title lenght: ', title.length );

        // Controlli per Anagrafiche Dettaglio
        if ((title.indexOf('anagrafica') > -1) && (( title.indexOf('UNITS') > -1 && title.length === 5 ) || ( title.indexOf('LINE') > -1 && title.length === 5 ) || ( title.indexOf('PLACEMENTS') > -1 && title.length === 4 ))){
            index = title.findIndex( string => string.includes('contending') || string.includes('allarme') );
            // console.log(`title[ ${index} ]: ${title[ index ]}`);
            if ( index > -1 ) {
                title[ index ] = '| Contending';
            } else {
                title.pop();
            }
        } 
        // console.log('Step 3 Title: ', title);
        // Controlli per Anagrafiche Storico|Forecast
        index = title.indexOf('dettaglio');
        if ( index > -1 ) {
            title = title.slice(0,index - 1);
            // title[ index - 1 ] = '|';
        }

        // Controlli per allarmi !!! Necessario vengano dopo i controlli di anagrafiche altrimenti conflitto
        index = title.indexOf('allarme')
        if ( index > -1 && title.length > 2) {
            title = title.slice(0,2);
            title.push('| Dettaglio');
        }

        // console.log('title: ', title);
        // console.log('Final Title: ', title.join(' '));

        let asFixString = [ '| DVN |', '| Eurosport |', '| Youtube |', '| Contending', '| Dettaglio' ];

        return title.filter( Boolean ).map( stringa => ( asFixString.find( sAccount => sAccount === stringa ) ? stringa : ( stringa[0].toUpperCase() + stringa.toLowerCase().slice(1) ) ) )
                    .join(' ').trim();
    }
    
    ,formatDate: function (val) { return ( ( val === moment().format('YYYYMMDD') ) ? 'OGGI' : ( +val >= 99990000 ) ? '' : moment(val, 'YYYYMMDD').format('D MMM YYYY') ) }

    ,formatDateWeek: function (val) { return ( ( val === moment().format('YYYYMMDD') ) ? 'OGGI' : ( !val || (+val >= 99990000) ) ? '' : moment(val, 'YYYYMMDD').format('ddd D MMM YYYY') ) }

    ,formatDateWeekHour: function (val) { return ( (  moment(val).format('YYYYMMDD') === moment().format('YYYYMMDD') ) ? `OGGI h${ moment(val).format('HH:mm')}` : ( !val || (+val >= 99990000000000) ) ? '' : moment(val, 'YYYYMMDDHHmmss').format('ddd D MMM YYYY HH:mm') ) }

    ,formatTime: function (val) { return( moment(val, 'HHmm').format('HH:mm')) }

    ,formatDateTime: function (
        
        stringDate,
        
        {   // defaults
             input    = 'YYYYMMDDHHmmss'
            ,output   = 'D MMMM YYYY HH:mm'
            ,useTimezone = false
            ,fromNow  = false
        } = { input: 'YYYYMMDDHHmmss', output: 'D MMMM YYYY HH:mm', fromNow: false }
    
    ) {
        
        let   outputDate     = '';
        const cellMomentDate = moment(stringDate, input);
        const cellDate       = cellMomentDate.toDate();
        const timezoneOffset = cellDate.getTimezoneOffset() * -1; // Es. -120 (minuti) uguale a UTC+2
        // console.log('formatexecution: ', stringDate)
        
        if ( cellMomentDate.isValid() ) {
            
            if ( useTimezone ) {
                
                if ( timezoneOffset >= 0 ) {
                    cellMomentDate.add(      timezoneOffset,      'minutes' );
                } else {
                    cellMomentDate.subtract( timezoneOffset * -1, 'minutes' );
                }
                
            }
            
            if ( output ) {
                outputDate = cellMomentDate.format( output );
                if ( useTimezone ) {
                    // outputDate += ' UTC ' + ( timezoneOffset >= 0 ? '+' : '' ) + ( timezoneOffset / 60 );
                }
            }
            
            if ( fromNow ) {
                outputDate += output ? ' (' : '';
                outputDate += cellMomentDate.fromNow();
                outputDate += output ? ')' : '';
            }
            
        }
        
        return outputDate;
        
    }

    , debounce(fn, time) {

        function wrapper(...args) {

            if ( timeoutId ) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(
                () => {
                    timeoutId = null;
                    fn(...args);
                }, time
            );

        }

        return wrapper;

    }
    
    , getFirstLastDates: function( s, e ) {
        // es. startDate: { year: 2018, month: 12, day: 31 }
        const dataInizio    = moment( s.year  + '-' + s.month + '-' + s.day );
        const dataFine      = moment( e.year  + '-' + e.month + '-' + e.day );
        if ( !dataInizio.isValid() || !dataFine.isValid() ) {
            return ['', ''];
        }
        
        return [ dataInizio.format('YYYYMMDD'), dataFine.format('YYYYMMDD') ];
    }
    
    , getSingleDates: function( s, e ) {
        // es. startDate: { year: 2018, month: 12, day: 31 }
        const dataInizio    = moment( s.year  + '-' + s.month + '-' + s.day );
        const dataFine      = moment( e.year  + '-' + e.month + '-' + e.day );
        if ( !dataInizio.isValid() || !dataFine.isValid() ) {
            return [];
        }
        const dateArray     = Array.from(
            { length: dataFine.diff(dataInizio, 'days') + 1 },
            (_, index) => moment(dataInizio).add(index, 'days')
        );
        return dateArray.map( d => d.format('YYYYMMDD') );
    }

    , getRangeDate: function( dStartDate, dEndDate ) {
        const dataInizio    = moment( dStartDate.year  + '-' + dStartDate.month + '-' + dStartDate.day );
        const dataFine      = moment( dEndDate.year    + '-' + dEndDate.month   + '-' + dEndDate.day   );
        if ( !dataInizio.isValid() || !dataFine.isValid() ) {
            return '';
        }

        return `Da ${ dataInizio.format('ddd D MMM YYYY') } a ${ dataFine.format('ddd D MMM YYYY')}`;
    }

    , jumpSegmentDate: function({ sPosition, dDateVal, set_dDateVal, dDateCompare, dSegmentDateVal = null, set_dSegmentDateVal = null, addClass = '', complexObj = {}, dDateValKey = '', dSegmentDateValKey = ''  }) {
        /* Function che ritorna un bottone di gestione date
        Parametri: [ sPosition                             : 'left' || 'right'
                   , dDateVal & set_dDateVal               : data e set_function della data da modificare
                   , dDateCompare                          : data con cui effettuare il controllo se si sta rispettando i limiti impostati
                   , dSegmentDateVal & set_dSegmentDateVal : data e set_function "collegata" che necessita di essere modificata in coppia  
                   , complexObj                            : indica che le date sono contenute in un unico oggetto nel componente padre, da passare decostruito nella set_dDateVal
                   , dDateValKey & dSegmentDateValKey      : stringhe contenenti il valore della chiave a cui assegnare le date in caso di complexObj ] */
        if ( dDateVal && dDateVal.isValid() && dDateCompare && dDateCompare.isValid() ){
            // console.log('dDateVal: ', dDateVal)
            if ( Object.keys(complexObj).length ) {
                if ( sPosition === 'before' ) {
                    return <button className={`pm-0 ${ addClass } ${ ( +dDateVal.format('YYYYMMDD') <= +dDateCompare.format('YYYYMMDD') ) ? 'hiddenButton' : '' }`} 
                                onClick={ () => set_dDateVal({ ...complexObj, [ dDateValKey ]: moment(dDateVal).subtract(1, 'days'), [ dSegmentDateValKey ]: moment(dSegmentDateVal).subtract(1, 'days') }) }>
                            <ArrowLeft />
                        </button>;
                } else if ( sPosition === 'after') {
                    return <button className={`pm-0 ${ addClass } ${ ( +dDateVal.format('YYYYMMDD') >= +dDateCompare.format('YYYYMMDD') ) ? 'hiddenButton' : '' }`} 
                                onClick={ () => set_dDateVal({ ...complexObj, [ dDateValKey ]: moment(dDateVal).add(1, 'days'), [ dSegmentDateValKey ]: moment(dSegmentDateVal).add(1, 'days') }) }>
                            <ArrowRight />
                        </button>
                } else {
                    return <></>;
                }
            } else {

                if ( sPosition === 'before' ) {
                    return <button className={`pm-0 ${ addClass } ${ ( +dDateVal.format('YYYYMMDD') <= +dDateCompare.format('YYYYMMDD') ) ? 'hiddenButton' : '' }`} 
                                    onClick={ () => { set_dDateVal(moment(dDateVal).subtract(1, 'days'));
                                                    dSegmentDateVal && set_dSegmentDateVal(moment(dSegmentDateVal).subtract(1, 'days')) }}>
                            <ArrowLeft />
                        </button>;
                } else if ( sPosition === 'after') {
                    return <button className={`pm-0 ${ addClass } ${ ( +dDateVal.format('YYYYMMDD') >= +dDateCompare.format('YYYYMMDD') ) ? 'hiddenButton' : '' }`} 
                                    onClick={ () => { set_dDateVal(moment(dDateVal).add(1, 'days'));
                                                    dSegmentDateVal && set_dSegmentDateVal(moment(dSegmentDateVal).add(1, 'days'))}}>
                            <ArrowRight />
                        </button>
                } else {
                    return <button className="pm-0 hiddenButton" ></button>;
                }
            }
        } else {
            return <button className="pm-0 hiddenButton" ></button>;
        }
    }

    , oMessage: function( cookie ) {
        console.log('oMessageFromSQS: ', cookie )
        // this.messageId        = cookie.Title // cookie.Name;
        const [ messageId, messageBody ] = cookie.split('=');
        
        const [/* messageId, */ messageTipologia, account, accountcode, dettaglio, dettaglio_id, messageUserId, fileName, dateExpires, msgState /*,messageBody */] = messageBody.split('§'); //cookie.value.split('§');
        // this.messageId        = messageId;
        return {
             messageId
            ,messageTipologia : messageTipologia
            ,dettaglio        : dettaglio
            ,dettaglio_id     : dettaglio_id
            ,messageUserId    : messageUserId
            ,fileName         : fileName
            ,messageBody      : `${messageTipologia} ${dettaglio} ${dettaglio_id}`
            ,dateExpires      : dateExpires // new Date()
            ,msgState         : msgState // Maybe 'unread'
            ,msgURL           : messageTipologia === 'Forecast' ? `anagrafica/${ account }/${ accountcode }/${ dettaglio }/${ dettaglio_id }/dettaglio/forecast` : ''
            ,msgIcon          : messageTipologia === 'Forecast' ? <ForecastIcon/> : ''
            ,sReadCoockie     : `${this.messageId}=${messageTipologia}§${account}§${accountcode}§${dettaglio}§${dettaglio_id}§${messageUserId}§${fileName}§${dateExpires}§read; expires=${ dateExpires }; path=/` // expires=${ cookie.expires }
            ,sCancelCookie    : `${this.messageId}=${messageUserId}; expires=${ new Date().toUTCString() }; path=/`
        }
        // this.setmsgState = function ( newState ) {
        //     this.msgState = newState
        // }
    }

    , fileSaver: function( aasOutputRows, sFileName ) {
        const OUTPUT_FILE_COLUMN_SEPARATOR = ';';
        const sFinalFileName = sFileName     || 'file';
        let   sFinalResult   = '';
    
        aasOutputRows.forEach( asOutputRow => {
            // console.log('asOutputRow; ', asOutputRow)
            sFinalResult += asOutputRow.join(OUTPUT_FILE_COLUMN_SEPARATOR) + '\n';
        });
    
        const blob = new Blob( [ sFinalResult ], { type: 'text/plain;charset=utf-8' } );
        try {
            // eslint-disable-next-line no-undef
            saveAs( blob, sFinalFileName + '.csv' );
        } catch ( err ) {
            console.log( 'Save file: "' + sFinalFileName + '"' );
            console.error(err);
        }
        // console.log( '[SUCCESS] Save file: "' + sFinalFileName + '"' );
    }

    ,mapTooltip: {
         CheckInventory:        "Stima della percentuale che verrà erogata sul totale delle Impressions Schedulate.\nL'erogato è dato da: impression già erogate + impression che GAM stima disponibili per l'erogazione.\nSe la stima dell'erogato risulta superiore alle impression schedulate l'indicatore sarà al 100%, viceversa darà un valore inferiore a 100%"
        ,Progressivoobiettivo:  "Progressione lineare che l'erogato della campagna dovrebbe registrare per arrivare al 100% alla data di fine campagna.\nRappresenta quindi il rapporto, in percentuale, tra numero di minuti trascorsi da inizio campagna all'ora corrente e numero di minuti totali della campagna."
        ,Progressivoattuale:    "Progressione effettiva dell'erogato della campagna,\novvero il rapporto tra impressions erogate e impression schedulate."
        ,Pacing:                "Rapporto in percentuale tra Progressivo attuale e Progressivo obiettivo"
        ,MMF:                   "Media mobile veloce, ovvero la media del bacino calcolata sugli ultimi 14 giorni"
        ,MMS:                   "Media mobile lenta, ovvero la media del bacino calcolata sugli ultimi 28 giorni"
    }
};

export default commonUtils;
