import React, { useState }                      from 'react';
import { BrowserRouter as Router, Route, Routes }          from 'react-router-dom';
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext.js'
import AuthIndex               from './auth/index';
import MainIndex               from './routes/index';
import { NotificationContainer } from 'react-notifications';
import { SnackAlert }          from './components/SnackAlert/SnackAlert.js';
import LogoDis24               from './assets/images/dis24logo.png';
import LogoDian                from './assets/images/digitalanalyzerlogo.svg';
import LogoDianText            from './assets/images/digitalanalyzerlogotext.svg';
import LogoDianOnlyText        from './assets/images/digitalanalyzertext.svg';
import 'react-notifications/lib/notifications.css';
import './styles/App.css';

export const AppContext = React.createContext();

const env = process.env.REACT_APP_ENV;

const AuthRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<AuthIndex/>} />
      </Routes>
    </Router>
  );
};

const MainRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<MainIndex/>} />
      </Routes>
    </Router>
  );
};

const
     selectedLogo          =  (env.includes('sole')) ? LogoDis24        : ( env === 'proddisc' ) ? LogoDian     : LogoDian
    ,selectedLogoText      =  (env.includes('sole')) ? LogoDis24        : ( env === 'proddisc' ) ? LogoDianText : LogoDianText
    ,selectedLogoOnlyText  = !(env.includes('sole')) ? LogoDianOnlyText : ''
;

const App = () => {

    const [ oFilterParamsTotal   ,set_oFilterParamsTotal ] = useState({
        'LINE_ITEMS' : {  
              pLineItemId            : ''
            , pLineItemName          : ''
            , pPlacementId           : ''
            , pAdUnitId              : ''
            , pDataInizio            : ''
            , pDataFine              : ''
            , pOrderName             : ''
            , pLineItemTypeList      : ''
            , pStatusList            : ''
            , pPacing                : ''
            , pPacingOper            : '='
            , pDeliveryEstim         : ''
            , pDelivOper             : '='
            , pPriorityList          : ''
            , pContendingLineItems   : ''
            , pAccountDesc           : ''
            , pOrderByClause         : ''
            // , pContendingCount       : ''
            // , pContendingOper        : '='
        }
       ,'AD_UNITS': {
              pAdUnitId              : ''
            , pAdUnitName            : ''
            , pPlacementId           : ''
            , pPlacementName         : ''
            , pLineItemId            : ''
            , pLineItemName          : ''
            , pSitoName              : ''
            , pFlagForecast          : ''
            , pFlagFoglia            : ''
            , pSizes                 : ''   
            , pAccountDesc           : ''
            , pOrderByClause         : ''
        }
       ,'PLACEMENTS': {
              pPlacementId           : ''
            , pPlacementName         : ''
            , pLineItemId            : ''
            , pLineItemName          : ''
            , pAdUnitId              : ''
            // , pAdUnitName            : ''
            , pAccountDesc           : ''
            , pOrderByClause         : ''
       }
    });
    const [ oNotifyOptions  ,set_oNotifyOptions ] = useState({ message: '', severity:'' })
         ,[ dLastUpdate     ,set_dLastUpdate    ] = useState('')
         ,[ sConcatNamePage ,setsConcatNamePage ] = useState('')
    ;
    const handleCloseAlert    = (event, reason) => {
        if (reason === 'clickaway') { return; }
        set_oNotifyOptions({ message: '' });
    };

    // useEffect(() => {
    //     console.log('oFilterParamsTotal: ', oFilterParamsTotal)
    // }, [ oFilterParamsTotal ]);

  return (
    <div className={"App " + env}>
      <NotificationContainer/>
        <AppContext.Provider value={{ oFilterParamsTotal, set_oFilterParamsTotal, oNotifyOptions, set_oNotifyOptions, selectedLogo, selectedLogoText, selectedLogoOnlyText, dLastUpdate ,set_dLastUpdate, sConcatNamePage ,setsConcatNamePage }}>
            
            <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />
            
            <AuthProvider>
                
                <AuthIsSignedIn>
                    <MainRoute/>
                </AuthIsSignedIn>
                
                <AuthIsNotSignedIn>
                    <AuthRoute/>
                </AuthIsNotSignedIn>
                
            </AuthProvider>
            
        </AppContext.Provider>
    </div>
  );
}

export default App;
