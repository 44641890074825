import React, { useState, useEffect, useContext }   from 'react'; 
import { Link }                                     from 'react-router-dom';
import axios                                        from 'axios';
import moment                                       from 'moment';
import 'moment/locale/it';

import './Allarmi.css';
import config                                       from '../../config.js';
import { AuthContext }                              from '../../contexts/authContext';
import { AppContext }                               from '../../App.js';
import { SimpleTable }                              from '../../components/SimpleTable/SimpleTable';

import CircleProgress                               from '../../components/CircleProgress/CircleProgress.tsx';
import utils                                        from '../../util/CommonUtilities';

import { DoubleArrowRightIcon
       , FolderOffIcon }           from '../../assets/icons/icons.js';
// import FolderOffIcon                                from '@mui/icons-material/FolderOff';
// import DoubleArrowRightIcon                 from '@mui/icons-material/KeyboardDoubleArrowRight';

moment.locale('it');

const Allarmi = (props) => {
    
    const [ loading,          setLoading           ] = useState(true);
    const [ aoAllarmi,        set_aoAllarmi        ] = useState([]);
    const [ aoAllarmiHeaders, set_aoAllarmiHeaders ] = useState([]);
    const auth = useContext(AuthContext);
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);

    useEffect(() => {
        setLoading(true);
        set_dLastUpdate('');
        set_oNotifyOptions({ message: '' });
        ( async () => {
            try {
                const response = await axios.get(
                    config.API_URL + '/get',
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  : { "getAlertsParams": true }
                    }
                )
                if ( response.status === 200 ) {
                    const result = response.data;
                    if ( result ) {
                        const 
                            aoRecords           = result.aoRecords.filter( oRecord => oRecord.FLAG_ENABLED === 'S' ) || [],
                            formatLinkDettaglio = ( val ) => <Link to={`/allarme/${val}`} className='link'> <DoubleArrowRightIcon className="text-uppercase" /></Link >,
                            formatNumElem       = ( val, oRow ) => <span className={`${ val > 0 ? ' textRed' : ' textGreen' }`} >{utils.formatNumberWithOptions(val)}</span>
                        ;

                        set_dLastUpdate( aoRecords[0].MAX_LAST_EXECUTION || '' );

                        set_aoAllarmiHeaders([
                             { name: 'DESC_ALERT'        ,title: 'Allarme'      ,width: 400 ,isUniqueKeyForRow: true }
                            ,{ name: 'NUM_ELEM'          ,title: ''             ,width: 80  ,format: formatNumElem   }
                            ,{ name: 'ID_ALERT'          ,title: ''             ,width: 80  ,format: formatLinkDettaglio ,notSortable: true }
                            // ,{ name: 'FLAG_ENABLED'      ,title: 'Abilitato'    ,width: 70  ,format: (val) => val === 'S' ? <CheckIcon className="enabled" /> : <DoDisturbIcon className="disabled" /> }
                            // ,{ name: 'LAST_EXECUTION'    ,title: 'Eseguito'     ,width: 310 ,format: ( val, oRow ) => oRow.FLAG_ENABLED !== 'S' ? null : val }
                        ]);
                        set_aoAllarmi(aoRecords);

                    }
                }      
            } catch(err) {
                console.error('ERR 100: Failed GetAllarmi: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
            }
            setLoading(false)
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[]);

    return (
        <div className="component-container allarmi">
            <div className="component-card">
                <div className="component-options-wrapper">
                {
                    loading ? <CircleProgress /> : <>{
                        aoAllarmi?.length && aoAllarmiHeaders?.length ? 
                            <SimpleTable
                            chiave            = { 'ALERTS' }
                            sTableDataType    = { 'ALERTS' }
                            aoRows            = { aoAllarmi }
                            aoCols            = { aoAllarmiHeaders }
                            oSortOptions      = { { isSortable: true } }
                            oExportOption     = { { sFileName: 'Allarmi' } }
                            noFilter          = { true }
                        />
                        : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                    }</>
                }
                </div>
            </div>  
        </div>
    )
};

export default Allarmi;
