import './index.css';
import React from 'react'
/*
    // Sintassi Riferimento CSS: i.icon::before

    // Dichiarazione per utilizzare props specifiche oltre alle classi CSS (Es ref per Tooltip)
    // export const AccountCircleIcon          = (props) => <i {...props} className = { `icon fa-regular fa-circle-user ${            props.addClass || '' }` } />;
*/
    
// export const EditIcon                   = ({ addClass }) => <i className={ `icon fa-solid fa-pen ${                          addClass || '' }` } />;
export const EditIcon    = React.forwardRef((props, ref) => <i {...props} ref={ref} className={ `icon fa-solid fa-pen         ${ props.addClass || '' }` } />);
     
export const InfoIcon    = React.forwardRef((props, ref) => <i {...props} ref={ref} className={ `icon fa-light fa-circle-info ${ props.addclass || '' }` } />);

export const AccountCircleIcon          = ({ addClass }) => <i className={ `icon fa-regular fa-circle-user ${                addClass || '' }` } />; 

export const AccountCircleSolidIcon     = ({ addClass }) => <i className={ `icon fa-solid fa-circle-user ${                  addClass || '' }` } />;
    
export const AddCircleOutlineIcon       = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-circle-plus ${       addClass || '' }` } />;

export const AdjustIcon                 = ({ addClass }) => <i className={ `icon fa-regular fa-sliders-up ${                 addClass || '' }` } />;

export const AddIcon                    = ({ addClass }) => <i className={ `icon fa-solid fa-plus-large${                    addClass || '' }` } />;

export const AlternateEmailIcon         = ({ addClass }) => <i className={ `icon fa-regular fa-at ${                         addClass || '' }` } />;

export const ArrowLeft                  = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-chevron-left ${        addClass || '' }` } />;

export const ArrowRight                 = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-chevron-right ${       addClass || '' }` } />;

export const BellIcon                   = ({ addClass }) => <i className={ `icon fa-regular fa-bell ${                       addClass || '' }` } />;

export const CabinetIcon                = ({ addClass }) => <i className={ `icon fa-solid fa-cabinet-filing ${               addClass || '' }` } />;

export const CachedIcon                 = ({ addClass }) => <i className={ `icon fa-regular fa-rotate ${                     addClass || '' }` } />;
    
export const CancelIcon                 = ({ addClass }) => <i className={ `icon fa-regular fa-xmark ${                      addClass || '' }` } />;

export const CancelPresentationIcon     = ({ addClass }) => <i className={ `icon fa-regular fa-rectangle-xmark ${            addClass || '' }` } />;
    
export const CheckIcon                  = ({ addClass }) => <i className={ `icon fa-regular fa-check ${                      addClass || '' }` } />;

export const CreditCardIcon             = ({ addClass }) => <i className={ `icon fa-regular fa-credit-card ${                addClass || '' }` } />;
    
export const DashBoardIcon              = ({ addClass }) => <i className={ `icon fa-regular fa-solid fa-gauge ${             addClass || '' }` } />;

export const DoDisturbIcon              = ({ addClass }) => <i className={ `icon fa-regular fa-ban ${                        addClass || '' }` } />;
    
export const DoNotDisturbOnOutlinedIcon = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-circle-minus ${      addClass || '' }` } />;

export const DoneAllIcon                = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-check-double ${        addClass || '' }` } />;
    
export const DoubleArrowLeftIcon        = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-angles-left ${       addClass || '' }` } />;
    
export const DoubleArrowRightIcon       = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-angles-right ${      addClass || '' }` } />;

export const DownLoadIcon               = ({ addClass }) => <i className={ `icon fa-regular fa-file-arrow-down ${            addClass || '' }` } />;
 
export const FolderOffIcon              = ({ addClass }) => <i className={ `icon fa-regular fa-folder-xmark ${               addClass || '' }` } />;

export const FilterIcon                 = ({ addClass }) => <i className={ `icon fa-regular fa-filter       ${               addClass || '' }` } />;
    
export const ForecastIcon               = ({ addClass }) => <i className={ `icon fa-solid fa-chart-mixed ${                  addClass || '' }` } />;
    
export const GearIcon                   = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-gear ${                addClass || '' }` } />;

export const GroupIcon                  = ({ addClass }) => <i className={ `icon fa-solid fa-users ${                        addClass || '' }` } />;
  
export const KeyIcon                    = ({ addClass }) => <i className={ `icon fa-regular fa-key-skeleton ${               addClass || '' }` } />;
    
export const LeaderboardIcon            = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-chart-simple ${        addClass || '' }` } />;
    
export const LogoutIcon                 = ({ addClass }) => <i className={ `icon fa-solid fa-right-from-bracket ${           addClass || '' }` } />;

export const MultipleIcon               = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-rectangle-history ${ addClass || '' }` } />;

export const NotificationImportantIcon  = ({ addClass }) => <i className={ `icon fa-solid fa-bell-exclamation ${             addClass || '' }` } />;

export const PinIcon                    = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-input-numeric ${     addClass || '' }` } />;
    
export const SaveOutlinedIcon           = ({ addClass }) => <i className={ `icon fa-regular fa-floppy-disk ${                addClass || '' }` } />;
    
export const SearchIcon                 = ({ addClass }) => <i className={ `icon fa-sharp fa-regular fa-magnifying-glass ${  addClass || '' }` } />;
    
export const SsidChartIcon              = ({ addClass }) => <i className={ `icon fa-regular fa-chart-line-up ${              addClass || '' }` } />;
    
export const SupervisorAccountIcon      = ({ addClass }) => <i className={ `icon fa-regular fa-crown ${                      addClass || '' }` } />;

export const NotSupervisorAccountIcon   = ({ addClass }) => <i className={ `icon fa-kit fa-regular-crown-slash ${            addClass || '' }` } />;
    
export const TableChartIcon             = ({ addClass }) => <i className={ `icon fa-regular fa-table-columns ${              addClass || '' }` } />;
    
export const ThreeVerticalDot           = ({ addClass }) => <i className={ `icon fa-regular fa-ellipsis-vertical ${          addClass || '' }` } />;
    
export const TrendingDownIcon           = ({ addClass }) => <i className={ `icon fa-solid fa-arrow-trend-down ${             addClass || '' }` } />;
    
export const TrendingUpIcon             = ({ addClass }) => <i className={ `icon fa-solid fa-arrow-trend-up ${               addClass || '' }` } />;

export const UploadIcon                 = ({ addClass }) => <i className={ `icon fa-sharp fa-solid fa-up-from-line ${        addClass || '' }` } />;
    
export const UserIcon                   = ({ addClass }) => <i className={ `icon fa-solid fa-user ${                         addClass || '' }` } />;
    
export const VisibilityIcon             = ({ addClass }) => <i className={ `icon fa-regular fa-eye ${                        addClass || '' }` } />;
    
export const VisibilityOffIcon          = ({ addClass }) => <i className={ `icon fa-regular fa-eye-slash ${                  addClass || '' }` } />;

// export const TryIcon                    = ({ addClass }) => <span class="fa-stack fa-2x">
//                                                                 <i class={ `icon fa-regular fa-crown fa-stack-2x ${           addClass || '' }` } />
//                                                                 <i class={ `icon fa-regular fa-slash fa-stack-1x fa-inverse ${addClass || '' }` } />
//                                                             </span>;
// export const StringCursorIcon           = ({ addClass }) => <i className = { `icon fa-regular fa-i-cursor ${                   addClass || '' }` } />;