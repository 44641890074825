import React, { useState, useEffect, useContext }   from 'react';
import {useNavigate}                                from 'react-router-dom';
import config                                       from '../../config.js';
import axios                                        from 'axios';
import moment                                       from 'moment';
import Box                                          from '@mui/material/Box';
import TabContext                                   from '@mui/lab/TabContext';
import TabPanel                                     from '@mui/lab/TabPanel';
import Select                                       from '@mui/material/Select';
import MenuItem                                     from '@mui/material/MenuItem';
import Autocomplete                                 from '@mui/material/Autocomplete';
import Tooltip                                      from '@mui/material/Tooltip';
import IconButton                                   from '@mui/material/IconButton';
import Button                                       from '@mui/material/Button';

import './Users.css'
import { AppContext }                               from '../../App.js';
import { AuthContext }                              from '../../contexts/authContext';
import { SimpleTable }                              from '../../components/SimpleTable/SimpleTable';
import { PopupConfirm }                             from '../../components/PopupConfirm/PopupConfirm'
import CircleProgress                               from '../../components/CircleProgress/CircleProgress.tsx';
import utils                                        from '../../util/CommonUtilities';
import UserEditing                                  from'./UserEditing.js'
import { CustomCssTextField
       , CustomCssInput
       , CustomCssTab
       , CustomCssTabList }                         from '../../components/CustomCSS/CustomCSS.tsx';

import { AddCircleOutlineIcon
       , CheckIcon
       , DoDisturbIcon
       , DoNotDisturbOnOutlinedIcon
       , FolderOffIcon
       , SupervisorAccountIcon 
       , NotSupervisorAccountIcon
       , UserIcon              }                    from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;
const asUtentiDaNascondere = [ 'l.pizzurro@systrategy.it', 'd.miccinelli@systrategy.it', 'm.lanzi@systrategy.it'];
/*
    NON BISOGNA PAGINARE GLI UTENTI !!!
*/

const Users = () => {

    const bAdminUser  = ( localStorage.getItem('flagAdmin') || '' ) === 'Y';
    const isProd = process.env.REACT_APP_ENV.includes('prod');
    const auth        = useContext(AuthContext);
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const formatDate      = (val) =>       utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', useTimezone: true });
    const formatExecution = (val) =>       utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', fromNow: true , useTimezone: true });
    const formatLogin     = (val) => val ? utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', fromNow: true , useTimezone: true }) : 'Utente mai connesso';
    const formatDisable   = (val) => val ? utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', fromNow: true , useTimezone: true }) : '';

    const navigate = useNavigate();

    const
          [ loading        ,setLoading         ] = useState(true)
         ,[ onReload       ,set_onReload       ] = useState(null) // State per triggerare il lancio della chiamata
         ,[ nValueTab,      set_nValueTab      ] = useState('1')

         ,[ aoUsers,        set_aoUsers        ] = useState([])
         ,[ aoUsersHeaders, set_aoUsersHeaders ] = useState([])

         ,[ KUSERselected,  set_KUSERselected  ] = useState('')  // State per contenere il KUser dell'utente selezionato dall'elenco <Select>
         ,[ KUSERFocused,   set_KUSERFocused   ] = useState('')  // State per contenere il KUser dell'urente OnFocus, sul quale sto operando
         ,[ KUSERParent,    set_KUSERParent    ] = useState('')  // State per contenere il KUser dell'utente Genitore
         ,[ KUSERChild,     set_KUSERChild     ] = useState('')  // State per contenere il KUser dell'Utente figlio

         ,[ sPopupType,     set_sPopupType     ] = useState('')  // State per condizionare il tipo di popup da aprire in gerarchia
         ,[ sButtonState,   set_sButtonState   ] = useState('')  // State per condizionare il comportamento del programma in base a quale bottone ho premuto
         ,[ userToSearch,   set_userToSearch   ] = useState(null)  // State per contenere l'utente da cercare in gerarchia
         ,[ userToSearchInputValue,   set_userToSearchInputValue   ] = useState('')  // State per contenere l'utente da cercare in gerarchia
         ,[ bShowDialog     ,set_bShowDialog     ] = useState(false) // State per condizionare l'apertura della finestra Modifica Utente
         ,[ oDialogOptions  ,set_oDialogOptions  ] = useState({}) // { sTitle: '', content: null, sConfirmButton: '' } // State per fornire il contenuto alla Mod Utente
    ;

    const reload = () => { setLoading(true); set_onReload( !onReload ); };

    const goToUserCreation = () => {
        navigate('/crea-utente');
    };

    const handleChangeTab = (event, newValue) => {
        set_nValueTab(newValue);
    };
    
    const toggleNotifica = ( message, severity, timeout ) => {
        setLoading(false);
        set_bShowDialog(false);
        set_oNotifyOptions({ message, severity,...( timeout && { timeout }) });
    };

    useEffect(() => {
        if ( !bAdminUser ) {
            navigate('/errorPage403');
        }
        set_dLastUpdate('');
        setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* #region Gerarchia */

        let aoRigaPrec = [];

        const handleChangeKUser = (event) => {
            let KUSER = event.target.value;
            set_KUSERselected(KUSER);

            if (sButtonState === 'first') {
                set_KUSERParent(KUSER)
            } else if (sButtonState === 'last') {
                set_KUSERChild(KUSER)
            }
        };

        const openPopupSelectUser = ({ KUSER, sButton }) => {
            bDebug && console.log('openPopupSelectUser: ', { KUSER, sButton });

            set_KUSERFocused(KUSER);
            if (sButton === 'first') {
                set_KUSERChild(KUSER)
                set_sButtonState('first')
            } else if (sButton === 'last') {
                set_KUSERParent(KUSER)
                set_sButtonState('last')
            }
            set_KUSERselected('')
            set_sPopupType('SelectUser');
        };

        const clearUserParams = () => {
            set_KUSERChild('');
            set_KUSERParent('');
            set_KUSERFocused('');
            set_KUSERselected('');
            set_sButtonState('');
            set_sPopupType('');
            reload();
        };

        const addUserInGerarchia = async () => {
            bDebug && console.log('KUSERParent', KUSERParent );
            bDebug && console.log('KUSERParenttype', typeof KUSERParent );
            bDebug && console.log('KUSERChild', KUSERChild );
            bDebug && console.log('sButtonState: ', sButtonState);
            set_oNotifyOptions(null);

            if (KUSERParent != null && KUSERChild ) {
                // Creo nuova persona inserendo il KUSER nel suo PARENT_KUSER
                setLoading(true);

                try {
                    let params =  { modUserGerarchia: true, pUserId: KUSERChild, pParentUserId: KUSERParent };
                    bDebug && console.log('params: ', params);

                    const response = await axios.get( config.API_URL + '/get', { headers, params } );

                    if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                        // set_sModifyTarget(''); // non posso settare qui a '' perchè altrimenti non entro nell'handlescroll dell'useEffectOnReload
                    } else {
                        console.error(`Errore ${ response?.data?.vErrorMessageDesc }`);
                        set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                        setLoading(false);
                    }

                } catch(err) {
                    console.error('ERR 131: Failed addUserInGerarchia', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
                    setLoading(false);
                }
            }
            clearUserParams();
        };

        const oPopupOptionsModify = {
            DialogTitle:       `Seleziona elemento ${ sButtonState === 'first' ? 'superiore' : 'inferiore' }`
            ,DialogContentText: `di ${( aoUsers.find( oUser => oUser.KUSER === KUSERFocused ) || {} ).FULL_NAME || 'Utente non riconosciuto' }`
            ,Content:           <Select
                                    id="demo-simple-select"
                                    value={KUSERselected}
                                    label=""
                                    onChange={ handleChangeKUser }
                                    input = { <CustomCssInput /> }
                                >
                                    {
                                        aoUsers //Necessario filtrare tutti i padri dei padri nel bottone last
                                            .filter(oUser => oUser.KUSER !== KUSERFocused && (
                                                sButtonState === 'last'
                                                    ? oUser.PARENT_KUSER !== KUSERFocused
                                                    : true
                                            ))
                                            .map( oUser => <MenuItem key={oUser.KUSER} value={oUser.KUSER}>{oUser.FULL_NAME}</MenuItem> )
                                    }
                                </Select>
            ,onClick:               addUserInGerarchia
            ,onCancel:              clearUserParams
            ,firstButtonLabel:       'inserisci'
            ,classFirstButton:       'popupConfirm'
            ,classCloseButton:       'popupAnnul'
        };
        
        const foPopupOptionsRemove = () => {
            let oUserattuale = aoUsers.find( o => o.KUSER === KUSERChild );
            let oUserParent = aoUsers.find( o => o.KUSER === oUserattuale.PARENT_KUSER);

            return {
                DialogTitle:        `Confermi di voler rimuovere ${ oUserattuale.FULL_NAME }`
                ,DialogContentText: ` da ${ oUserParent.FULL_NAME }`
                ,onClick:               addUserInGerarchia
                ,onCancel:              clearUserParams
                ,firstButtonLabel:     'rimuovi'
                ,classFirstButton:     'popupRemove'
                ,classCloseButton:     'popupAnnul'
            }
        };

        const removeUserFromTreeGerArk = ({ KUSER }) => {
            set_KUSERChild(KUSER);
            set_KUSERParent('');
            set_sButtonState('remove');
            set_sPopupType('removeUser');
        };

        const sortingRow = ( aRow1, aRow2 ) => {
            let result = 0
            if ( aRow1 && aRow2 ) {
                for (let i = 0; i < aRow1.length; i++) {
                    bDebug && console.log(aRow1[i].PARENT_KUSER);
                    bDebug && console.log((aRow2[i] || {}).PARENT_KUSER || 0);

                    let fullName1 = ((aRow1[i] || {}).FULL_NAME || '') + '';
                    let fullName2 = ((aRow2[i] || {}).FULL_NAME || '') + '';

                    if ( fullName1 < fullName2 ) {
                        return -1;
                    } else if ( fullName1 > fullName2 ) {
                        return 1;
                    } else {
                        let username1 = ((aRow1[i] || {}).USERNAME || '') + '';
                        let username2 = ((aRow2[i] || {}).USERNAME || '') + '';

                        if (username1 < username2 ) {
                            return -1;
                        } else if ( username1 > username2 ) {
                            return 1;
                        } else {
                            result = 0;
                        }
                    }
                }
                return result;
            } else {
                return 0;
            }
        };

        const addRowSpan = ( aoRow, nRow, aaoRow ) => {

            const aoRowPrec = aaoRow[nRow-1];
            
            for ( let i = 0; i < aoRow.length; i++ ) {
                const oCellRigaAttuale = aoRow[i];
                const oCellRigaPrec    = ( aoRowPrec || [] )[i] || {};
                const sCellRigaAttualeUSERNAME = oCellRigaAttuale.USERNAME || ''
                const sCellRigaPrecUSERNAME    =    oCellRigaPrec.USERNAME || ''

                if ( sCellRigaAttualeUSERNAME === sCellRigaPrecUSERNAME ) {
                    oCellRigaAttuale.nRowSpan = ( oCellRigaPrec.nRowSpan || 0 ) + 1;
                } else {
                    oCellRigaAttuale.nRowSpan = 1;
                }
            }

            return aoRow;
        };

        const creaCella = ( oCella, nCella, aoCelle ) => {
            bDebug && console.log(`CreaCella ${ oCella.FULL_NAME }`);

            const isToRender = aoCelle[nCella] !== aoRigaPrec[nCella];
            if ( nCella === ( aoCelle.length - 1 ) ) {
                aoRigaPrec = aoCelle;
            }

            return ( oCella && isToRender && <td
                    key={ `${oCella.KUSER} ${oCella.FULL_NAME} cell` }
                    className={ `${( oCella.isLeaf ? 'isLeaf ' : '' )} ${( 'nRowSpan' + ( oCella.nRowSpan || '' ) )} ${( oCella.isRadix ? ' isRadix' : '' )} ${ (userToSearch?.USERNAME || '' ) === oCella.USERNAME ? 'found' : '' } ` }
                    rowSpan={( oCella.nRowSpan || '' ) }
                >
                <div className="wrapperCell">
                    
                    <div key={`StartButton`} className='cellUserButton' >
                        <Tooltip title='Modifica elemento superiore' placement='top' arrow>
                            <IconButton className='addUser' onClick={ () => openPopupSelectUser({ KUSER: oCella.KUSER, sButton: 'first' }) } >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Rimuovi elemento superiore e rendi radice della gerarchia' placement='bottom' arrow>
                            <IconButton className="removeUser" onClick={ () => removeUserFromTreeGerArk({ KUSER: oCella.KUSER }) }>
                                <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div className="cellUser">
                        {
                            oCella.FLAG_ATTIVO === 'Y' ?
                                oCella.FLAG_ADMIN === 'Y' ?
                                    <SupervisorAccountIcon addClass={"textGrey"} />
                                  : <UserIcon              addClass={"textGrey"} />
                              : <DoDisturbIcon addClass={"textRed activeFlag"}/>
                        }
                        <span id={`${ oCella.USERNAME }`}>
                            <Tooltip title={`${ oCella.FULL_NAME }`} placement='top-start'>
                                <div>{ oCella.FULL_NAME }</div>
                            </Tooltip>
                            <Tooltip title={`${ oCella.USERNAME }`} placement='bottom-start'>
                                <div>{ oCella.USERNAME }</div>
                            </Tooltip>
                        </span>
                    </div>

                    <div key={ `${oCella.KUSER} ${oCella.FULL_NAME} Button` } className="cellUserButton">
                        <Tooltip title='Aggiungi elemento inferiore' placement='top' arrow>
                            <IconButton className='addUser' onClick={ () => openPopupSelectUser({ KUSER: oCella.KUSER, sButton: 'last' }) }>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>
            </td>);
        };

        const creaRigaRicorsivamente = ( oRow ) => {
            try {
                if ( !oRow.PARENT_KUSER ) {

                    oRow.isLeaf = !aoUsers.some( o => o.PARENT_KUSER === oRow.KUSER );
                    oRow.isRadix = true;
                    return [ oRow ];
                } else {

                    let oRowParent = null;
                    let isLeaf = false;
                    aoUsers.forEach( oActualRow => {
                        if ( oRow.PARENT_KUSER === oActualRow.KUSER ) {
                            oRowParent = oActualRow;
                        }
                        isLeaf       = !aoUsers.some( o => o.PARENT_KUSER === oRow.KUSER );

                        oRow.isLeaf = isLeaf;
                    });
                    return ( oRowParent && [ ...creaRigaRicorsivamente( oRowParent ), oRow ] );
                }
            } catch(e) {
                console.error(e);
                return [];
            }
        };

        const creaRiga = ( aRow ) => {
            const rigaWithLeaf = aRow && aRow.find( o => o.isLeaf );
            return ( rigaWithLeaf?.KUSER ? aRow : null )
        };

        const creaRigaHTML = ( aoCelle ) => {
            return (
                <tr key={`${aoCelle[ aoCelle.length -1 ].KUSER} Row`} >
                    { aoCelle.map(creaCella) }
                </tr>
            )
        };

        const gerarchiaTable = () => {
            bDebug && console.log(aoUsers.map( creaRigaRicorsivamente )
            .sort( sortingRow )
            .map( creaRiga )
            .filter( Boolean ));
            return (
                <table className="gerarchiaTable">
                    <tbody>
                        {
                            aoUsers
                                .map( creaRigaRicorsivamente )
                                .sort( sortingRow )
                                .map( creaRiga )
                                .filter( Boolean )
                                .map( addRowSpan )
                                .map( creaRigaHTML )
                        }
                    </tbody>
                </table>
            )
        };

        const searchUserGerarchia = () => {
            bDebug && console.log(userToSearch);
            let utenteTrovato = userToSearch;

            if ( utenteTrovato ) {
                document.getElementById(`${ utenteTrovato.USERNAME }`).scrollIntoView({ behavior: "auto", block: "center" });
            } else {
                set_oNotifyOptions({ message: `Utente non trovato`, severity:'warning', timeout: 3 })
            }
        };
        
        useEffect(() => {
            if ( sPopupType === '' ) {
                set_sButtonState('');
            }
        },[ sPopupType ]);

        useEffect(() => {
            if ( userToSearch ) {
                bDebug && console.log('Dentro useEffect userToSearch');
                searchUserGerarchia();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ userToSearch ]);

    /* #endregion */

    /* #region ModUtente */
        // const oFormatRole        = { 'Y': 'Admin', 'N': 'User', 'Admin': 'Y', 'User': 'N' };
        // const oFormatVerified    = { 'Y': 'Email verificata', 'N': 'Email non verificata' };
        const oMapLabelAndValues = {
             'USERNAME'             : { label: 'Username'                ,enabled: false }
            ,'FULL_NAME'            : { label: 'Nome'                    ,enabled: true  }
            ,'FLAG_ADMIN'           : { label: 'Role'                    ,enabled: true  } /* ,format: val => oFormatRole[val]  */
            ,'AGENTE_COD'           : { label: 'Codice Agente'           ,enabled: true  ,format: val => val ? val.replace(/[^a-zA-Z0-9]/g, '') : '' }
            // ,'FLAG_COGNITO'         : { label: 'Cognito'                 ,enabled: false ,format: val => oFormatVerified[val]}
            ,'FLAG_ATTIVO'          : { label: 'Active'                  ,enabled: true  }
            ,'DATA_INSERIMENTO'     : { label: 'Data Creazione'          ,enabled: false ,format: val => formatExecution(val) }
            ,'DATA_ULTIMA_MODIFICA' : { label: 'Data Ultima Modifica'    ,enabled: false ,format: val => formatExecution(val) }
            ,'DATA_ULTIMA_LOGIN'    : { label: 'Data Ultima Connessione' ,enabled: false ,format: val => formatLogin(val)     }
            ,'DATA_CANCELLAZIONE'   : { label: 'Data Disabilitazione'    ,enabled: false ,format: val => formatDisable(val)   }/* FLAG_ATTIVO scatena il cambio di stato di DATA_CANCELLAZIONE */
            // ,'NOME'                 : { label: 'Name'                    ,enabled: true  }
            // ,'EMAIL_ADDRESS'        : { label: 'Email'                   ,enabled: true  }
        };

        const onClickEdit     = ( oRow ) => {
            set_oDialogOptions({ oRow });
            set_bShowDialog(true);
        };
    /* #endregion ModUtente */

    useEffect(() => {
        /*
            NON BISOGNA PAGINARE GLI UTENTI !!!
        */
        setLoading(true);
        // set_oNotifyOptions(null);
        
        bAdminUser && ( async () => {
            try {
                const response = await axios.get( config.API_URL + '/get', { headers, params : { "getUsersParams": true } } );
                if ( response.status === 200 ) {
                    const result = response.data;
                    // console.log(JSON.stringify(result));
                    set_dLastUpdate( result.aoRecords[0].MAX_LAST_EXECUTION || '' );
                    
                    if ( result ) {
                        let aoRecords = result.aoRecords || [];
                        /*  console.log(aoRecords);
                            // Moltiplichiamo i record per allungare la tabella
                            let temp = [];
                            for (let i = 0; i <= aoRecords.length; i++) {
                                // console.log('temp[i]: ',temp[i].USERNAME);
                                temp[i] = { 'USERNAME': i};
                            }
                            aoRecords = aoRecords.concat(temp);
                        */
                        isProd && ( aoRecords = result.aoRecords.filter( record => !asUtentiDaNascondere.includes(record.USERNAME) ) || []);
                        const iconAdminYes  = <div className='iconWrap'><SupervisorAccountIcon addClass={"textGrey"}/></div>;
                        const iconAdminNo   = <div className='iconWrap'><NotSupervisorAccountIcon addClass={"textGrey"}/></div>;
                        const iconAttivo    = <div className='iconWrap'><CheckIcon addClass={"textGreen"}/></div>;
                        const iconDisattivo = <div className='iconWrap'><DoDisturbIcon addClass={"textRed"}/></div>;
                        // const formatDate    = (val) => !val ? null : moment(val, 'YYYYMMDDHHmmss').format('DD MMM YYYY HH:mm');
                        set_aoUsersHeaders([
                             { name: 'FULL_NAME'                ,title: 'Nome'                  ,width: 190     ,tooltip: true }
                            ,{ name: 'USERNAME'                 ,title: 'Utente'                ,width: 375     ,tooltip: true        ,isUniqueKeyForRow: true }
                            ,{ name: 'FLAG_ADMIN'               ,title: 'Admin'                 ,width: 60      ,filterOriginalValue: true ,format: (val) => val === 'Y' ? <div className='iconWrap'><SupervisorAccountIcon addClass={"textGrey"}/></div> : null
                                ,selectOptions: [
                                     { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                                    ,{ label: iconAdminYes  ,value: 'Y' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                    ,{ label: iconAdminNo   ,value: 'N' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                ]
                            }
                            ,{ name: 'AGENTE_COD'               ,title: 'Codice Agente'         ,width: 95   }
                            // ,{ name: 'FLAG_COGNITO'             ,title: 'Cognito'            ,width: 60      ,format: (val) => val === 'Y' ? iconAttivo : iconDisattivo
                            //     ,selectOptions: [
                            //          { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                            //         ,{ label: iconAttivo    ,value: 'Y' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                            //         ,{ label: iconDisattivo ,value: 'N' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                            //     ]
                            // }
                            ,{ name: 'FLAG_ATTIVO'              ,title: 'Attivo'                ,width: 60      ,format: (val) => val === 'Y' ? iconAttivo : iconDisattivo
                                ,selectOptions: [
                                     { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                                    ,{ label: iconAttivo    ,value: 'Y' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                    ,{ label: iconDisattivo ,value: 'N' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                ]
                            }
                            ,{ name: 'DATA_INSERIMENTO'         ,title: 'Data Creazione'        ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_ULTIMA_MODIFICA'     ,title: 'Ultima Modifica'       ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_ULTIMA_LOGIN'        ,title: 'Ultima Connessione'    ,width: 135     ,format: formatDate }
                            ,{ name: 'DATA_CANCELLAZIONE'       ,title: 'Data Cancellazione'    ,width: 135     ,format: formatDate } /* FLAG_ATTIVO scatena il cambio di stato di DATA_CANCELLAZIONE */
                        ]);
                        set_aoUsers(aoRecords);
                    }
                }
            } catch(err) {
                console.error('ERR 130: Failed GetUsers: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
            setLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);


    return (
        <div className="component-container users">
            <div className="component-card">
                { loading ? <CircleProgress /> :
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={nValueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomCssTabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <CustomCssTab label="Elenco"    value="1" />
                   { !isProd && <CustomCssTab label="Gerarchia" value="2" /> }
                            </CustomCssTabList>
                        </Box>
                        <TabPanel value="1" className="tabPanel elenco">
                            { /* ----- popup di modifica dati utente ----- */ }
                            { !bShowDialog ? null : <UserEditing
                                options ={{ bShowDialog, set_bShowDialog, oDialogOptions, loading, setLoading,
                                    sTitle: 'Profilo Utente', oMapLabelAndValues, reload, toggleNotifica }}
                            /> }
                            <Button className="add-user-btn" variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={goToUserCreation}>
                                Crea Nuovo Utente
                            </Button>
                            <div className="component-options-wrapper">
                            {
                                loading ? <></> : <>{
                                    aoUsers?.length && aoUsersHeaders?.length ? 
                                        <SimpleTable
                                            chiave            ={ 'USERS' }
                                            sTableDataType    ={ 'USERS' }
                                            aoRows            ={ aoUsers }
                                            aoCols            ={ aoUsersHeaders }
                                            isEditable        ={ true }
                                            editFunction      ={ onClickEdit }
                                            oSortOptions      ={ { isSortable: true } }
                                            oExportOption     ={ { sFileName: `Utenti` } }
                                        />
                                    : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                                }</>
                            }
                            </div>
                        </TabPanel>
                        <TabPanel value="2" className="tabPanel gerarchia">
                            {
                               ( sPopupType !== '' )
                                    ? <PopupConfirm { ...{ oPopupOptions: (sPopupType === 'removeUser') ? foPopupOptionsRemove() : oPopupOptionsModify, sPopupType, set_sPopupType } } />
                                    : null
                            }
                            <div className="searchFieldWrapper">
                                <Autocomplete
                                    autoComplete
                                    freeSolo
                                    clearOnBlur
                                    id="combo-box-usersGerarchia"
                                    options={aoUsers}
                                    getOptionLabel={( option => option.FULL_NAME + ' (' + option.USERNAME + ')' )} // avendo nomi duplicati vi 
                                    value={ userToSearch }
                                    onChange={ (event, newValue) => set_userToSearch(newValue) }
                                    inputValue={ userToSearchInputValue }
                                    onInputChange={ (event, newInputValue) => set_userToSearchInputValue(newInputValue)}
                                    sx={{ width: 700 }}
                                    renderInput={(params) => <CustomCssTextField {...params} label="Cerca" />}
                                />
                            </div>
                            <div className="gerarchiaTableWrapper">{gerarchiaTable()}</div>
                        </TabPanel>
                    </TabContext>
                </Box>
                }
            </div>
        </div>
    )
};

export default Users;
