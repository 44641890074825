import React, { useState, useEffect }    from 'react';
import { ResponsiveLine }                from '@nivo/line';
import { ResponsiveBar  }                from '@nivo/bar';
import { ResponsivePie  }                from '@nivo/pie';
import utils                             from '../../util/CommonUtilities';
import moment                            from 'moment/moment';

export default function Graph({ oGraphContent = {} , className }) {
    
    const [ data        , set_data      ] = useState([]);
    const [ keys        , set_keys      ] = useState([]);
    const [ indexBy     , set_indexBy   ] = useState('');
    const [ sChartType  , set_sChartType ] = useState('');
    
    let aoRows     = (oGraphContent.aoRows    || []).slice(0,100); // !!! TODO da Fixare
    let asElementX = oGraphContent.asElementX || []; // es. [ 'DATA_RIF' ]
    let asElementY = oGraphContent.asElementY || []; // es. [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ]
    const oMappingField = oGraphContent.oMappingField || [];
    

    const
         formatNum0dec  = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 0 })
        ,formatDate     = v => oGraphContent.formatDate ? oGraphContent.formatDate(v) : ( v === moment().format('YYYYMMDD') ) ? 'OGGI' : `${utils.formatDateTime(v, {
             input       : 'YYYYMMDD'
            ,output      : 'ddd D MMM YYYY'
        })}`
        ,mappingAO = aoValues => aoValues.map( oRow => Object.keys(oRow).reduce( ( o, sKey ) => ({ ...o, [ oMappingField[sKey] || sKey ]: oRow[sKey] }) ,{}) )
        ,mappingAS = asElements => asElements.map( sElement => oMappingField[sElement] || sElement )
    ;
    
    aoRows = mappingAO(aoRows);
    asElementX = mappingAS(asElementX);
    asElementY = mappingAS(asElementY);
    
    // console.log('oGraphContent: ', oGraphContent);
    
    useEffect( () => {
        set_sChartType(oGraphContent.sChartType);
    }, [oGraphContent]);
    
    useEffect(() => {
        (async function () {
            /*  dati fittizi
                data.rows = [
                    {"2018":422958,"2019":395700,"2020":177450,"2021":70287,"channelDesc":"MARKETING"},
                    {"2018":274280,"2019":332645,"2020":174237,"2021":92410,"channelDesc":"AKTION"},
                    {"2018":4888,"2019":228,"channelDesc":"ALLSQY"},
                    {"2019":11255,"2020":10535,"2021":3846,"channelDesc":"KOOMB"},
                    {"2020":310,"2021":790,"channelDesc":"RECQ"}
                ];
            */
            /*
                aoRows: aoAllarmeDettaglio, sElementX: 'DATA_RIF', asElementY: [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ]
            */
            if ( sChartType === 'line' ) {
                // console.log('sChartType: ',sChartType);
                /* risultato finale:
                    [
                        {
                            "id": "japan",
                            "color": "hsl(34, 70%, 50%)",
                            "data": [
                                { "x": "plane",         "y": 91 },
                                { "x": "helicopter",    "y": 85 },
                            ]
                        }
                    ]
                */
                const sElementX  = asElementX[0];
    
                const risultatofinale = asElementY.map(
                    sElementY => ({
                         id: sElementY
                         // ,color: 'red'
                        ,data: aoRows.map( oRow => ({ x: oRow[sElementX] ,y: oRow[sElementY] }) )
                    })
                )
    
                // console.log(risultatofinale);
                set_data(   risultatofinale || []);
                
            } else if ( sChartType === 'bar' ) {
                const nameField = oMappingField[asElementX[0]] || 'DATA_RIF';

                for( let i = 0; i < aoRows.length; i++ ) {
                    aoRows[i][nameField] = formatDate(aoRows[i][nameField])
                }
                const sElementX  = asElementX[0];
                set_indexBy(sElementX);
                set_data( aoRows || []);
                set_keys(asElementY)
                
                // oGraphContent={{ keys: ['BACINO', 'BACINO_MM_SLOW'], rows: aoAllarmeDettaglio, indexBy: 'DATA_RIF' }}
                /*
                const aoRows = oGraphContent.aoRows || [];
                const asKeys = oGraphContent.aoRows || [];
                const aoRows = oGraphContent.aoRows || [];
                
                set_keys(   ['BACINO', 'BACINO_MM_SLOW']);
                set_data(   []);
                set_indexBy(oGraphContent.indexBy);
                */
                
            } else if ( sChartType === 'pie' ) {
            
            }
            
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sChartType]);
    
    return (
        (data != null && data.length > 0) && (
            (sChartType === 'bar') ? (
            <div className={ className }>
                <ResponsiveBar
                    data            = {data}
                    keys            = {keys}
                    indexBy         = {indexBy}
                    margin          = {{top: 40, right: 45, bottom: 80, left: 75}}
                    padding         = {0.35}
                    innerPadding    = {2}
                    groupMode       = "grouped"
                    valueScale      = {{ type:   'linear' }}
                    indexScale      = {{ type:   'band'   , round: true }}
                    colors          = {{ scheme: 'nivo'   }}
                    borderWidth     = {0.5}
                    borderColor     = {{ from:   'color'  , modifiers: [ [ 'darker', 0.5 ] ] }}
                    axisTop         = {null}
                    axisRight       = {null}
                    axisBottom      = {{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : -45
                        ,format : formatDate // Non formattiamo qui perchè altrimenti il tooltip mostra la data non formattata

                    }}
                    axisLeft    ={{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : 0
                        ,format          : formatNum0dec
                    }}
                    labelSkipWidth  = {12}
                    labelSkipHeight = {12}
                    labelTextColor  = {{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                             dataFrom        : 'keys'
                            ,anchor          : 'top-right'
                            ,direction       : 'column'
                            ,justify         : false
                            ,translateX      : -20
                            ,translateY      : 5
                            ,itemsSpacing    : 0
                            ,itemWidth       : 105
                            ,itemHeight      : 20
                            ,itemDirection   : 'left-to-right'
                            ,itemOpacity     : 1
                            ,symbolSize      : 10
                            ,itemBackground  : 'var(--color-ghost)'
                        }
                    ]}
                    animate         ={false}
                    motionStiffness ={90}
                    motionDamping   ={15}
                    enableLabel     ={false}
                    tooltipFormat   ={null}
                    // Pattern Grafici: defs per definire il pattern e fill per associare il pattern agli elementi da modificare, è possibile associarli sia attraverso id che attraverso una funzione
                    // defs={[
                    //     { id: 'squares', type: 'patternSquares', size: 2 },
                    //     { id: 'lines', type: 'patternLines', background: 'inherit', color: 'white', rotation: -45, lineWidth: 3, spacing: 9 },
                    //     { id: 'dots', type: 'patternDots', background: 'inherit', color: 'black', size: 2, padding: 1, stagger: true },
                    // ]}
                    // // 2. defining rules to apply those patterns
                    // fill={[
                    //     // match using query object
                    //     // { match: { id: 'Bacino Media Veloce' }, id: 'dots' },

                    //     // match using function
                    //     { match: d => { console.log(aoRows.length);  return ( ~~(d.index / aoRows.length) + 1 ) % 2 }, id: 'lines' },
                    //     // { match: d =>  d.index <= 56 , id: 'lines' },
                    // ]}
                />
            </div>  ) :
            (sChartType === 'line') ? (
            <div className={ className }>
                <ResponsiveLine
                    data   ={data}
                    margin ={{top: 40, right: 45, bottom: 80, left: 75}}
                    enableGridX={false}
                    animate={false}
                    enableSlices={'x'}
                    xScale ={{type: 'point'}}
                    xFormat={formatDate}
                    yScale ={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}
                    yFormat={formatNum0dec}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        format : formatDate
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        format : formatNum0dec
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    
                    legends={[
                        {
                            anchor            : 'top-right',
                            direction         : 'column',
                            justify           : false,
                            translateX        : -20,
                            translateY        : 5,
                            itemsSpacing      : 0,
                            itemDirection     : 'left-to-right',
                            itemWidth         : 105,
                            itemHeight        : 20,
                            itemOpacity       : 1,
                            symbolSize        : 12,
                            symbolShape       : 'circle',
                            symbolBorderColor : 'rgba(0, 0, 0, .5)',
                            itemBackground    : 'var(--color-ghost)'
                        }
                    ]}
                />
            </div> )
            : (sChartType === 'pie') ? (
                <div className={ className }></div>
            ) : <></>
        )
    );
}
