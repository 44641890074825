import React, {useContext, useState, useEffect}  from 'react';
import { Link }                                  from 'react-router-dom';
import Button                                    from '@mui/material/Button';

import { AppContext }                            from '../../App.js';
import './ErrorPage.css'

const ErrorPage403 = (props) => {
    const home = (process.env.REACT_APP_ENV === 'localsole') || ( process.env.REACT_APP_ENV === 'prodsole' ) ? 'allarmi' : 'dashboard';
    const { dLastUpdate , set_dLastUpdate } = useContext(AppContext);

    useEffect(() => {
        set_dLastUpdate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
     
    return (
        <div className="component-container errorPage">
            <div className="component-card flexy">
                <div className="app-login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="card-wrapper">
                        <div className='card-wrapper-content'> 
                            <h1 className='wrap-row title-error'>
                                403
                            </h1>
                            <h2 className='wrap-row noPadd'>
                                Accesso Negato
                            </h2>
                            <div className='descErr descErrAlign'>L'utente non risulta abilitato alla visualizzazione della pagina</div>
                            <Link to={`/${ home }`} className='button404' >
                                <Button className="backButton text-uppercase" variant="outlined" >
                                    <span>Ritorna alla home</span>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage403
