import React, {useEffect, useState, useContext }                    from "react";
import { Routes, Route, Link, Navigate, useNavigate, useLocation }  from 'react-router-dom';
import axios                                                        from "axios";

import {  Button
        , CircularProgress
        , ClickAwayListener 
        , Divider
        , IconButton
        , ListItemIcon 
        , ListItemText
        , Menu
        , MenuItem
        , MenuList 
        , Popover
        , Tooltip }                     from '@mui/material';
        
import Adjustment                       from './adjustment/Adjustment.js';
import Allarmi                          from './allarmi/Allarmi.js';
import AllarmeDettaglio                 from './allarmi/AllarmeDettaglio.js';
import Anagrafiche                      from './anagrafiche/Anagrafiche.js';
import AnagraficheDettaglio             from './anagrafiche/AnagraficheDettaglio.js';
import AnagraficheStoricoForecast       from './anagrafiche/AnagraficheStoricoForecast.js';
import DashBoard                        from './dashboard/Dashboard.js';
import ErrorPage403                     from './errorPage/ErrorPage403.js';
import ErrorPage404                     from './errorPage/ErrorPage404.js';
import Forecast                         from './forecast/Forecast.js';
import ChangePassword                   from './users/ChangePassword.js';
import UploadFile                       from './uploadFile/UploadFile.js';
import Users                            from './users/Users.js';
import UserDetail                       from './users/UserDetail';
import CreateUser                       from './users/CreateUser';
import './index.css'

import { AuthContext }                  from '../contexts/authContext';
import { AppContext }                   from '../App.js';
import { PopupConfirm }                 from '../components/PopupConfirm/PopupConfirm.js'
import config                           from '../config.js';
import utils                            from '../util/CommonUtilities';
import CircleProgress                   from '../components/CircleProgress/CircleProgress.tsx';
import Roobeek                          from '../assets/images/roobeek.png';
import {ReactComponent as SysCubeLogo}  from '../assets/images/systrategy_cube_logo.svg';
import {ReactComponent as SysWordLogo}  from '../assets/images/systrategy_word_logo.svg';

import { AccountCircleSolidIcon
       , AdjustIcon
       , BellIcon
       , CabinetIcon
       , CancelIcon
       , CreditCardIcon
       , DashBoardIcon
       , ForecastIcon
       , GearIcon
       , GroupIcon
       , KeyIcon
       , LogoutIcon
       , NotificationImportantIcon
       , UploadIcon                }    from '../assets/icons/icons.js';

const bDebug = config.DEBUG && false;

const asNamePageDefault = [ 'Allarmi', 'Allarme 1', 'Allarme 2', 'Allarme 3', 'Allarme 4', 'Allarme 5', 'Allarme 6', 'Allarme 7', 'Allarme 8', 'Allarme 9', 'Allarme 10',
                            'Allarme 1 | Dettaglio', 'Allarme 2 | Dettaglio', 'Allarme 3 | Dettaglio', 'Allarme 4 | Dettaglio', 'Allarme 5 | Dettaglio', 'Allarme 6 | Dettaglio', 'Allarme 7 | Dettaglio', 'Allarme 8 | Dettaglio', 'Allarme 9 | Dettaglio', 'Allarme 10 | Dettaglio',
                            'Anagrafiche', 'Anagrafica', 
                            'Anagrafica | Line Items', 'Anagrafica | Line Items | Contending', 'Anagrafica | Placements', 'Anagrafica | Ad Units',
                            'Anagrafica | DVN | Line Items',       'Anagrafica | DVN | Line Items | Contending',       'Anagrafica | DVN | Ad Units',       'Anagrafica | DVN | Placements',
                            'Anagrafica | Youtube | Line Items',   'Anagrafica | Youtube | Line Items | Contending',   'Anagrafica | Youtube | Ad Units',   'Anagrafica | Youtube | Placements',
                            'Anagrafica | Eurosport | Line Items', 'Anagrafica | Eurosport | Line Items | Contending', 'Anagrafica | Eurosport | Ad Units', 'Anagrafica | Eurosport | Placements',
                            'Adjustment', 'Adjustment | Dettaglio', 'Dashboard', 'Forecast', 'Carica File',
                            'Utenti', 'Crea Utente', 'Cambia Password', 
                            'Pagina non trovata', 'Accesso Negato', 'Home', ''];

const MainIndex = () => {
    
    const auth = useContext(AuthContext);
    const { set_oNotifyOptions, sConcatNamePage ,setsConcatNamePage, selectedLogo, selectedLogoOnlyText, dLastUpdate  } = useContext(AppContext);
    const navigate = useNavigate();
    const { pathname }  = useLocation();
    const [ userEmail ] = useState(localStorage.getItem('userEmail') ? localStorage.getItem('userEmail') : 'Username');
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    
    const
         isLocal     = process.env.REACT_APP_ENV === 'local'
        ,isLocalSole = process.env.REACT_APP_ENV === 'localsole'
        ,isProdSole  = process.env.REACT_APP_ENV === 'prodsole'
        ,isLocalDisc = process.env.REACT_APP_ENV === 'localdisc'
        ,isProdDisc  = process.env.REACT_APP_ENV === 'proddisc'
        ,appName     =
                       isProdSole  ? 'DIS24'
                     : isProdDisc  ? 'Digital Analyzer'
                     : isLocalSole ? 'DIS24 Dev'
                     : isLocalDisc ? 'DIAN Dev'
                     : isLocal     ? 'DIAN Dev'
                     : ''
        ,bAdminUser  = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
    ;

    const [ namePage          ,setNamePage           ] = useState('Home' ) // Assegnazione Fittizia, a prescindere appena verrà rendirizzato un componente figlio esso rinominerà il namePage
         ,[ oLicenses         ,set_oLicenses         ] = useState({ TOTAL: 30 , USED: 0, UNUSED: 0 }) //useState(null);
         ,[ loadingLicenses   ,set_LoadLicenses      ] = useState(false)
         ,[ loading           ,setLoading            ] = useState(false)
         ,[ sideHover         ,setSideHover          ] = useState(false)
        //  ,[ openUserSettings, setOpenUserSettings ] = useState(false)
         ,[ anchorUserSetting ,setAnchorUserSetting  ] = useState(null)
         ,[ anchorSettings    ,setAnchorSettings     ] = useState(null)
         ,[ anchorNotify      ,setAnchorNotify       ] = useState(null)
         ,[ sPopupType        ,set_sPopupType        ] = useState('')
         ,[ dLastUpdateToView ,set_dLastUpdateToView ] = useState('')
         ,[ nIdInterval       ,set_nIdInterval       ] = useState('')
         ,[ nThersMessage     ,setnThersMessage      ] = useState(0)
         ,[ aoNotifyMessage   ,set_aoNotifyMessage   ] = useState([])
    ;
    
    const createPopupDialog = () => {

        const oPopupOptions = {
            'licenses': {
                 DialogTitle: <span className="dialog-title-licenses"><CreditCardIcon />Licenze Utenti</span>
                ,Content: loadingLicenses ? <div className="myFlex"><div className='circularprogressWrapper'><CircularProgress color="inherit" /></div></div> :
                    !(oLicenses||{}).TOTAL ? '' :
                        <div className="licenses-table">
                            <div><div>Numero totale di licenze: </div><div>{ oLicenses.TOTAL  }</div></div>
                            <div><div>Licenze usate:            </div><div>{ oLicenses.USED   }</div></div>
                            <div><div>Licenze rimanenti:        </div><div>{ oLicenses.UNUSED }</div></div>
                        </div>
                ,hideFistButton:    true
                ,closeButtonLabel: 'chiudi'
            }
        }[sPopupType] || {};

        return <PopupConfirm
            { ...{ oPopupOptions, sPopupType, set_sPopupType } }
        />

    };

    /* #region Handler */
    const handleHover = (action) => {
        if(action === 'in'){
            setSideHover(true);
        }else{
            setSideHover(false);
        }
    }

    const handleOpen = ( event, target ) =>{
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(event.currentTarget);
                break;

            case 'notify':
                setnThersMessage(0);
                setAnchorNotify(event.currentTarget);
                break;                

            case 'settings':
                setAnchorSettings(event.currentTarget);
                break;

            default:
                bDebug && console.log('Sorry, somethink whent wrong!');
                break;
        }
    };

    const handleClose = (target) => {
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(null);
                break;

            case 'notify':
                setAnchorNotify(null);
                break;                

            case 'settings':
                setAnchorSettings(null);
                break;

            default:
                bDebug && console.log('Sorry, somethink whent wrong!');
                break;
        }
    };

    const handleClickNotify = ( notify ) => {
        // Setto la notifica come letta ( cookie letto e cancello mex dalla coda ) e nel caso di forecast renderizzo sulla pagina estraendo file da db
        handleClose('notify');
        document.cookie = notify.sCoockie;
        // dato che premendo sulla notifica ci si aspetta un rendirizzamento, potrebbe non essere necessario risettare il suo stato ( setmsgState() ) in quanto l'array di notifiche viene ricostruito ogni secondo
        // notify.setmsgState('read'); 
        navigate(notify.msgURL);
    }
    
    const handleCancelNotify = ( notify ) => {
        // !!! ToDo lanciare chiamata per cancellare messaggio dalla coda
        document.cookie = notify.sCancelCookie;
        // Necessario poi eliminare la notifica dall'array, la cosa più facile potrebbe essere riscatenare la creazione delle notifiche stesse
        getMessageCookie();
    }
    /* #endregion Handler */

    /* #region Navigate */
    const goToHome = () => {
        if ( isLocalSole || isProdSole ) {
            navigate('/allarmi');
        } else {
            navigate('/dashboard');
        }
    };

    const logOut = () =>{
        handleClose();
        auth.signOut();
        navigate('/login', {replace: false});
    }

    const goToChangePassword = () => {
        // handleClose();
        navigate('/cambia-password');
    }
    /* #endregion Navigate */

    /* #region CallToDB */
    const getLicenses = async () => {
        set_LoadLicenses(true);

        if ( bAdminUser ) {
            try {
                // let aoLicenses = [{ TOTAL: 10, USED: 4, UNUSED: 6 }];
                let aoLicenses = await axios.get( config.API_URL + '/get', { headers, params : { "getLicensesParams": true } } );

                if ( aoLicenses?.status === 200 && aoLicenses?.data?.aoRecords ) {
                    bDebug && console.log('aoLicenses.data?.aoRecords?.length: ', aoLicenses.data?.aoRecords?.length);
                    let countLicencies = aoLicenses.data.aoRecords[0].COUNT_ACTIVE_USER
                    set_oLicenses(( countLicencies ) ? { TOTAL: 30, USED: countLicencies, UNUSED: (30 - countLicencies) } : {});
                }
            } catch(err) {
                console.error(err);
                set_oNotifyOptions({ message: `Interrogazione fallita, impossibile ottenere il numero di Licenze da Database`, severity:'error' });
            }
        }
        
        set_LoadLicenses(false);
    }

    const loginToRoobeek = async() => {
        try{
            let url = `${config.API_URL}/link-to-roobeek`
            let params = {};
            let response = await axios.post(url, null, { headers, params });
            bDebug && console.log('response: ', response);
            
            if(response.status===200){
                let redirectUrl = response.data
                window.open(redirectUrl, '_blank');
            }
        }catch(err){
            console.error('err', err);
        }
    }
    /* #endregion CallToDB */

    /* #region CalloToAWS */
    const testRequestSqs = async() => {
        console.log('io richiedo')
        setInterval(async ()=>{
            let response = await axios.get( config.API_URL + '/readonsqs', { headers, params : {} } );
            console.log('Response testRequestSqs: ', response); 
            // let aResp = JSON.parse( response.data ); // Maybe
            if ( response.status === 200 && Array.isArray(response.data) && response.data?.length ){
                let defaultDateExpire = new Date();
                defaultDateExpire.setHours(defaultDateExpire.getHours() + 1); // !!! ToDo 
                // console.log(defaultDateExpire);

                const sCookies = document.cookie; 

                response.data?.forEach(messaggio => {
                    console.log('Messaggio: ', messaggio);
                    let { Body: msgBody, MessageId } = messaggio;
                    // Controllo che il messaggio non sia già stato salvato nei cookie
                    if ( !sCookies.includes( MessageId /* ? really? maybe only MessageId ? */ )) {
                        console.log(msgBody.split('§'));
                        // let aMsgBody = msgBody.split('§');
                        // let userCreator = msgBody[0];
                        // let expireMsgTime = msgBody[4] ? new Date(msgBody[4]) : defaultDateExpire;
                        // let expireMsgTime = defaultDateExpire;
                        document.cookie = `${ MessageId }=${ msgBody }§${ defaultDateExpire.toUTCString() }§unread; expires=${defaultDateExpire.toUTCString()}; path=/`
    
                        setnThersMessage(1);
                    };
                });
                getMessageCookie();
            }
        },100000)
        
    }
    /* #endregion CalloToAWS */

    /* #region UtilsFunction */
    const formatExecution       = (val) => utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', fromNow: true, useTimezone: true });

    const getMessageCookie = () => {
        console.log('getMessageCookie')
        console.log('Cookie: ', document.cookie);
        let asCookie = document.cookie.split(';').filter( sCookie => sCookie.includes(`Forecast`)) // Bisogna inserire la condizione per Raccogliere solamente i cookie interessati
        let aoMessage = [];
        for ( let i = 0; i < asCookie; i++ ) {
            aoMessage.unshift( utils.oMessage( asCookie[i] ))
        };
        console.log('aoMessage: ',aoMessage);
        set_aoNotifyMessage( aoMessage );
        // set_aoNotifyMessage( aCookie.forEach( cookie => new utils.oMessage(cookie)));
    }
    /* #region UtilsFunction */
    
    // useEffect(() => {
    // testRequestSqs();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);

    useEffect(() => {
        // console.log('nIdInterval: ', nIdInterval);
        clearInterval(nIdInterval);
        set_dLastUpdateToView(formatExecution(dLastUpdate));
        
        if ( dLastUpdate ) {
            set_nIdInterval(setInterval( () => {
                set_dLastUpdateToView(formatExecution(dLastUpdate))
            }, 60000 ));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dLastUpdate ]);

    useEffect( () => {
        setNamePage('Pagina non trovata');
        setsConcatNamePage('')
        let newNamePage = utils.formatTitleHeader(pathname);
        document.title = appName + ' | 404';
        // console.log(newNamePage)
        
        // !!! TODO da aggiungere gli account di Discovery - meglio se parametrizzato
        if ( newNamePage === 'errorPage403' ) {
            setNamePage('Accesso Negato');
            document.title = appName + ' | 403';

        } else {
            const sTitle = asNamePageDefault.find( sNamePage => sNamePage === newNamePage );
            // console.log('newNamePage: ', newNamePage)
            // console.log('sTitle: ', sTitle)
            const [ t_last, t_last_1 ] = sTitle.split(' | ').reverse();

            if (sTitle) {
                setNamePage( sTitle );
                document.title = appName + ( t_last_1 ? ` | ${ t_last_1 }` : '' ) + ' | ' + ( t_last || '' );
            } else { bDebug && console.log("Titolo non trovato") }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathname ]);
    
    return (
        <div className={"main-background"}>
            {
                loading ? <CircleProgress /> : <>
                    <aside onMouseEnter={() => handleHover('in')} onMouseLeave={() => handleHover('out')}>
                        <div className="logo-and-menu-container">
                            <div className="side-menu-logo">
                                <img src={ selectedLogo } alt="" className="logoImage"/>
                                {
                                    ( sideHover && ( isProdDisc || isLocalDisc || isLocal )) && <img src={ selectedLogoOnlyText } alt="Digital Analyzer" className="logoText"/>
                                }
                            </div>
                            <ul>
                                {
                                    isLocal || isLocalSole || isLocalDisc ?
                                    <li>
                                        <Link to="/dashboard" className="linkMenu">
                                            <div className="side-menu-options">
                                                <DashBoardIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Dashboard'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                {
                                    isLocal || isLocalSole || isLocalDisc || isProdSole ? 
                                    <li>
                                        <Link to="/allarmi" className="linkMenu">
                                            <div className="side-menu-options">
                                                <NotificationImportantIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Allarmi'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                <li>
                                    <Link to="/anagrafiche" className="linkMenu">
                                        <div className="side-menu-options">
                                            <CabinetIcon/>&nbsp;&nbsp;
                                            {sideHover && 'Anagrafiche'}
                                        </div>
                                    </Link>
                                </li>
                                {
                                    false ?
                                    <li>
                                        <Link to="/forecast" className="linkMenu">
                                            <div className="side-menu-options">
                                                <ForecastIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Forecast'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                {
                                    isLocal || isLocalSole || isProdSole ?
                                    <li>
                                        <Link to="/adjustment" className="linkMenu">
                                            <div className="side-menu-options">
                                                <AdjustIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Adjustment'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                {
                                    isLocal || isLocalSole || isLocalDisc ?
                                    <li>
                                        <Link to="/carica-file" className={`linkMenu`}>
                                            <div className="side-menu-options">
                                                <UploadIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Carica File'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                {
                                    bAdminUser  ?  // && !isProdDisc
                                    <li>
                                        <Link to="utenti" className={`linkMenu`}>
                                            <div className="side-menu-options">
                                                <GroupIcon/>&nbsp;&nbsp;
                                                {sideHover && 'Utenti'}
                                            </div>
                                        </Link>
                                    </li>
                                    : <></>
                                }
                                {
                                    isLocal || isLocalSole || isLocalDisc || isProdSole ?
                                    <li>
                                        <div className="roobeekPath side-menu-options" onClick={loginToRoobeek}>
                                            <img src={Roobeek} alt="Roobeek" title="Roobeek" className="logoRoobeek" />&nbsp;&nbsp;
                                            {sideHover && 'Roobeek'}
                                        </div>
                                    </li>
                                    : <></>
                                }
                            </ul>
                        </div>
                        <a href='https://www.systrategy.it/' target='_blank' rel="noreferrer" className="logo-container roobeekPath side-menu-options">
                            <div style={{width: '30%'}}><SysCubeLogo style={{ paddingLeft:'10px'}}/></div>
                            <div className='logo-wrapper' style={{width: '80%' }}>{sideHover && (<SysWordLogo/>)}</div>
                        </a>
                    </aside>
                    <div className="main-content">
                        <div className="header">
                            <div className="header-container">
                                <div>
                                    <span className="app-name cursor-pointer" onClick={goToHome}>{ appName }</span>
                                    <span className="app-name cursor-default"> | {namePage} { sConcatNamePage ? ` | ${ sConcatNamePage }`: '' }</span>
                                    { dLastUpdateToView ? <span className="cursor-default"> | {dLastUpdateToView} </span> : <></> }
                                </div>
                                <div className="header-container">
                                    { bAdminUser && <div>
                                        <Tooltip title="Impostazioni Applicazione">
                                            <IconButton type="button" onClick={ (event) => handleOpen( event, 'settings' ) }>
                                                <GearIcon addClass={ `textBlack bigIcon ${ Boolean(anchorSettings) ? 'openMenu' : ''}`}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            className = "menu-settings"
                                            anchorEl  = {anchorSettings}
                                            open      = {Boolean(anchorSettings)}
                                            onClose   = {() => handleClose('settings')}
                                            style     = {{ marginTop: 5, left: -8 }}
                                            keepMounted 
                                        >
                                            <MenuItem className="dropdown-user-settings" onClick={ ()=> { getLicenses(); set_sPopupType('licenses'); }}>
                                                <div className='menu-icon-container'>
                                                    <CreditCardIcon />
                                                </div>
                                                &nbsp;&nbsp;&nbsp;Licenze
                                            </MenuItem>
                                        </Menu>
                                    </div> }&nbsp;
                                    { (isLocal || isLocalDisc || isLocalSole) && <div>
                                        <Tooltip title={`Notifiche: ${aoNotifyMessage.length}`}>
                                            <IconButton 
                                              id            =  "notify-button"
                                              aria-controls = { Boolean(anchorNotify) ? 'notify-menu' : undefined }
                                              aria-haspopup = "true"
                                              aria-expanded = { Boolean(anchorNotify) ? 'true' : undefined }
                                              type          = "button" 
                                              onClick       = { (event) => handleOpen(event, 'notify') }
                                            >
                                                { (nThersMessage > 0) && <span className="green-dot"/> }
                                                {/* { (aoNotifyMessage.length > 0) && <span className="grey-dot"/> } */}
                                                <BellIcon addClass={ `textBlack bigIcon ${ Boolean(anchorNotify) ? 'openMenu' : ''}`}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            id            = "notify-menu"
                                            MenuListProps = {{ 'aria-labelledby': 'notify-button' }}
                                            anchorEl      = { anchorNotify }
                                            open          = { Boolean(anchorNotify) }
                                            onClose       = { () => handleClose('notify') }
                                            className     = "menu-notifiche"
                                            keepMounted
                                        >
                                            {/* Devo ciclare sui coockie e costruire un item per ogni notifica dell'utente */}
                                            {
                                                aoNotifyMessage.length ?
                                                    aoNotifyMessage.map(( message, index ) => (<>
                                                        <MenuItem key={ message.messageId || '' } onClick={()=> { console.log(message); handleClickNotify(message); }} className={ message.msgState }>
                                                            <ListItemIcon>
                                                                { message.msgIcon }
                                                            </ListItemIcon> 
                                                            <ListItemText>
                                                                { message.messageBody || '' }
                                                            </ListItemText> 
                                                            <ListItemIcon>
                                                                <CancelIcon onClick={()=> handleCancelNotify(message)} />
                                                            </ListItemIcon> 
                                                        </MenuItem> 
                                                        { ( aoNotifyMessage.length > 1 ) && ( index < aoNotifyMessage.length ) ? <Divider sx={{ my: 0.5 }} /> : '' }
                                                    </>))

                                                : <MenuItem key={ 'Nessuna notifica' } value=''>
                                                      Nessuna notifica
                                                  </MenuItem>
                                            }
                                        </Menu>
                                    </div>}
                                    <div className="user-settings-wrapper" onClick={(event) => handleOpen(event, 'user-settings')}>
                                        <span>{userEmail}</span>&nbsp;
                                        <AccountCircleSolidIcon className="cursor-pointer"/>
                                        <Popover
                                            open={Boolean(anchorUserSetting)}
                                            anchorEl={anchorUserSetting}
                                            onClose={() => handleClose('user-settings')}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={() => handleClose('user-settings')}>
                                            <div className="dropdown-user-settings">
                                                <Button className="dropdown-user-options cursor-pointer" onClick={goToChangePassword} startIcon={<KeyIcon/>}>Cambia Password</Button>
                                                <Button className="dropdown-user-options cursor-pointer" onClick={logOut} startIcon={<LogoutIcon />}>Esci</Button>
                                            </div>
                                            </ClickAwayListener>
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { ( sPopupType !== '' ) && createPopupDialog() }

                        <Routes>
                            {   // Rotte in Test
                                isLocal || isLocalSole || isLocalDisc ?
                                    <>
                                        <Route path="/dashboard"        element={<DashBoard                  />}/>
                                        <Route path="/carica-file"      element={<UploadFile                 />}/>
                                        <Route path="/forecast"         element={<Forecast                   />}/>
                                    </>
                                : <></>
                            }
                            {   
                                isLocal || isLocalSole || isLocalDisc || isProdSole ?
                                <>
                                        <Route path="/"                          element={<Navigate to="/allarmi" />}/>
                                        <Route path=""                           element={<Navigate to="/allarmi" />}/>
                                        <Route path="/adjustment"                      element={<Adjustment           />}/>
                                        <Route path="/adjustment/:adjustId/dettaglio"  element={<Adjustment           />}/>
                                        <Route path="/allarmi"                               element={<Allarmi            />}/>
                                        <Route path="/allarme/:id"                           element={<AllarmeDettaglio   />}/>
                                        <Route path="/allarme/:id/:idDettaglio/:dettaglio"   element={<AllarmeDettaglio   />}/>
                                    </>
                                : <></>
                            }
                            {
                                isProdDisc ?
                                    <>
                                        <Route path="/"                 element={<Navigate to="/anagrafiche" />}/>
                                        <Route path=""                  element={<Navigate to="/anagrafiche" />}/>
                                    </>
                                : <></>
                            }
                            {   // Rotte riservate agli Admin
                                bAdminUser ?
                                    <>
                                        <Route path="/utenti"           element={<Users                      />}/>
                                        <Route path="/crea-utente"      element={<CreateUser                 />}/>
                                        <Route path="/utente/:id"       element={<UserDetail                 />}/>
                                    </>
                                : <></>
                            }                        
                            {/* // Rotte disponibili a tutti */}
                                    <Route path="/anagrafiche"          element={<Anagrafiche                />}/>
                                    <Route path="/anagrafica/:account/:accountcode/:dettaglio"                                 element={<AnagraficheDettaglio       />}/>
                                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:idDettaglio"                    element={<AnagraficheDettaglio       />}/>
                                    {/* <Route path="/anagrafica/:account/:accountcode/:dettaglio/:idDettaglio/:origin"            element={<AnagraficheDettaglio       />}/> */}
                                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:id/dettaglio"                   element={<AnagraficheStoricoForecast />}/>
                                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:id/dettaglio/:isForecastNotify" element={<AnagraficheStoricoForecast />}/>
                                    <Route path="/cambia-password"      element={<ChangePassword             />}/>
                            {   /*ErrorPage */ }
                                    <Route path="/errorPage403"         element={<ErrorPage403               />}/>
                                    <Route path="/*"                    element={<ErrorPage404               />}/>
                        </Routes>
                    </div>
                </>
            }
        </div>
    )

};

export default MainIndex;