import React, {
       useState
     , useEffect 
     , useContext
}                                   from 'react';
import { Link, useParams }          from 'react-router-dom';
import axios                        from 'axios';
import moment                       from 'moment';
import 'moment/locale/it';

import './Adjustment.css';
import { AuthContext }              from '../../contexts/authContext.js';
import { AppContext }               from '../../App.js';
import config                       from '../../config.js';
import utils                        from '../../util/CommonUtilities.js';
import CircleProgress               from '../../components/CircleProgress/CircleProgress.tsx';
import { SimpleTable }              from '../../components/SimpleTable/SimpleTable.js';
import { CustomCssTextField }       from '../../components/CustomCSS/CustomCSS.tsx';
import AdjustmentEdit               from './AdjustmentEdit.js'

import {
    CustomCssTab
  , CustomCssTabList }              from '../../components/CustomCSS/CustomCSS.tsx';

import TabContext                   from '@mui/lab/TabContext';
import TabPanel                     from '@mui/lab/TabPanel';
import { LocalizationProvider }     from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }            from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }               from '@mui/x-date-pickers/DatePicker';
import { Autocomplete
       , Box
       , Button
       , CircularProgress
       , IconButton
       , Tooltip }                  from '@mui/material';
import { AddIcon
       , DoubleArrowLeftIcon
       , DoubleArrowRightIcon
       , CachedIcon
       , CheckIcon
       , DoDisturbIcon              
       , FolderOffIcon }            from '../../assets/icons/icons.js';

const bDebug         = config.DEBUG && false;
const bDebugTable    = config.DEBUG && true;
const firstDateToSee = '20230101'; // '20230101'

const regexOnlyNum       = /^[0-9]*$/;
const maxAdjustDiffDates = 14;
const linkGam            = 'https://developers.google.com/ad-manager/api/reference/v202305/AdjustmentService#errors';

const Adjustment = (props) => {
    
    const isProd = process.env.REACT_APP_ENV.includes('prod');
    const isSole = process.env.REACT_APP_ENV.includes('sole');
    const auth = useContext(AuthContext);
    const { adjustId } = useParams();
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    
    const
         sGAMurl                 = config.GAM_URL
        ,sAccountCode            = isSole ? config.GAM_ACCOUNT_CODE : ''
        ,formatNum0dec           = ( val ) => val && utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })
        ,formatDate              = ( val ) => moment(val, 'YYYYMMDD').format('D MMM YYYY')
        ,formatDateWeek          = (val) => utils.formatDateWeek(val)
        // ,formatAdjustVal         = ( val, oRow ) => <span className={ val > 0 ? 'textGreen' : val < 0 ? 'textRed' : '' }>{ `${ val > 0 ? '+' :'' }${val} ${ oRow.ADJ_TYPE === 'P' ? '%' : '' }` }</span>
        ,formatAdUnitLink        = ( val ) => <a href={ sGAMurl + sAccountCode + `#inventory/ad_unit/detail/ad_unit_id=${ val }` }    target='_blank' className='myExternalLinkID' rel='noreferrer'>{ !!val ? val : '' }</a>
        ,formatPlacementLink     = ( val ) => <a href={ sGAMurl + sAccountCode + `#inventory/placement/detail/placement_id=${ val }`} target='_blank' className='myExternalLinkID' rel='noreferrer'>{ !!val ? val : '' }</a>
        ,formatEnableAdjButton   = ( val, oRow ) => <Tooltip title={ val === 'ACTIVE' ? 'Disabilita' : 'Abilita' } placement="bottom" arrow followCursor><IconButton /* onClick={ () => fPopupModAdj( oRow ) } */ ><div className='iconWrap'>
                                                        { ( val === 'ACTIVE' ) ? <CheckIcon addClass={   "textGreen" }/>
                                                                               : <DoDisturbIcon addClass={ "textRed" }/> }
                                                    </div></IconButton></Tooltip>
        ,formatLinkToDett        = ( val ) => { return val ? <Tooltip title="Dettaglio" placement='left' arrow followCursor>
                                                                <Link to={`/adjustment/${ val }/dettaglio`} className='link myShadow'>
                                                                    <DoubleArrowRightIcon />
                                                                </ Link>
                                                            </Tooltip> : '' }
    ;

    /* #region Dichiarazioni */
        const
             [ loading ,setLoading ] = useState(true)
            ,[ isCallToDB           ,set_isCallToDB          ] = useState(false) // questo state non indica che stiamo facendo la chiamata, ma che la chiamata ha già risposto
            ,[ onReload             ,set_onReload            ] = useState(null) // State per triggerare il lancio della chiamata
            ,[ sPopupType           ,set_sPopupType          ] = useState('')  // State per condizionare l'apertura del Popup di conferma
            
            ,[ sSearchType          ,set_sSearchType         ] = useState('id')   // State per contenere l'indicazione se stiamo ricercando per name o id

            ,[ aosAdUnit            ,set_aosAdUnit           ] = useState([])   // State per contenere l'elenco di AdUnit
            ,[ oAdUnitToAdjust      ,set_oAdUnitToAdjust     ] = useState(null) // State per contenere l'oggetto AdUnit a cui assegnare l'Adjust
            ,[ sAdUnitName          ,set_sAdUnitName         ] = useState('')   // State per contenere la stringa per ricercare l'AdUnit
            ,[ sAdUnitID            ,set_sAdUnitID           ] = useState('')

            ,[ aosPlacement         ,set_aosPlacement           ] = useState([])   // State per contenere l'elenco di AdUnit
            ,[ oPlacementToAdjust   ,set_oPlacementToAdjust     ] = useState(null) // State per contenere l'oggetto AdUnit a cui assegnare l'Adjust
            ,[ sPlacementName       ,set_sPlacementName         ] = useState('')   // State per contenere la stringa per ricercare l'AdUnit
            ,[ sPlacementID         ,set_sPlacementID           ] = useState('')
            
            // ,[ aoAdjustsHeaders        ,set_aoAdjustsHeaders       ] = useState([])
            ,[ oRecordAdj              ,set_oRecordAdj             ] = useState({})   // State per contenere l'elenco dei record per Placement prima della formattazione per DataSingola/DataRange
            ,[ aoAdjustmentsPlacement  ,set_aoAdjustmentsPlacement ] = useState([])   // State per contenere l'elenco di Adjustment appartenente al Placement selezionato
            ,[ aoAdjustmentsAdUnit     ,set_aoAdjustmentsAdUnit    ] = useState([])   // State per contenere l'elenco di Adjustment del'AdUnit selezionata appartenente
            ,[ oAdjToMod               ,set_oAdjToMod              ] = useState(null) // State per contenere l'Adjustment da Abilitare/disabilitare da passare al Popup
            
            ,[ dDataInizio          ,set_dDataInizio         ] = useState(moment())
            ,[ dDataFine            ,set_dDataFine           ] = useState(moment().add(7, 'days'))
            ,[ sAdjType             ,set_sAdjType            ] = useState('ad unit')    // State per indicare in quale tab si sta navigando [ AdUnits, Placements ]
        ;

        let aoAdjustsHeaders = [
                   adjustId && {...{ name: 'giorno'        ,title: 'Data'           ,width: 150   ,isNum: true   ,format: formatDateWeek                               ,filterOriginalValue: true   ,isUniqueKeyForRow:   true }}
                 ,!adjustId && {...{ name: 'dataInizio'    ,title: 'Data Inizio'    ,width: 150   ,isNum: true   ,format: formatDateWeek                               ,filterOriginalValue: true   ,isUniqueKeyForRow:   true }}
                 ,!adjustId && {...{ name: 'dataFine'      ,title: 'Data Fine'      ,width: 150   ,isNum: true   ,format: formatDateWeek                               ,filterOriginalValue: true   ,isUniqueKeyForRow:   true }}
             ,( bDebugTable && {...{ name: 'adjustId'      ,title: 'ID'             ,width: 120   ,isNum: true                                  ,showDiff: !!adjustId                               ,isUniqueKeyForRow:   true }})
             ,( bDebugTable && {...{ name: 'name'          ,title: 'Etichetta'      ,width: 500                                                 ,showDiff: !!adjustId  }})
  ,sAdjType === 'placement' && {...{ name: 'placementId'   ,title: 'Placement ID'   ,width: 120   ,isNum: true   ,format: formatPlacementLink   ,showDiff: !!adjustId  ,filterOriginalValue: true }}
  ,sAdjType === 'ad unit'   && {...{ name: 'adunitId'      ,title: 'AD Unit ID'     ,width: 120   ,isNum: true   ,format: formatAdUnitLink      ,showDiff: !!adjustId  ,filterOriginalValue: true }}
                                  ,{ name: 'value'         ,title: 'Adjustment'     ,width: 120   ,isNum: true   ,format: formatNum0dec         ,showDiff: !!adjustId  }
                 ,!adjustId && {...{ name: 'dettaglio'     ,title: ''               ,width: 45    ,isNum: true   ,format: formatLinkToDett                                                          ,notSortable: true }}
                 //  ,{ name: 'status'                         ,title: ''             ,width: 45                 ,format: formatEnableAdjButton                            ,filterOriginalValue: true ,notSortable: true }
        ];
    /* #endregion Dichiarazioni */

    /* #region GetFunction */

        const getAdUnitList = async () => {
            bDebug && console.log('Dentro GetADunitFromDB');
            set_oNotifyOptions({ message: '' });
            bDebug && console.log('sSearchType: ', sSearchType)

            try {
                const params = {
                     getAnagraficaAdUnitUniqueNameIDParams: true
                    ,oFilterParams: {
                        ...( sSearchType === 'id' 
                      ? { pAccountDesc : 'ilsole24ore' // !!! TODO da parametrizzare o rendere nullable
                        , pAdUnitName  : ''
                        , pAdUnitId    : sAdUnitID }
                      : { pAccountDesc : 'ilsole24ore'
                        , pAdUnitName  : sAdUnitName
                        , pAdUnitId    : '' } )
                    }
                };
                bDebug && console.log(params)
                const response = await axios.get(config.API_URL + '/get', { headers, params });
        
                if (response && (response?.status === 200) && response?.data?.aoRecords && Array.isArray(response.data.aoRecords)) {
                    if (response?.data?.aoRecords?.length) {
                        set_aosAdUnit([...response.data.aoRecords]);

                        if (sSearchType === 'id' ) {
                            if ( sAdUnitID ) { 
                            set_oAdUnitToAdjust({...response?.data?.aoRecords[0]} || null);

                            // Questo else if è sbagliato perche con la ricerca per nome non ottengo un risultato univoco, non posso settare l'adunit a priori con il primo record
                            // } else if (sSearchType === 'name') {
                            //     set_sAdUnitID(response?.data?.aoRecords[0]?.AD_UNIT_ID || '')
                            } else {
                                set_oAdUnitToAdjust(null)
                                set_sAdUnitName('')
                            }
                        }
                    } else {
                        set_oAdUnitToAdjust(null)
                        set_sAdUnitName('')
                    }
                } else {
                    console.error('ERR 153.5: Failed GetADunitList');
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                }
            } catch (err) {
                console.error('ERR 153: Failed GetADunitList: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
            };
            set_isCallToDB(true);
        };

        const getPlacementList = async () => {
            bDebug && console.log('Dentro GetPlacementFromDB');
            set_oNotifyOptions({ message: '' });

            try {
                const params = {
                     getAnagraficaPlacementsUniqueParams: true
                    ,oFilterParams: {
                        ...( sSearchType === 'id' 
                      ? { pAccountDesc    : 'ilsole24ore' // !!! TODO da parametrizzare o rendere nullable
                        , pPlacementName  : ''
                        , pPlacementId    : sPlacementID }
                      : { pAccountDesc    : 'ilsole24ore'
                        , pPlacementName  : sPlacementName
                        , pPlacementId    : '' } )
                    }
                };
                const response = await axios.get(config.API_URL + '/get', { headers, params });
        
                if (response && (response?.status === 200) && response?.data?.aoRecords && Array.isArray(response.data.aoRecords)) {
                    if (response?.data?.aoRecords?.length) {
                        set_aosPlacement([...response.data.aoRecords]);
                        if ( sSearchType === 'id' ) {
                            if (sPlacementID ) { 
                            set_oPlacementToAdjust({...response?.data?.aoRecords[0]} || null);
                            } else {
                                set_oPlacementToAdjust(null)
                                set_sPlacementName('')
                            }
                        }
                    } else {
                        set_oPlacementToAdjust(null)
                        set_sPlacementName('')
                    }
                } else {
                    console.error('ERR 154.5: Failed GetPlacementList');
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                }
            } catch (err) {
                console.error('ERR 154: Failed GetPlacementList: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
            };
            set_isCallToDB(true);
        };

        const getAdjust = async () => {
            bDebug && console.log('Dentro GetAdjust');
            set_dLastUpdate('');
            setLoading(true);

            try {
                let params = {
                      onlyGetAdjusts : true
                    , GAMaccount     : 'ilsole24ore'
                    , pDataInizio    : ( dDataInizio || moment() ).format('YYYYMMDD')
                    , pDataFine      : ( dDataFine   || moment() ).format('YYYYMMDD')
                }
                const response = await axios.get( config.API_URL + '/gam-adjust', { headers, params } );
                const resValid = ( response && ( response.status === 200 ) && response?.data );

                if ( !resValid ) {
                    console.error('ERR 151: Failed GetAdjust');
                    set_oNotifyOptions({ message: `Impossibile recuperare i dati da GAM`, severity:'error' });
                    setLoading(false);

                } else if ( resValid?.error ) { 
                    console.error('ERR 152: Failed GetAdjust');
                    console.log( resValid?.error )
                    set_oNotifyOptions({ message: `Impossibile recuperare i dati da GAM`, severity:'error' });
                    setLoading(false);

                } else if ( resValid ) {
                    // !!! TODO set_dLastUpdate(''); se va a buon fine potremmo anche stampare data e ora corrente essendo uno scarico immediato di dati freschi
                    set_oRecordAdj({...resValid});
                }
            } catch (err) {
                console.error('ERR 150: Failed GetAdjust: ', err);
                set_oNotifyOptions({ message: `Impossibile recuperare i dati da GAM`, severity:'error' });
                setLoading(false);
            }
        };

        const setAdjAdUnit = () => {
            bDebug && console.log('Dentro setAdjAdUnit');
            let { aoSegments, aoAdjustsFromGAM } = oRecordAdj;

            // converto l'elenco di segments in un oggetto che ha come chiavi le Adunit ID e come valore il Segment ID
            // così per ottenere il segment id di una adunit basta fare: oAdunitsWithSegment[123] (dove 123 è ID di Adunit)
            const oAdunitsWithSegment = aoSegments.reduce( ( o, oSegment ) => ({
                ...o
                ,[  oSegment.id ] : +oSegment?.targeting?.inventoryTargeting?.targetedAdUnits?.[0]?.adUnitId || 0
            }) ,{});
            // bDebug && console.log('oAdunitsWithSegment: ', oAdunitsWithSegment)

            let aoAdjusts = [];

            if ( adjustId ) {
                set_sSearchType('id')
                // Se Abbiamo l'adjustId significa che siamo nel dettaglio e quindi va mostrato il dettaglio giornaliero
                
                // *** PARTE NUOVA ***
                    
                /* primo esperimento: conversione in semplice array di oggetti [{ nSegmentId, nAdunitId }]
                aoSegments = aoSegments.map( oSegment => (
                    { nSegmentId: oSegment.id, nAdunitId: +oSegment?.targeting?.inventoryTargeting?.targetedAdUnits?.[0]?.adUnitId || 0 }
                ));*/
                
                // converto aoAdjustsFromGAM in un altro array in cui elemento corrisponde a una terna (AdunitID, giorno, valore)
                // bDebug && console.table(aoAdjustsFromGAM);
                for ( let n1 = 0; n1 < aoAdjustsFromGAM.length; n1++ ) {
                    // bDebug && console.table([{ n1, 'adunitid': oAdunitsWithSegment[ aoAdjustsFromGAM[n1].trafficForecastSegmentId ], 'segmentid': aoAdjustsFromGAM[n1].trafficForecastSegmentId }])
                    const { id, name, status, trafficForecastSegmentId, dailyVolumeSettings, dateRange } = aoAdjustsFromGAM[n1];
                    const asAdjustValues = dailyVolumeSettings?.adOpportunityCounts
                    const oAdjust = { adjustId: id, name, status, adunitId: oAdunitsWithSegment[ trafficForecastSegmentId ] };
                    const asDates = utils.getSingleDates( dateRange.startDate, dateRange.endDate );
                    
                    for ( let n2 = 0; n2 < asAdjustValues.length; n2++ ) {
                        aoAdjusts.push({ ...oAdjust, giorno: ( asDates[n2] || '' ), value: ( asAdjustValues[n2] || '' ) });
                    }
                    
                }
                // bDebug && console.log('oAdUnitToAdjust?.AD_UNIT_ID: ', oAdUnitToAdjust?.AD_UNIT_ID)
                aoAdjusts = aoAdjusts.filter( o => ( o.adunitId ) && ( o.adjustId === adjustId ) && ( o.giorno > firstDateToSee )  ).slice(0,99);

                if (oAdUnitToAdjust?.AD_UNIT_ID) {
                    aoAdjusts = aoAdjusts.filter( o => o.adunitId === oAdUnitToAdjust?.AD_UNIT_ID );
                }
                // bDebug && console.log(aoAdjusts);
                /* esempio di record
                    adjustId    : "16307"
                    adunitId    : undefined
                    giorno      : "20181113"
                    name        : "09/21/2018 - 04/13/2019"
                    status      : "ACTIVE"
                    value       : "88032"
                */
                set_aoAdjustmentsAdUnit([ ...aoAdjusts ] || [] );
                // console.log(aoAdjusts[0].adunitId)
                set_sAdUnitID(aoAdjusts[0].adunitId)
            } else {
                
                // bDebug && console.table(aoAdjustsFromGAM);
                for ( let n1 = 0; n1 < aoAdjustsFromGAM.length; n1++ ) {
                    // bDebug && console.table([{ n1, 'adunitid': oAdunitsWithSegment[ aoAdjustsFromGAM[n1].trafficForecastSegmentId ], 'segmentid': aoAdjustsFromGAM[n1].trafficForecastSegmentId }])
                    const { id, name, status, trafficForecastSegmentId, volumeType, dailyVolumeSettings, totalVolumeSettings, dateRange } = aoAdjustsFromGAM[n1];
                    const oAdjust = { adjustId: id, name, status, adunitId: oAdunitsWithSegment[ trafficForecastSegmentId ] };
                    const asDates = utils.getFirstLastDates( dateRange.startDate, dateRange.endDate );
                    let sAdjustValues = '';
                    if ( volumeType === 'DAILY_VOLUME' ) {
                        const [ min, max ] = utils.getMinMaxArray(dailyVolumeSettings?.adOpportunityCounts);
                        sAdjustValues = min === max ? min : '';
                    } else {
                        sAdjustValues = volumeType === 'TOTAL_VOLUME' ? totalVolumeSettings?.adOpportunityCount : '';
                    }

                    if ( !(asDates.find( sDate => sDate < firstDateToSee ))) {
                        // console.log('sAdjustValues: ', sAdjustValues)
                        aoAdjusts.push({ ...oAdjust, dataInizio: ( asDates[0] || '' ), dataFine: ( asDates[1] || '' ), value: sAdjustValues, ...(( volumeType === 'DAILY_VOLUME' ) && { dettaglio: id } ) });
                    }
                }

                if (oAdUnitToAdjust?.AD_UNIT_ID) {
                    aoAdjusts = aoAdjusts.filter( o => o.adunitId && o.adunitId === oAdUnitToAdjust?.AD_UNIT_ID );
                } else {
                    aoAdjusts = aoAdjusts.filter( o => o.adunitId );
                }

                set_aoAdjustmentsAdUnit([ ...aoAdjusts ] || [] );
            }
            
            setLoading(false);
        };

        const setAdjPlacement = () => {
            bDebug && console.log('Dentro setAdjPlacement');
            let { aoSegments, aoAdjustsFromGAM } = oRecordAdj;
            
            const oPlacementsWithSegment = aoSegments.reduce( ( o, oSegment ) => ({
                ...o
                ,[  oSegment.id ] : +oSegment?.targeting?.inventoryTargeting?.targetedPlacementIds?.[0] || ''
            }) ,{});
            let aoAdjusts = [];
            
            if ( !!adjustId ) {
                set_sSearchType('id')
                // converto aoAdjustsFromGAM in un altro array in cui elemento corrisponde a una terna (PLACEMENTID, giorno, valore)
                // bDebug && console.table(aoAdjustsFromGAM);
                for ( let n1 = 0; n1 < aoAdjustsFromGAM.length; n1++ ) {
                    // bDebug && console.table([{ n1, 'placementId': oPlacementsWithSegment[ aoAdjustsFromGAM[n1].trafficForecastSegmentId ], 'segmentid': aoAdjustsFromGAM[n1].trafficForecastSegmentId }])
                    // console.log(`aoAdjustsFromGAM[${n1}]:`)
                    // console.log(aoAdjustsFromGAM[n1])
                    const { id, name, status, trafficForecastSegmentId, dailyVolumeSettings, dateRange } = aoAdjustsFromGAM[n1];
                    const asAdjustValues = dailyVolumeSettings?.adOpportunityCounts;
                    const oAdjust = { adjustId: id, name, status, placementId: oPlacementsWithSegment[ trafficForecastSegmentId ] };
                    const asDates = utils.getSingleDates( dateRange.startDate, dateRange.endDate );
                    
                    for ( let n2 = 0; n2 < asAdjustValues.length; n2++ ) {
                        aoAdjusts.push({ ...oAdjust, giorno: ( asDates[n2] || '' ), value: ( asAdjustValues[n2] || '' ) });
                    }
                    
                }
                aoAdjusts = aoAdjusts.filter( o => ( o.placementId ) && ( o.adjustId === adjustId ) && ( o.giorno > firstDateToSee ) ).slice(0,99);
                
                // bDebug && console.log('oPlacementToAdjust?.PLACEMENT_ID: ', oPlacementToAdjust?.PLACEMENT_ID)
                if ( oPlacementToAdjust?.PLACEMENT_ID ) {
                    aoAdjusts = aoAdjusts.filter( o => o.placementId === oPlacementToAdjust?.PLACEMENT_ID );
                }

                bDebug && console.log(aoAdjusts);
                set_aoAdjustmentsPlacement(aoAdjusts || [] )
                set_sPlacementID(aoAdjusts[0].placementId);
            } else {
                for ( let n1 = 0; n1 < aoAdjustsFromGAM.length; n1++ ) {
                    const { id, name, status, trafficForecastSegmentId, volumeType, dailyVolumeSettings, totalVolumeSettings, dateRange } = aoAdjustsFromGAM[n1];
                    // console.log(typeof oPlacementsWithSegment[ trafficForecastSegmentId ]);
                    const oAdjust = { adjustId: id, name, status, placementId: oPlacementsWithSegment[ trafficForecastSegmentId ] };
                    const asDates = utils.getFirstLastDates( dateRange.startDate, dateRange.endDate );
                    let sAdjustValues = '';
                    if ( volumeType === 'DAILY_VOLUME' ) {
                        const [ min, max ] = utils.getMinMaxArray(dailyVolumeSettings?.adOpportunityCounts);
                        sAdjustValues = min === max ? min : '';
                    } else {
                        sAdjustValues = volumeType === 'TOTAL_VOLUME' ? totalVolumeSettings?.adOpportunityCount : '';
                    }
                    bDebug && console.log('sAdjustValues: ', sAdjustValues)

                    if ( !(asDates.find( sDate => sDate < firstDateToSee ))) {
                        aoAdjusts.push({ ...oAdjust, dataInizio: ( asDates[0] || '' ), dataFine: ( asDates[1] || '' ), value: sAdjustValues, ...(( volumeType === 'DAILY_VOLUME' ) && { dettaglio: id } ) });
                    }
                }
                
                // bDebug && console.log('oPlacementToAdjust?.PLACEMENT_ID: ', oPlacementToAdjust?.PLACEMENT_ID)
                if ( oPlacementToAdjust?.PLACEMENT_ID ) {
                    aoAdjusts = aoAdjusts.filter( o => o.placementId && o.placementId === oPlacementToAdjust?.PLACEMENT_ID );
                } else {
                    aoAdjusts = aoAdjusts.filter( o => o.placementId ).slice(0,99);
                }

                bDebug && console.log('aoAdjusts: ', aoAdjusts);
                
                set_aoAdjustmentsPlacement([...aoAdjusts] || [] )
            }
            setLoading(false);
        };
    /* #endregion Function */

    /* #region Hadler */

        /* Handler per controllare che le date siano conformi prima di aprire il popup di conferma per inserire l'ADJ
            in questa pagina il controllo della maxAdjustDiffDates lo facciamo qui perchè vogliamo che sia solo in inserimento ADJ (così si permette la visualizzazione gli ADJ in un periodo più ampio */
        const handleDate = () => {
            let diffDate = dDataFine?.diff(dDataInizio || moment(), 'days');
            bDebug && console.log('DiffDate: ', dDataFine?.diff(dDataInizio || moment(), 'days'));
            return ( diffDate >= 0 ) && ( diffDate <= maxAdjustDiffDates );
        };

        // Function per cercare l'oAdUnitToAdjust in base all'id digitato
        const handleChangeID = ( value, label ) => {
            if ( regexOnlyNum.test(value) || value === '' ) { 
                if ( label === 'Ad Unit ID') {
                    set_sAdUnitID(value);
                } else if ( label === 'Placement ID') {
                    set_sPlacementID(value)
                }
            }
        };
    
    /* #endregion Hadler */

    /* #region PopupConfirmAddAdjust */

        // Function per controllare se vi sono le condizioni per aprire il Popup di inserimento ADJ
        const foPopupAddAdjust = () => {
            bDebug && console.log('FoPopupAddAdjust');
            if (( sAdjType === 'ad unit' && oAdUnitToAdjust ) || ( sAdjType === 'placement' && oPlacementToAdjust )) { // !!! TODO inserimento controllo regex
                if ( handleDate() ) {
                    bDebug && console.log(`I'm here for you`)
                    set_sPopupType('AddAdj');
                } else {
                    set_oNotifyOptions({ message: `Il periodo selezionato è troppo ampio.`, severity:'warning', timeout: 5 });
                }
            } else {
                set_oNotifyOptions({ message: `E' necessario riempire tutti i campi.`, severity:'warning', timeout: 5 });
            };
        };
    /* #endregion */

    /* #region PopupConfirmEnable/DisableAdjust */
        // Function per Abilitare/Disabilitare l'Adjustment selezionata
        const fModAdj = async () => {
            bDebug && console.log('FAddAdjust');
            setLoading(true);
            set_oNotifyOptions({ message: '' });

            // !!! TODO bisogna decidere come comportarsi con pEtichettaAdj 
            try {
                let params = { pAdjustId :                   oAdjToMod?.adjustId
                             , GAMaccount:                  'ilsole24ore'
                             , forecastAdjustmentAction :  ( oAdjToMod?.stato === 'active' ? 'DeactivateForecastAdjustments' : 'ActivateForecastAdjustments' ) 
                             , filterStatement :           { query: `WHERE id = ${ oAdjToMod?.adjustId }` }
                            }
                // let params = { pAdUnitId:   oAdUnitToAdjust?.AD_UNIT_ID
                //     , GAMaccount: 'ilsole24ore'
                //     , pDataInizio: dDataInizio.format('YYYYMMDD')
                //     , pDataFine:   dDataFine.format('YYYYMMDD')
                //     , pAdjVal:     nValueAdjust 
                //     , pStatus:    'active'
                // }

                const response = await axios.get( config.API_URL + '/gam-adjust', { headers, params } );
                const resValid = ( response && ( response.status === 200 ) && response?.data );

                bDebug && console.log(Object.keys( response ))
                bDebug && console.log( response )

                if ( !resValid ) {
                    console.error('ERR 156.1: Failed ModAdjust');
                    set_oNotifyOptions({ message: `Impossibile salvare le modifiche su GAM`, severity:'error' });
                    
                } else if ( resValid?.error ) { 
                    console.error('ERR 156.2: Failed ModAdjust');
                    console.log( resValid?.error )
                    set_oNotifyOptions({ message: <span className='flexySnackAlert'><span>Errore Google Ad Manager:</span><span>{ resValid?.error?.errBody }</span><a className='myExternalLinkwhite' href={ linkGam } target='blank'>Visualizza dettaglio errore</a></span> , severity:'error' });

                } else if ( resValid && resValid.response ) {
                    console.log('Response: ', resValid.response )
                    set_oNotifyOptions({ message: `Adjustment ${ oAdjToMod.stato === 'active' ? 'disabilitata' : 'abilitata' } con successo`, severity:'success', timeout: 4 });
                    
                } else {
                    throw new Error('ERR 156.3: errore sconosciuto');
                }
            } catch ( err ) {
                console.error('ERR 156: Failed ConnectToGam ModAdjust', err);
                set_oNotifyOptions({ message: `Impossibile connettersi a GAM`, severity:'error' });
            }

            set_sPopupType('');
            set_onReload(!onReload);
        };

        // Dichiarazione PoPup per Abilitare/Disabilitare ADJ
        const oPopupModifyAdj = {
             DialogTitle: `Confermi di voler ${ oAdjToMod?.stato === 'active' ? 'disabilitare' : 'abilitare' } il seguente Adjustment`
            ,Content: <div style={{ width: 'fit-content'}}>
                        <table className='tablePopup'>
                            <tbody>
                            {  
                                bDebugTable ? <tr>
                                    <td>Adjustment:</td>
                                    <td className='myTruncText'>{ oAdjToMod?.adjustId }</td>
                                </tr> : ''
                            }
                            <tr>
                                <td>AD Unit ID:</td>
                                <td>{ oAdjToMod?.adunitId }</td>
                            </tr>
                            <tr>
                                <td>Periodo:</td>
                                <td>{ oAdjToMod?.giorno }</td>
                            </tr>
                            <tr>
                                <td>Valore:</td>
                                <td>{ oAdjToMod?.value }</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
            ,onClick:               fModAdj
            ,firstButtonLabel:      'conferma'
            ,classFirstButton:      'popupConfirm'
            ,classCloseButton:      'popupAnnul'
        };

        // Function per triggherare l'apertura del Popup per Abilitare/Disabilitare l'Adjustment
        const fPopupModAdj = ( oRow ) => {
            bDebug && console.log('FPopupModAdj');
            set_oAdjToMod({ ...oRow });
            set_sPopupType('modAdj');
        };
    /* #endregion PopupConfirmEnableAdjust */

    /* #region UseEffect */

        // UseEffect iniziale: Caricamento ADJ e AD_Unit_Name
        useEffect( () => {
            bDebug && console.log('UseEffect Iniziale');
            setLoading(true);
            set_isCallToDB(false);
            getAdUnitList();
            getPlacementList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        
        // Al Cambio di tipologia di Adj (switch tab) controllo se ho già ottenuto gli Adj da Gam, se non li ho già faccio la richiesta, altrimenti posso direttamente chiamare le setFunction
        // Questa useEffect verrà richiamata anche al caricamento iniziale della pagina, quindi utilizzata come getAdjIniziale
        useEffect(() => {
            bDebug && console.log('Dentro useEffect sAdjType: ', sAdjType );
            if ( !Object.keys(oRecordAdj).length ) {
                getAdjust();
            } else {
                if ( sAdjType === 'ad unit' ) {
                    setLoading(true);
                    setAdjAdUnit();
                }
                if ( sAdjType === 'placement' ) {
                    setLoading(true)
                    setAdjPlacement();
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sAdjType ]);
        
        // Tramite questa useEffect controllo la tab e decido quale setFunction lanciare
        // Per evitare di richiamare questa funzione molte volte si potrebbe controllare se aoRecordAdj non è vuoto
        useEffect(() => {
            if ( Object.keys(oRecordAdj).length ) {
                bDebug && console.log('Dentro useEffect oRecordAdj' );
                if ( sAdjType === 'ad unit' ) {
                    setLoading(true);
                    setAdjAdUnit();
                }
                if ( sAdjType === 'placement' ) {
                    setLoading(true)
                    setAdjPlacement();
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ oRecordAdj ]);

        // Use Effect per scatenare le chiamate per ottenere l'elenco di ADJUSTMENT
        useEffect(() => {
            if ( onReload !== null ) {
                bDebug && console.log('Dentro UseEffect OnReload');
                setLoading(true);
                set_oNotifyOptions({ message: '' });
                set_aoAdjustmentsAdUnit([]);
                set_aoAdjustmentsPlacement([]);
                getAdjust();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ onReload ]);

        // UseEffect che permette di visualizzare il dettaglio
        useEffect(() => {
            bDebug && console.log('useEffect adjustId: ', adjustId );
            if ( Object.keys(oRecordAdj).length ) {
                // console.log('aoRecordAdj: ', oRecordAdj );
                if ( sAdjType === 'ad unit' ) {
                    setLoading(true);
                    setAdjAdUnit();
                }
                if ( sAdjType === 'placement' ) {
                    setLoading(true)
                    setAdjPlacement();
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ adjustId ]);

        // UseEffect per ottenere l'elenco di AD_Unit alla digitazione nel campo autocomplete sAdUnitName
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect sAdUnitName');
            if ( sSearchType === 'name' ) {
                set_isCallToDB(false);
                set_aosAdUnit([]);
                utils.debounce( getAdUnitList, 1000 )();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sAdUnitName ]);

        // UseEffect per ottenere l'elenco di AD_Unit alla digitazione nel campo TextField sAdUnitID
        useEffect(() => {
            if ( sSearchType === 'id' ) {
                bDebug && console.log('Dentro UseEffect sAdUnitID');
                set_isCallToDB(false);
                set_aosAdUnit([]);
                utils.debounce( getAdUnitList, 1000 )();
            } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sAdUnitID ]);

        // Questa useEffect è necessaria perchè solamente nel caso in cui oAdUnitToAdjust cambia il suo valore e siamo nella ricerca per nome dobbiamo aggiornare l'adunit id
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect oAdUnitToAdjust');
            sSearchType === 'name' && set_sAdUnitID( oAdUnitToAdjust?.AD_UNIT_ID || '' )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ oAdUnitToAdjust ]);

        // UseEffect per ottenere l'elenco di Placement alla digitazione nel campo autocomplete sPlacementName
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect sPlacementName');
            if ( sSearchType === 'name' ) {
                set_isCallToDB(false);
                set_aosPlacement([]);
                utils.debounce( getPlacementList, 1000 )();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sPlacementName ]);

        // UseEffect per ottenere l'elenco di AD_Unit alla digitazione nel campo TextField sPlacementID
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect sPlacementID');
            if ( sSearchType === 'id' ) {
                set_isCallToDB(false);
                set_aosPlacement([]);
                utils.debounce( getPlacementList, 1000 )();
            } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sPlacementID ]);

        // Questa useEffect è necessaria perchè solamente nel caso in cui oPlacementToAdjust cambia il suo valore e siamo nella ricerca per nome dobbiamo aggiornare l'sPlacementID
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect oPlacementToAdjust: ', oPlacementToAdjust);
            sSearchType === 'name' && set_sPlacementID( oPlacementToAdjust?.PLACEMENT_ID || '' )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ oPlacementToAdjust ]);

        // UseEffect per controllare il cambio Data Inizio
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect dDataInizio ', dDataInizio.format('DD/MM/YYYY') );
            if ( dDataInizio && dDataInizio.isValid() ) {
                bDebug && console.log( `${dDataInizio} ${dDataInizio.isValid()}` );
                let bIsMin = (dDataInizio || moment()).format('YYYYMMDD') <= ( dDataFine || moment() ).format('YYYYMMDD');
                if ( !bIsMin ) {
                    set_dDataFine(moment(dDataInizio));
                }
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ dDataInizio ]);

        // UseEffect per controllare il cambio Data Fine
        useEffect(() => {
            bDebug && console.log('Dentro UseEffect dDataFine ', dDataFine.format('DD/MM/YYYY') );
            if ( dDataFine && dDataFine.isValid() ) {
                bDebug && console.log( `${dDataFine} ${dDataFine.isValid()}` );
                let bIsMag = (dDataFine || moment()).format('YYYYMMDD') >= (dDataInizio || moment()).format('YYYYMMDD');
                if ( !bIsMag ) {
                    set_dDataInizio(moment(dDataFine));
                }
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ dDataFine ]);

    /* #endregion UseEffect */

    /* #region SupportFunction */
        const fRetrurnToElenco = () => {
            set_sSearchType('id');
            if ( sAdjType === 'ad unit' ) {
                set_sAdUnitID('');
                set_oAdUnitToAdjust(null);
                set_aoAdjustmentsAdUnit([]);
            } else {
                set_sPlacementID('');
                set_oPlacementToAdjust(null);
                set_aoAdjustmentsPlacement([]);
            }
        };

        const myKeyPress = (e) => {
            if (e.key === 'Enter' ) {
                // console.log('i`m Here')
                e.preventDefault();
                set_oNotifyOptions({ message: '' });
                set_onReload(!onReload);
            }
        };
    /* #endregion SupportFunction */

    return (
      <div className="component-container adjustment" tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }>
        <div className="component-card">
            <Box sx={{ width: '100%', typography: 'body1', position: 'relative' }}>
                <TabContext value={sAdjType}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CustomCssTabList onChange={ (event, newValue) => set_sAdjType(newValue) } aria-label="lab API tabs example">
            { !adjustId  && <CustomCssTab label="Ad Unit"   value="ad unit"   /> }
            { !adjustId  && <CustomCssTab label="Placement" value="placement" /> }
                        </CustomCssTabList>
                    </Box> 
    {  adjustId  && <Link to={`/adjustment`} className='link myShadow flyButton' onClick={() => fRetrurnToElenco()} >
                        <Button className="backButton" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} > Elenco Adjustment</Button>
                    </ Link> }
                    <TabPanel value="ad unit" className="tabPanel" >
                        <span className='component-wrapper-Adj-AdUnit'>
                            <span className='insAdjColumnHeaderWrapper'>
                                <span className='insAdjustmentHeaderGroupWrapper'>
                                    <span className='insAdjustOptionGroup'>
                                        <CustomCssTextField
                                            disabled        = { !!adjustId }
                                            autoComplete    = "off"
                                            id              = "AdUnitID"
                                            key             = "AdUnitID"
                                            label           = { 'Ad Unit ID' }
                                            value           = { sAdUnitID }
                                            onChange        = { (event) => { handleChangeID(event.target.value, 'Ad Unit ID' );} }
                                            onFocus         = { () => { console.log('Dentro select onFocus'); set_sSearchType('id'); }}
                                            InputLabelProps = { { shrink: true } }
                                            variant         = "outlined"
                                            type            = "text"
                                            className       = "smallSpace smallHeight"
                                            onKeyDown       = { (e) => { e.stopPropagation() }}
                                        />
                                        <Autocomplete
                                            disabled        = { !!adjustId }
                                            className       = "AutocompleteFieldAdunit mediumSpace smallHeight"
                                            options         = { aosAdUnit }
                                            loading         = { !isCallToDB }
                                            noOptionsText   = { isCallToDB ? 'Nessun elemento trovato' : "Loading..." }
                                            getOptionLabel  = {( option => option?.AD_UNIT_NAME )}
                                            value           = { oAdUnitToAdjust }
                                            onChange        = { (event, newValue) => set_oAdUnitToAdjust(newValue) }
                                            inputValue      = { sAdUnitName }
                                            onInputChange   = { (event, newInputValue) => { set_sAdUnitName(newInputValue); }}
                                            onOpen          = { () => { set_sSearchType('name'); }}
                                            // onAction        = { () => { set_sSearchType('name'); }}
                                            clearOnBlur     = { false }
                                            renderInput     = { (params) => 
                                                                <CustomCssTextField 
                                                                        {...params}
                                                                        label       = "Ad Unit"
                                                                        onMouseDown = {() => { set_sSearchType('name') }}
                                                                        InputProps  = {{
                                                                         ...params.InputProps,
                                                                            endAdornment: (
                                                                               <React.Fragment>
                                                                                 {!isCallToDB ? <span className='circularprogressWrapper'><CircularProgress color="inherit" size={20} /></span> : null}
                                                                                 {params.InputProps.endAdornment}
                                                                               </React.Fragment>
                                                                            )}}
                                                                    /> }
                                        />
    
                                        <LocalizationProvider dateAdapter={AdapterMoment} key="date" >
                                          <Tooltip title={`Periodo massimo di giorni selezionabili per inserimento ADJ: ${ maxAdjustDiffDates }`} placement='top' >
                                           <span className='insAdjustOptionGroup'>
                                            <span className="TextFieldDateSpan mybordercolor d-flex jumpDateIcon" >
                                                { utils.jumpSegmentDate({ sPosition: 'before', dDateVal: dDataInizio, set_dDateVal: set_dDataInizio, dDateCompare: moment(), dSegmentDateVal: dDataFine, set_dSegmentDateVal: set_dDataFine }) }
                                                <DatePicker
                                                    id          = "dataInizio"
                                                    label       = "Data Inizio"
                                                    key         = "dataInizio"
                                                    format      = "DD/MM/YYYY"
                                                    views       = {['year', 'month', 'day']}
                                                    value       = { dDataInizio }
                                                    minDate     = { moment() }
                                                    onChange    = {( value ) => set_dDataInizio(value) }
                                                    slotProps   = {{ textField: { variant: 'outlined' } }}
                                                    className   = "TextFieldDate smallSpace smallHeight"
                                                />
                                            </span>
                                                                            
                                            <span className="TextFieldDateSpan mybordercolor d-flex jumpDateIcon" >
                                                <DatePicker
                                                    id          = "dataFine"
                                                    label       = "Data Fine"
                                                    key         = "dataFine"
                                                    format      = "DD/MM/YYYY"
                                                    views       = {['year', 'month', 'day']}
                                                    value       = { dDataFine }
                                                    minDate     = { dDataInizio }
                                                    onChange    = { ( value ) => set_dDataFine(value) }
                                                    slotProps   = {{ textField: { variant: 'outlined' } }}
                                                    className   = "TextFieldDate smallHeight"
                                                />
                                                { utils.jumpSegmentDate({ sPosition: 'after', dDateVal: dDataFine, set_dDateVal: set_dDataFine, dDateCompare: moment().add(1, 'years'), dSegmentDateVal: dDataInizio, set_dSegmentDateVal: set_dDataInizio ,addClass: 'mediumSpace' }) }
                                            </span>
                                           </span>
                                          </Tooltip>
                                        </LocalizationProvider>
                                        <Tooltip title="Inserisci Adjustment" placement="top" arrow>
                                            <IconButton className="dianButton myShadow saveButton bigSpace smallHeight" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ foPopupAddAdjust }>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                    <span className='reload-adj'>
                                        <Tooltip title="Aggiorna Elenco" placement="top" arrow>
                                            <IconButton className="dianButton reloadButton myShadow smallHeight" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => { set_oNotifyOptions({ message: '' }); set_onReload(!onReload); }} >
                                                <CachedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                </span>
                            </span>
                            {/* {
                                sPopupType === 'modAdj' ?
                                    <PopupConfirm { ...{ 
                                          oPopupOptions:  oPopupModifyAdj
                                        , sPopupType
                                        , set_sPopupType } } />
                                    : ''
                            } */}
                            {
                              loading
                                ? <CircleProgress/>
                                : ( aoAdjustmentsAdUnit?.length && aoAdjustsHeaders?.length && Array.isArray(aoAdjustmentsAdUnit) )
                                    ?   <div className="table-wrapper-adjustment">
                                            <SimpleTable
                                                    chiave         = { 'ADJUSTMENTS' }
                                                    sTableDataType = { 'ADJUSTMENTS' }
                                                    aoRows         = { aoAdjustmentsAdUnit || [] }
                                                    aoCols         = { aoAdjustsHeaders }
                                                    oSortOptions   = { { isSortable: true } }
                                                    oExportOption  = { { sFileName: `Adjustments ${ adjustId ? adjustId + ' ' : '' }AdUnit ${ oAdUnitToAdjust?.AD_UNIT_ID || '' }` } }
                                                    noHeight       = { true }
                                                />
                                        </div>
                                    :   <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                            }
                        </span>
                    </TabPanel>
                    <TabPanel value="placement" className="tabPanel" >
                        <span className='component-wrapper-Adj-AdUnit'>
                            <span className='insAdjColumnHeaderWrapper'>
                                <span className='insAdjustmentHeaderGroupWrapper'>
                                    <span className='insAdjustOptionGroup'>
                                        <CustomCssTextField
                                            disabled        = { !!adjustId }
                                            autoComplete    = "off"
                                            id              = "PlacementID"
                                            key             = "PlacementID"
                                            label           = { 'Placement ID' }
                                            value           = { sPlacementID }
                                            onChange        = { (event) => { handleChangeID( event.target.value, 'Placement ID' )}}
                                            onFocus         = { () => { set_sSearchType( 'id' )}}
                                            InputLabelProps = { { shrink: true } }
                                            variant         = "outlined"
                                            type            = "text"
                                            className       = "smallSpace smallHeight"
                                            onKeyDown       = { (e) => { e.stopPropagation() }}
                                        />
                                        <Autocomplete
                                            disabled        = { !!adjustId }
                                            className       = "AutocompleteFieldAdunit mediumSpace smallHeight"
                                            options         = { aosPlacement }
                                            loading         = { !isCallToDB }
                                            noOptionsText   = { isCallToDB ? 'Nessun elemento trovato' : "Loading..." }
                                            getOptionLabel  = { (option => option?.PLACEMENT_NAME ) }
                                            value           = { oPlacementToAdjust }
                                            onChange        = { (event, newValue) => set_oPlacementToAdjust(newValue) }
                                            inputValue      = { sPlacementName }
                                            onInputChange   = { (event, newInputValue) => { set_sPlacementName( newInputValue )}}
                                            onOpen          = { () => { set_sSearchType( 'name' )}}
                                            clearOnBlur     = { false }
                                            renderInput     = { (params) => 
                                                                <CustomCssTextField 
                                                                        {...params}
                                                                        label       = "Placement"
                                                                        onMouseDown = {() => { set_sSearchType('name') }}
                                                                        InputProps  = {{
                                                                         ...params.InputProps,
                                                                            endAdornment: (
                                                                               <React.Fragment>
                                                                                 {!isCallToDB ? <span className='circularprogressWrapper'><CircularProgress color="inherit" size={20} /></span> : null}
                                                                                 {params.InputProps.endAdornment}
                                                                               </React.Fragment>
                                                                            )}}
                                                                    /> }
                                        />
    
                                                <LocalizationProvider dateAdapter={AdapterMoment} key="date" >
                                          <Tooltip title={`Periodo massimo di giorni selezionabili per inserimento ADJ: ${ maxAdjustDiffDates }`} placement='top'>
                                           <span className='insAdjustOptionGroup'>
                                            <span className="TextFieldDateSpan mybordercolor d-flex jumpDateIcon" >
                                                { utils.jumpSegmentDate({ sPosition: 'before', dDateVal: dDataInizio, set_dDateVal: set_dDataInizio, dDateCompare: moment(), dSegmentDateVal: dDataFine, set_dSegmentDateVal: set_dDataFine }) }
                                                <DatePicker
                                                    id          = "dataInizio"
                                                    label       = "Data Inizio"
                                                    key         = "dataInizio"
                                                    format      = "DD/MM/YYYY"
                                                    views       = {['year', 'month', 'day']}
                                                    value       = { dDataInizio }
                                                    minDate     = { moment() }
                                                    onChange    = {( value ) => set_dDataInizio(value) }
                                                    slotProps   = {{ textField: { variant: 'outlined' } }}
                                                    className   = "TextFieldDate smallSpace smallHeight"
                                                />
                                            </span>
                                                                            
                                            <span className="TextFieldDateSpan mybordercolor d-flex jumpDateIcon" >
                                                <DatePicker
                                                    id          = "dataFine"
                                                    label       = "Data Fine"
                                                    key         = "dataFine"
                                                    format      = "DD/MM/YYYY"
                                                    views       = {['year', 'month', 'day']}
                                                    value       = { dDataFine }
                                                    minDate     = { dDataInizio }
                                                    onChange    = { ( value ) => set_dDataFine(value) }
                                                    slotProps   = {{ textField: { variant: 'outlined' } }}
                                                    className   = "TextFieldDate smallHeight"
                                                />
                                                { utils.jumpSegmentDate({ sPosition: 'after', dDateVal: dDataFine, set_dDateVal: set_dDataFine, dDateCompare: moment().add(1, 'years'), dSegmentDateVal: dDataInizio, set_dSegmentDateVal: set_dDataInizio ,addClass: 'mediumSpace' }) }
                                            </span>
                                           </span>
                                          </Tooltip>
                                        </LocalizationProvider>
                                        <Tooltip title="Inserisci Adjustment bigSpace" placement="top" arrow>
                                            <IconButton className="dianButton myShadow saveButton smallSpace smallHeight" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ foPopupAddAdjust }>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                    <span className='reload-adj'>
                                        <Tooltip title="Aggiorna Elenco" placement="top" arrow>
                                            <IconButton className="dianButton reloadButton myShadow smallHeight" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => { set_oNotifyOptions({ message: '' }); set_onReload(!onReload); }} >
                                                <CachedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                </span>
                            </span>
                            {/* {
                                sPopupType === 'modAdj' ?
                                    <PopupConfirm { ...{ 
                                          oPopupOptions:  oPopupModifyAdj
                                        , sPopupType
                                        , set_sPopupType } } />
                                    : ''
                            } */}
                            {
                              loading
                                ? <CircleProgress/>
                                : ( aoAdjustmentsPlacement?.length && aoAdjustsHeaders?.length && Array.isArray(aoAdjustmentsPlacement) )
                                    ?   <div className="table-wrapper-adjustment">
                                            <SimpleTable
                                                    chiave         = { 'ADJUSTMENTS' }
                                                    sTableDataType = { 'ADJUSTMENTS' }
                                                    aoRows         = { aoAdjustmentsPlacement || [] }
                                                    aoCols         = { aoAdjustsHeaders }
                                                    oSortOptions   = { { isSortable: true } }
                                                    oExportOption  = { { sFileName: `Adjustments ${ adjustId ? adjustId + ' ' : '' }Placement ${ oPlacementToAdjust?.PLACEMENT_ID || '' }` } }
                                                    noHeight       = { true }
                                                />
                                        </div>
                                    :   <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                            }
                        </span>
                    </TabPanel>
                </TabContext>
            </Box>
            <AdjustmentEdit { ...{ oPopupSettings: { sPopupType, set_sPopupType, setLoading, onReload, set_onReload }
                                 , oPopupOptions:  { oAdUnitToAdjust, oPlacementToAdjust, dDataInizio, dDataFine, sAdjType }}} />
        </div>
      </div>
    )
};

export default Adjustment;
