import './SimpleTable.css';
import React, {useEffect, useRef, useState} from 'react';
// import { Paper, Checkbox, MenuItem, Select} from '@material-ui/core';
import { DownLoadIcon
       , EditIcon }     from '../../assets/icons/icons.js'; // '@mui/icons-material/Edit';
import { Button
       , Checkbox
       , MenuItem
       , Paper
       , Select
       , TextField
       , Tooltip      } from '@mui/material';
import PaginationTool   from '../PaginationTool/PaginationTool';
import utils            from '../../util/CommonUtilities.js';
import moment           from 'moment';

const removeSpecialChars = s => ( ['string','number'].includes(typeof s) ? s : '' ).toString().replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
let timeoutIdOnHover     = 0;

const sortBy = ( arrayOfObjects, arrayOfProperties, arrayOfSortingType ) => {
    // console.log('arrayOfProperties: ', arrayOfProperties)
    // console.log('arrayOfSortingType: ', arrayOfSortingType)
    // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )
    if ( !Array.isArray(arrayOfProperties) ) { arrayOfProperties = [arrayOfProperties]; }

    let toStr                = anyVar => ( anyVar || 0 ).toString(),         // per avere stringhe simili da confrontare (i falsy vengono uniformati)
        uniformRes           = ( res, sortType ) => ( ( res < 0 ) ? -1 : ( res > 0 ? 1 : 0 ) ) * ( [ 'DESC', '-1' ].includes( ( sortType + '' ).toUpperCase() ) ? -1 : 1  ) , // serve per uniformare i risultati del localeCompare (-1 0 +1)
        compare              = ( e1, e2, sortType ) => uniformRes(                         // restituisce un valore uniformato per la comparazione di stringhe
             toStr(e1).localeCompare( toStr(e2), 'en', {numeric: true, sensitivity: 'base'} )
                ,sortType
        ),
        // controlla se è una stringa non vuota
        validateString       = str        => ( typeof str === 'string' && str !== '' ) ? str : 'err: "'+JSON.stringify(str)+'"',
        validateProperty     = ( elToDebug, prop )  => {
            console.assert( typeof elToDebug[prop] !== 'undefined', 'ERROR in sortBy: proprietà ' + prop + ' non trovata in', elToDebug );
            return elToDebug[prop] || 0
        },
        compareAllProperties = ( obj1, obj2, props = [], sortTypes = [] ) => {
            let retVal          = 0 ;
            props.forEach( ( property, nProp ) => {
                property        = validateString(property);
                retVal          = retVal || compare ( validateProperty(obj1, property), validateProperty(obj2, property), sortTypes[nProp] );
            });
            return retVal
        }
    ;
    
    return [...arrayOfObjects].sort( ( a, b ) => compareAllProperties( a, b, arrayOfProperties, arrayOfSortingType ) );
        
};

export function SimpleTable({ chiave,
    sTableDataType, aoRows = [], oRowOptions, aoCols, extraClasses, noHeight, noHeader, noFilter,  bOnlySelected,
    isCheckedFunction = ()=>{}, fCheckAll, fCheckOne, oCheckOptions = {}, // oCheckOptions = { isEnabled: true, isVisible: true }
    isHighlightable, isHighlightedFunction = ()=>{}, fHighlightOnlyOne,
    isEditable, editFunction,
    oHeadersOptions = {},
    exception = val=>val,
    fGetRecord,
    oSortOptions = { isSortable: false, isMultiColSortable: false, oSort: null },
    oPaginationOption = { isPagination: false, oPag: {} },  // nTotRecord: null, nRecordPerPage: null, nFirstRecordPage: null, set_nFirstRecordPage: null }, // functionToExecute === fSort
    oExportOption = { sFileName: 'export' },
    offSetHeight = 0
}) {
    // console.log('oPaginationOption: ', oPaginationOption)
    // l'abilitazione delle checkbox è possibile solo se gli elementi nelle righe hanno la proprietà "FLAG_SELECTED" ( 'Y' oppure 'N' )

    const isEmpty          = aoRows.length === 0;
    aoCols = aoCols.filter(Boolean);
    const asKeyColumnNames = ( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) || {} ).map( oCol => oCol.name );
    const getUniqueKey     = ( oRow, asKeyColumnNames ) => {
        // console.log('----------------------------------------------------------------');
        return asKeyColumnNames.reduce(
            ( sUniqueKey, sKeyColumnName ) => sUniqueKey + ( oRow[ sKeyColumnName ] || '' ),
            ''
        );
    };
    const bFiltersLimitReached = false; // ( aoRows.filter( isCheckedFunction ).length >= ( config.FILTERS_LIMIT || 50 ) );

    const
        [ oColumnFilters     ,set_oColumnFilters     ] = useState({})           // per ogni colonna mi segno il valore da filtrare
       ,[ aoFilteredRows     ,set_aoFilteredRows     ] = useState([...aoRows])  // array interno delle sole righe filtrate
       ,[ height             ,set_height             ] = useState(0)            // serve per mantenere fissa l'altezza della finestra dopo il primo caricamento dei dati
       ,[ headGroupRowHeight ,set_headGroupRowHeight ] = useState(0)            // serve per mantenere fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ headersRowHeight   ,set_headersRowHeight   ] = useState(0)            // serve per mantenere fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ bSelectAll         ,set_bSelectAll         ] = useState(null)         // indica se devono essere selezionati tutti gli elementi,
       ,[ coordHover         ,set_coordHover         ] = useState([])
       ,[ isDisabledExport   ,set_isDisabledExport   ] = useState(false)
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
    ;

    const paperContainer = useRef(null);
    const headGroupRow   = useRef(null);
    const headersRow     = useRef(null);


    const exportCSV = () => {
        set_isDisabledExport(true)
        const aasDataRows = [
            aoCols.filter( oCol => oCol && !oCol.noExport ).map( oCol => oCol.title )
            , ...aoFilteredRows.map( oRow => aoCols.filter( oCol => oCol && !oCol.noExport ).map(
                ( oCol, nCol ) => {
                        const 
                            originalValue  = oRow[ oCol.name ]
                           ,valueToDisplay = ['string','number'].includes( typeof originalValue ) ? originalValue : ''
                           // ,formatValue    = oCol.format ? oCol.format( valueToDisplay, oRow ) : valueToDisplay
                        ;
                        // console.log('originalValue: ', originalValue);
                        // console.log('valueToDisplay: ', valueToDisplay);
                        return ( typeof valueToDisplay === 'string' ) ? ( '"' + valueToDisplay  + '"' ) : utils.fixDecimals(valueToDisplay, 2);

                })
            )
        ];
        utils.fileSaver( aasDataRows, oExportOption.sFileName + ` ( ${ moment().format('YYYY-MM-DD HH/mm/ss')} )` );
        setTimeout( set_isDisabledExport, 2000, false);
    };


    useEffect(() => {
        if ( !noHeight ) {
            set_height(paperContainer?.current?.clientHeight + offSetHeight);
        }
        set_headGroupRowHeight( headGroupRow?.current?.clientHeight + 1 );
        set_headersRowHeight(   headersRow?.current?.clientHeight + 1 );
        if ( !aoRows.length ) { console.error('Nessun dato da visualizzare') }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_aoFilteredRows( bOnlySelected ? [...aoRows].filter( isCheckedFunction ) : [...aoRows] );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bOnlySelected]);

    const aoRowsUpdatedWithAllFilters = () => {

        let aoRowsUpdated = [...aoRows];

        // applico ogni filtro inserito (compreso l'ultimo appena aggiunto)
        const asFilterKeys = Object.keys( oColumnFilters );
        for ( let nFilterKey = 0; nFilterKey < asFilterKeys.length; nFilterKey++ ) {
            const sColumnFilterName = asFilterKeys[nFilterKey];

            let sValueFiltered     = oColumnFilters[sColumnFilterName];
            const oColFound        = ( aoCols.find( oCol => !oCol.noFilter  && ( oCol.name === sColumnFilterName ) ) || {} );
            const selectOptions    = oColFound.selectOptions;
            const formatFunction   = ( oColFound.format && !oColFound.filterOriginalValue ) ? oColFound.format : ( val => val );
            const filterRows = ( sValue, aoRowsInFilter, sColKey ) => {
                return aoRowsInFilter.filter( oRow => {
                    if  ( !!selectOptions ) {
                        const oActualOption = selectOptions.find( oSelectOption => oSelectOption.value === sValue );
                        return oActualOption && oActualOption.checkFunc && oActualOption.checkFunc( oRow[sColumnFilterName] || '', ( typeof sValueFiltered === 'object' ) ? sColKey : sValueFiltered );
                    } else {
                        return removeSpecialChars( formatFunction(oRow[sColumnFilterName], oRow ) ).includes( removeSpecialChars(sValue) );
                    }
                });
            }

            if ( typeof sValueFiltered === 'object' ) {
                const asColKeys = Object.keys( oColumnFilters[ sColumnFilterName ] );
                for ( let nColKey = 0; nColKey < asColKeys.length; nColKey++ ) {
                    const sColKey = asColKeys[nColKey];
                    sValueFiltered = oColumnFilters[ sColumnFilterName ][ sColKey ];
                    aoRowsUpdated  = filterRows( sValueFiltered, aoRowsUpdated, sColKey );
                }
            } else {
                aoRowsUpdated = filterRows( sValueFiltered, aoRowsUpdated );
            }

        }

        return aoRowsUpdated;

    };

    const handleFilterValues = ({ sValueToFilter, sColumnName, key }) => {

        // aggiungo l'attuale valore filtrato alla colonna interessata
        if ( key ) {
            if ( !oColumnFilters[ sColumnName ] ) {
                oColumnFilters[ sColumnName ] = {};
            }
            oColumnFilters[ sColumnName ][ key ] = sValueToFilter;
        } else {
            oColumnFilters[ sColumnName ] = sValueToFilter;
        }

        const aoRowsUpdated = aoRowsUpdatedWithAllFilters();

        // aggiorno righe e colonne
        set_oColumnFilters( oColumnFilters );
        set_aoFilteredRows( aoRowsUpdated );

    }

    const onChangeSelectAll         = (event) => {
        set_bSelectAll(!!event.target.checked);
    }

    useEffect( ()=>{
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
        if ( oCheckOptions.isVisible && ( typeof bSelectAll === 'boolean' ) ) {
            fCheckAll(bSelectAll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bSelectAll]);

    useEffect( ()=>{
        if ( aoRows.every( oRow => oRow.FLAG_SELECTED === 'Y' ) ) {
            set_bSelectAll(true);
        }
        set_aoFilteredRows(aoRowsUpdatedWithAllFilters() );
        // fix per aggiornare lo stato della tabella quando aoRows (una prop) viene aggiornata dal padre
        // ad ogni modifica di aoRows risetto lo stato associato "aoFilteredRows" tenendo conto dei filtri già applicati
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoRows ]);

    const isHeadersGrouped = aoCols.some( oCol => oCol.group );
    const oColsGroups     = aoCols.reduce( ( oColsGroups, oCol ) => {
        if ( !oColsGroups[ oCol.group ] ) {
            oColsGroups[ oCol.group ] = 1; // se non esiste il gruppo nell'oggetto, lo crea e gli dà valore 1
        } else { // altrimenti se esiste già aumenta il suo valore di 1
            oColsGroups[ oCol.group ] = oColsGroups[ oCol.group ] + 1;
        }
        return {...oColsGroups};
    } ,{});

    /*
    const handleCheck = ( { isChecked, oRow } ) => {
        handleChangeCheck({ isChecked, oRow });
        setAoRows( [...aoRows] );
    };

    const  handleHighlight = ( { isHighlighted, oRow } ) => {
        console.log('PRIMA ',JSON.stringify(aoRows));
        aoRows.map( row => handleChangeHighlight({ isHighlighted: false, oRow: row }) ); // toglie evidenziazione da tutte le righe
        handleChangeHighlight({ isHighlighted: !isHighlighted, oRow }); // evidenzia solo la riga interessata
        console.log('DOPO ',JSON.stringify(aoRows));
        console.log(setAoRows.toString());
        setAoRows( [...aoRows] );
    };
    */
    
    const fMapSameValues = () => {
        
        let oPrevRow        = {};
        let aMappedSameRow  = [];
        const aoSortCols    = ( aoCols.filter( oCol => oCol.sort ) || {} );
        // console.log( 'aoCols: ' + JSON.stringify(aoCols) );
        // console.log('aoSortCols: ', aoSortCols);
        
        const aoSortedRows  = fGetRecord ? [ ...aoFilteredRows ] : sortBy(
             aoFilteredRows 
            ,aoSortCols.map( oSortcol => oSortcol.name ) 
            ,aoSortCols.map( oSortcol => oSortcol.sort )
        );
        
        for ( let nRow = 0; nRow < aoSortedRows.length; nRow++ ) {
            const oRow = aoSortedRows[nRow];
            const isChecked = isCheckedFunction( oRow );
            aMappedSameRow.push( 
                <tr
                    key       ={ 'tr' + getUniqueKey(    oRow, asKeyColumnNames ) }
                    className ={ ( isHighlightable && isHighlightedFunction( oRow ) ? ' highlight ' : '' )
                            + ( oRowOptions && oRowOptions.addClass ? oRowOptions.addClass(oRow) : '' ) }
                    onClick   ={ () => { isHighlightable && fHighlightOnlyOne( oRow ) } }
                >
                    {   // colonna per checkbox
                        oCheckOptions.isVisible &&
                        <td key="checkcolumn" className="check">
                            <Checkbox // disabilita checkbox se si supera il massimo di elementi selezionabili
                                disabled ={ !oCheckOptions.isEnabled && !isChecked && bFiltersLimitReached }
                                checked  ={ isCheckedFunction(oRow) || isChecked }
                                onClick  ={((event)=>{event.stopPropagation()})}
                                onChange ={ (event) => fCheckOne( { isChecked: event.target.checked, oRow } ) }
                                color    ="primary"
                            />
                        </td>
                    }
                    {   /* colonna per EDIT */
                        isEditable && <td className="edit" onClick={ () => { editFunction(oRow) } } >
                            <Tooltip title="Edit" placement='right' arrow>
                                <EditIcon />
                            </Tooltip>
                        </td>
                    }
                    {   // corpo della tabella (i dati)

                        // eslint-disable-next-line no-loop-func
                        aoCols.map( (oCol, nCol) => {
                            const
                                 originalValue  = oRow[ oCol.name ]
                                ,isSame         = oCol.showDiff && ( originalValue === oPrevRow[ oCol.name ] ) ? ' isSame ' : ''
                                ,valueToDisplay = ['string','number'].includes( typeof originalValue ) ? originalValue : ''
                                ,formatValue    = oCol.format ? oCol.format( valueToDisplay, oRow ) : valueToDisplay
                                ,optionalProps  = !oCol.onHover ? {} : {
                                    onMouseEnter:() => { timeoutIdOnHover = setTimeout( () => { set_coordHover([nRow, nCol]) }, 250 ) },
                                    onMouseLeave:() => { clearTimeout(timeoutIdOnHover);  set_coordHover([]) }
                                }
                                ,cellContent    = ( oCol.onHover && ( coordHover[0] === nRow ) && ( coordHover[1] === nCol ) )
                                                    ? oCol.onHover( formatValue, oRow.KUSER, oCol.key )
                                                    : exception( formatValue, oRow, oCol, oRow.asCurrentFilters )
                                ,uniqueClass    = oCol.isUniqueKeyForRow ? ' unique ' : ''
                                ,numberClass    = oCol.isNum ? ' number ' : ''
                            ;
                            return <td
                                key       = { 'td' + oCol.name + oCol.title }
                                className = { oCol.name + ' '
                                    + uniqueClass + numberClass + ( oCol.key ? oCol.format( valueToDisplay, oRow ) : '' ) + ' '
                                    + (  ( oCol.additionalClass || ((v)=>'') )(originalValue) ) + isSame
                                }
                                style     = { { minWidth: oCol.width || '', width: oCol.width || '', maxWidth: oCol.width || '' } }
                                title     = { oCol.tooltip ? formatValue : '' }
                                { ...optionalProps }
                            >{
                                cellContent
                            }</td>
                        })

                    }
                </tr> )

                oPrevRow = oRow;
            }

        // console.log(aoFilteredRows);
        return aMappedSameRow;
    };
    
    const sortTable = async (oCol) => {
        // console.log( 'Prima: ' + JSON.stringify(oCol) );

        if ( !oSortOptions.isMultiColSortable ) {
            for ( let i = 0; i < aoCols.length; i++ ) {
                if ( aoCols[i].name !== oCol.name ) {
                    delete aoCols[i].sort;
                }
            }
        }

        // console.log( 'Dopo: ' + JSON.stringify(oCol) );
        if ( oSortOptions.isSortable && !oCol.sortInPage && fGetRecord ) {
            oCol.sort = (oSortOptions?.oSort?.sort !== 'DESC') || (oSortOptions?.oSort?.name !== oCol.name) ? 'DESC' : 'ASC'; // 
            await fGetRecord({ oCol: { ...oCol } });
            // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
            set_aoFilteredRows([...aoFilteredRows]);
        } else {
            // oCol.sort = !fGetRecord && oCol.sort !== 'DESC' ? 'DESC' : 'ASC';
            oCol.sort = oCol.sort !== 'DESC' ? 'DESC' : 'ASC';
            
            set_aoFilteredRows([...sortBy(aoFilteredRows, [oCol.name], [oCol.sort])]);
        }
        // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
        // set_aoFilteredRows([...aoFilteredRows]);
    }
    
    if ( !Object.keys(aoRows).length || !Object.keys(aoCols).length ) {
        return <></>;
    }

    const tableTool = <span className='headerTableTool'>
        <Tooltip title='Download CSV' placement='right' arrow>
            <Button 
                className='side-simpletable-menu-opener myShadow'
                onClick={ exportCSV }
                disabled={ isDisabledExport }
            >
                <DownLoadIcon addClass={'bigIcon textGrey'} />
            </Button>
        </Tooltip>
        { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) &&
            <PaginationTool oPaginationOption = {{ fGetRecord, oPag: oPaginationOption.oPag }}/>
        }
    </span>;

    
    return <Paper
        key       ={chiave}
        className ={
            'SimpleTable-wrapper '
            + ( isEmpty ? ' no-data ' : ' ' )
            + ( extraClasses || ' ' )
            + ' ' + sTableDataType
        }
        ref       ={ paperContainer }
        style     ={ height ? { height } : {} }
    >
        { tableTool }
    {/* <Tooltip title='Menu' placement='top' arrow><div className="dianButton side-simpletable-menu-opener link myShadow" onClick={ openMenuTable }><ThreeVerticalDot /></div></Tooltip> !!! TODO
        <Tooltip title='Download' placement='bottom' arrow><div className={`side-simpletable-menu link myShadow ${ isMenuOpen ? '' : 'hiddenElement' }`} onClick={ exportCSV }><DownLoadIcon /></div></Tooltip> */}
        <div className={ 'SimpleTable ' + chiave } key={chiave}>
            <table>
                <thead>
                {   // intestazioni dei gruppi di colonne
                    ( noHeader || !isHeadersGrouped ) ? null :
                    <tr>
                        { oCheckOptions.isVisible && <th key="checkcolumn" className="check" ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  ><div></div></th> }
                        { Object.keys(oColsGroups).map( ( sColGroup, nColGroup ) =>
                            <th
                                ref       ={ nColGroup === 0 ? headGroupRow : null }
                                key       ={ sColGroup }
                                className ={ sColGroup !== 'undefined' ?  ( 'colgroup ' + sColGroup ) : '' }
                                colSpan   ={ oColsGroups[sColGroup] }
                                title     ={ oHeadersOptions.tooltip && sColGroup ? sColGroup : '' }
                            >{ sColGroup === 'undefined' ? '' : sColGroup }</th>
                        ) }
                    </tr>
                }
                {   // intestazioni delle colonne
                    noHeader ? null :
                    <tr>
                        { oCheckOptions.isVisible &&    <th key="checkcolumn" className="check" style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { isEditable              &&    <th key="editcolumn"  className="edit"  style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { aoCols.map( ( oCol, nCol ) => {
                            const nColWidth = ( oCol.width + +( oSortOptions.isSortable && !oCol.notSortable && 14 ) ) || ''; 
                            return <th
                                ref       ={ nCol === 0 ? headersRow : null }
                                key       ={ 'headers' + oCol.name + nCol }
                                className ={
                                     oCol.name
                                     + ' ' + ( oCol.group                ? ( 'ingroup' + oCol.group )   : '' )
                                     + ' ' + ( oCol.isUniqueKeyForRow    ? 'unique'                     : '' )
                                     + ' ' + ( oCol.isNum                ? 'number'                     : '' )
                                     + ' ' + (  ( oCol.additionalClass || ((v)=>'') )() )
                                     + ' ' + ( oHeadersOptions.breakline ? 'breakline'                  : '' )
                                }
                                style     ={ { minWidth: nColWidth, width: nColWidth, maxWidth: nColWidth, top: headGroupRowHeight || 0 } }
                                title     ={ ( oHeadersOptions.tooltip && (oCol.headerTooltip || oCol.title) ) || '' }
                            >{ oSortOptions.isSortable && !oCol.notSortable && 
                                    <div className={ 'arrows-icon updown ' + ( (((!fGetRecord || oCol.sortInPage) && oCol.sort) || ((oSortOptions?.oSort?.name === oCol.name) && oSortOptions?.oSort?.sort)) || '' ) } 
                                         onClick={ () => sortTable(oCol) }></div> }
                             { oCol.title }</th>
                            }
                        ) }
                    </tr>
                }
                {   // riga dei filtri
                    noFilter ? null :
                    <tr className="filter">
                        { oCheckOptions.isVisible &&
                            <th key="checkcolumn" className="check" style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }>
                                <Tooltip title={ bSelectAll ? 'Deselect all' : 'Select all' } >
                                    <Checkbox className="check-sel-all" checked={ !!bSelectAll } onChange={ onChangeSelectAll } />
                                </Tooltip>
                            </th>
                        }
                        { isEditable && <th key="editcolumn" className="edit" style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }/> }
                        { // filtri sulle righe
                            aoCols.map( ( oCol, nCol ) => {
                                let sValueToFilter = oColumnFilters[ oCol.name ] || '';
                                if ( sValueToFilter && ![ 'string', 'number' ].includes( typeof sValueToFilter ) ) {
                                    sValueToFilter = sValueToFilter[ oCol.key ];
                                }
                                return <th
                                    style     ={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }
                                    key       ={ 'filters' + oCol.name + nCol }
                                    className ={ oCol.name + ( oCol.isUniqueKeyForRow ? ' unique ' : '' ) + ( oCol.isNum ? ' number ' : '' ) + ' ' + (  ( oCol.additionalClass || ((v)=>'') )() ) }
                                > {
                                    oCol.noFilter ? '' :
                                    ( !!oCol.selectOptions )
                                        ? // filtro in versione select con solo tre opzioni: tutto, uno specifico valore oppure il valore opposto
                                            <Select
                                                value       ={ sValueToFilter || '' }
                                                variant     = 'standard'
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:     event.target.value
                                                        ,sColumnName:        oCol.name
                                                        ,formatFunction:     oCol.format
                                                        ,selectOptions:      oCol.selectOptions
                                                        ,key:                oCol.key
                                                    })
                                                }}
                                            >{
                                                oCol.selectOptions.map( oSelectItem =>
                                                    <MenuItem key={ 'trioptions' + oSelectItem.value } value={ oSelectItem.value } >{ oSelectItem.label }</MenuItem>
                                                )
                                            }</Select>
                                        : // filtro versione con input libero da parte dell'utente digitando il testo
                                            <TextField
                                                type        ="text"
                                                variant     ="standard"
                                                value       ={ sValueToFilter }
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:    event.target.value
                                                        ,sColumnName:       oCol.name
                                                        ,formatFunction:    oCol.format
                                                    })
                                                }}
                                                placeholder ="Filter..."
                                                fullWidth
                                                inputProps={{
                                                    spellCheck: "false" }}
                                            />
                                    /* InputProps={{ endAdornment: ( <InputAdornment position="end"> <span>Cc</span> <span>W</span> <span>*</span> </InputAdornment> ) }} */
                                } </th>
                            })
                        }
                    </tr>
                }
                </thead>
                <tbody>
                {   // corpo della tabella
                    fMapSameValues()
                }
                </tbody>
                {/* {
                    oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && 
                    <tfoot><tr><th className='myFoot' colSpan={100}>
                        { tableTool }
                    </th></tr></tfoot>
                } */}
            </table>
        </div>
        { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && tableTool } 
        { /* Ripetizione di condizione per i casi di tabella con più di una pagina */}
    </Paper>

}
/* --- esempio di utilizzo ---
    tableContainer = ({
                        key, sTableType, sTableDataType, aoColumns, aoRows, setAoRows, bChecksVisible, bOnlySelectedEnabled,
                        exception, noHeader, noFilter, isEditable, editFunction
                     }) => {

        return !( aoRows && aoRows.length ) ? null : (
            <SimpleTable

                chiave                ={ key }
                sTableDataType        ={ sTableDataType }
                aoRows                ={ aoRows    }
                aoCols                ={ aoColumns }

                noHeight              ={ true }
                noHeader              ={ noHeader }
                noFilter              ={ noFilter }
                bOnlySelected         ={ bOnlySelectedEnabled }

                oCheckOptions         ={ { isEnabled: true, isVisible: ( sTableType === 'checkable' ) && bChecksVisible } }
                isCheckedFunction     ={ isCheckedFunction }
                fCheckAll             ={ bCheckAll => fCheckAll( { bCheckAll, aoRows, setAoRows } ) }
                fCheckOne             ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows, setAoRows } ) }

                isEditable            ={ !bChecksVisible && isEditable }
                editFunction          ={ isEditable && editFunction ? editFunction : () => {} }

                exception             ={ exception }

            />
        )
    }

    tableContainer({
         key:                    'users'
        ,sTableDataType:         'USERS'
        ,sTableType:             'checkable'
        ,aoColumns:              [...aoUsersColumns]
        ,aoRows:                 [...aoUsers]
        ,setAoRows:              set_aoUsers
        ,bChecksVisible:         bUsersChecks
        ,bOnlySelectedEnabled:   false
        ,isEditable:             true
        ,editFunction:           onClickEdit
    })

*/
